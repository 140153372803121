import RD from "@/api/RD";
export default {
  store: {
    webName: "吴江图片库",
    count: 1,
    addrTree: [],
    typeTree: [],
    userInfo: {},
    logined: false,
    isMobile: false,
  },

  /**
   * in this method, this is the basicFrame, vue component
   */
  initExtra() {
    console.log("init extra run");
    let thiz = this;
    var prefixURL = "https://m.meimeiwujiang.com/client/v_0.1";

    RD.setPreFix(prefixURL);
    RD.setErrorHandler(function (error) {
      console.log(error);
      return new Promise((resolve, reject) => {
        if (error.code && error.code < 10) {
          thiz.$message(error.msg);
          thiz.$rtm.hideLoading();
          resolve();
        } else {
          reject();
        }
      });
    });
    RD.setLoadingStartAction(function () {
      console.log("showloading");
      thiz.$rtm.showLoading();
    });
    RD.setLoadingEndAction(function () {
      console.log("hideLoading");
      thiz.$rtm.hideLoading();
    });
  }
};
