<template>
  <div class="news_whole">
    <div class="news-con">
      <div class="news-title">
        <div class="text">{{ titleInfo.title }}</div>
      </div>
      <div class="fixtop-video-list" v-if="$route.query.id == '17'">
        <waterfall :line-gap="360" :watch="fixtopList">
          <waterfall-slot class="fixtop-list-item" v-for="(item, index) in fixtopList" :key="item.id + 'fixtopic_' + index" :width="300" :height="item.frameHeight">
            <div class="item-inner">
              <div class="video-container" :height1="item.videoHeight" :style="{ height: item.videoHeight + 'px' }">
                <video :src="item.videoURL" :poster="item.videoImgURL" :ref="'video_' + index + '_' + item.id" :height="item.videoHeight" @click="onlookPost(item.id)"></video>
                <div class="first-play" v-if="item.firstPlay" @click="onlookPost(item.id)">
                  <i class="el-icon-caret-right" @click.stop="onVideoFirstPlay('video_' + index + '_' + item.id, index)"></i>
                </div>
                <div class="item-title">{{ item.title }}</div>
                <div class="item-author">
                  <div class="author-img"></div>
                  <div class="author-name">{{ item.author }}</div>
                </div>
              </div>
            </div>
          </waterfall-slot>
        </waterfall>
      </div>
      <div class="fixtop-pic-list" v-else>
        <waterfall :line-gap="360" :watch="fixtopList">
          <waterfall-slot
            class="fixtop-list-item"
            style="margin-left: 10px; margin-bottom: 10px"
            v-for="(item, index) in fixtopList"
            :key="item.id + 'fixtopic_' + index"
            :width="200"
            :height="item.frameHeight"
            :margin-left="10"
          >
            <div class="item-inner" @click="onLookDetail(item.id)">
              <div class="item-img" :height1="item.postImageHeight" :style="{ backgroundImage: `url(` + item.imgURL + `)` }"></div>
              <div class="item-title">{{ item.title }}</div>
              <div class="item-author">
                <div class="author-img"></div>
                <div class="author-name">{{ item.author }}</div>
              </div>
            </div>
          </waterfall-slot>
        </waterfall>
      </div>
      <div class="more-post">
        <div class="btn" @click="onMore">加载更多</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'
import Waterfall from 'vue-waterfall/lib/waterfall'
import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot'

export default {
  components: { Bottom, Waterfall, WaterfallSlot },
  data: function () {
    return {
      fixtopList: [], // 二级的稿件
      titleInfo: {},
      navName: '', // 稿件所属的Nav
      page: 1,
      currentPage: 1,
      totalPage: 1,
      banmian: false, // 版面展示
      videoHeight: '',
      videoWidth: '',
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('fiextopic', this.$route.query)
      if (this.$route.query.id == '17') {
        thiz.titleInfo.title = '精彩视频'
      }
      RD.fixedTopic()
        .id(this.$route.query.id)
        .posts(thiz.page, 36)
        .then((data) => {
          console.log('datamx', data)
          data.list.forEach((i) => {
            i.videoImgURL = RD.video().id(i.id).poster_URL()
            i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
            i.firstPlay = true
            i.videoURL = RD.video().id(i.id).svideo_URL()
            if (this.$route.query.id == '17') {
              if (i.videoHeight >= i.videoWidth) {
                i.frameHeight = 460
                i.videoHeight = 340
                console.log('i', i.frameHeight)
              } else {
                i.frameHeight = 340
                i.videoHeight = 320
              }
            } else {
              if (i.postImageHeight >= i.postImageWidth) {
                i.frameHeight = ((i.postImageHeight * 120) / i.postImageWidth) * 1.5 + 120
                i.postImageHeight = ((i.postImageHeight * 100) / i.postImageWidth) * 1.2
                console.log('i', i.frameHeight)
              } else {
                i.frameHeight = 160
                i.postImageHeight = 110
              }
            }
          })
          thiz.fixtopList = thiz.fixtopList.concat(data.list)
          thiz.titleInfo = data.item
          thiz.totalPage = data.pager.totalPage
          thiz.currentPage = data.pager.currentPage
        })
    },
    // 加载更多
    onMore() {
      console.log('page', this.page)
      if (this.page == this.totalPage) {
        this.$alert('已经加载全部了', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      } else {
        this.page++
        this.reload()
        console.log('list', this.fixtopList)
      }
    },
    onVideoMouseEnter(refId) {
      console.log('video mouse enter', refId)
      this.$refs[refId][0].play()
    },
    onVideoMouseLeave(refId) {
      console.log('video mouse leave', refId)
      this.$refs[refId][0].pause()
    },
    onVideoFirstPlay(refId, index) {
      console.log('onVideoFirstPlay', refId)
      this.fixtopList[index].firstPlay = false
      this.$refs[refId][0].play()
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.reload()
    },
    // 查看稿件video
    onlookPost(id) {
      this.$router.push({
        path: '/picDetail.html',
        query: { postId: id },
      })
    },
    // 查看稿件pic
    onLookDetail(id) {
      this.$router.push({
        path: '/post.html',
        query: { id: id },
      })
    },
    // 点赞
    onTagUp(postId) {
      RD.post(postId)
        .tagUp()
        .then(() => {})
    },
    // 视频详情页
    onVideoDetail(postId) {
      let routeUrl = this.$router.resolve({
        path: '/videoDetail.html',
        query: { id: postId },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.news_whole {
  width: 750px;
  background-color: #fff;
  .news-con {
    margin: 0 auto;
    width: 750px;
    .news-title {
      height: 220px;
      line-height: 220px;
      width: 100%;
      background: no-repeat url(../assets/fixtopic.png);
      background-size: cover;
      .text {
        margin-left: 5px;
        font-size: 38px;
        font-weight: bold;
        line-height: 220px;
      }
    }
    .fixtop-video-list {
      width: 730px;
      margin: 0 auto;
      .page-container {
        text-align: right;
        margin: 10px 0px;
      }
      .fixtop-list-item {
        .item-inner {
          margin: 10px;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 0 10px #ccc;
          .video-container {
            position: relative;
            width: 100%;
            height: 100%;
            // overflow: hidden;
            video {
              width: 100%;
              min-height: calc(100% - 80px);
              object-fit: cover;
              cursor: pointer;
            }
            .first-play {
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 0px;
              left: 0px;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              i {
                margin: auto;
                height: 30px;
                width: 30px;
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 15px;
                line-height: 30px;
                font-size: 20px;
                color: white;
                transition: all 300ms ease-in-out;
              }
              i:hover {
                height: 40px;
                width: 40px;
                border-radius: 20px;
                line-height: 40px;
                color: black;
                background-color: rgba(255, 255, 255, 0.6);
              }
            }
            .item-title {
              height: 30px;
              font-size: 20px;
              text-align: left;
              font-weight: bold;
              margin-top: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #000;
              width: 98%;
              margin-left: 5px;
              opacity: 1;
            }
            .item-author {
              display: flex;
              height: 30px;
              line-height: 30px;
              .author-img {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                margin-top: 4px;
                background: no-repeat url(../assets/head.png) center;
                background-size: contain;
              }
              .author-name {
                margin-left: 10px;
                font-size: 20px;
              }
            }
          }

          .fix-title {
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
            cursor: pointer;
            font-size: 14px;
            text-align: left;
            margin-left: 10px;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .fix-author {
            height: 20px;
            text-align: left;
            margin-left: 10px;
            margin-top: 15px;
            color: #aaa;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              margin-left: 5px;
            }
            .anothercompany {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .fix-extra {
            display: flex;
            font-size: 15px;
            margin-left: 10px;
            margin-right: 10px;
            color: #aaa;
            margin-bottom: 10px;
            .video-visit {
              flex: 1;
              text-align: left;
              i {
                margin-right: 5px;
              }
            }
            .video-tagup {
              flex: 1;
              text-align: right;
              color: #ff4141;
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .fixtop-pic-list {
      position: relative;
      width: 730px;
      margin: 0 auto;
      background-color: #f5f5f5;
      .fixtop-list-item {
        background-color: #fff;
        margin-right: 10px;
        margin-bottom: 10px;
        .item-inner {
          height: 100%;
          width: 96%;
          position: relative;
          .item-img {
            // height: 70%;
            min-height: calc(100% - 80px);
            background: no-repeat 50% / cover;
          }
          .item-title {
            height: 30px;
            font-size: 20px;
            text-align: left;
            font-weight: bold;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000;
            width: 98%;
            margin-left: 5px;
            opacity: 1;
          }
          .item-author {
            display: flex;
            height: 30px;
            line-height: 30px;
            .author-img {
              width: 24px;
              height: 24px;
              border-radius: 12px;
              margin-top: 4px;
              background: no-repeat url(../assets/head.png) center;
              background-size: contain;
            }
            .author-name {
              margin-left: 10px;
              font-size: 20px;
            }
          }
        }
      }
    }
    .more-post {
      padding-bottom: 20px;
      padding-top: 20px;
      .btn {
        width: 120px;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        border-radius: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .news-con {
    width: 1200px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .news-con {
    width: 1000px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .news-con {
    width: 800px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
  .news-con {
    width: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .news-con {
    width: 400px;
  }
}
</style>
