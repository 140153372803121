import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/index.html",
  //   name: "welcome",
  //   component: welcome
  // },
  {
    path: "/",
    redirect: "/index.html",
  },
  {
    path: "/index.html",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/post.html",
    component: () => import("../views/postDetail.vue")
  },
  {
    path: "/picDetail.html",
    component: () => import("../views/picDetail.vue")
  },
  {
    path: "/catalog.html",
    component: () => import("../views/catalog.vue")
  },
  {
    path: "/fixTopic.html",
    component: () => import("../views/fixTopic.vue")
  },
  {
    path: "/userShow.html",
    component: () => import("../views/userShow.vue")
  },
  {
    path: "/userShowDetail.html",
    component: () => import("../views/userShowDetail.vue")
  },
  {
    path: "/local.html",
    component: () => import("../views/local.vue")
  },
  {
    path: "/localShow.html",
    component: () => import("../views/localShow.vue")
  },
  {
    path: "/upPost.html",
    component: () => import("../views/upPost.vue")
  },
  {
    path: "/postEdit.html",
    component: () => import("../views/postEdit.vue")
  },
  {
    path: "/myPost.html",
    component: () => import("../views/myPost.vue")
  },
  {
    path: "/myInfo.html",
    component: () => import("../views/myInfo.vue")
  },
  {
    path: "/bindMobile.html",
    component: () => import("../views/bindMobile.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
