<template>
  <div class="whole">
    <div class="mobile-present">
      <div class="top">
        <div class="top-list">
          <i v-touch:tap="onTouchMenu"></i>
          <div class="menu-list" v-bind:class="{ active: isActive }">
            <div class="menu-con">
              <div class="menu-name" v-for="menu in menuList" :key="menu.id">
                <div v-if="menu.dis" @click="onNoOpen"></div>
                <a v-else :href="menu.url">{{ menu.name }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="top-img" @click="onGoPicture">
          <div class="pic"></div>
        </div>
      </div>
    </div>
    <div class="return-home">
      <div class="return-img">
        <a href="https://www.ahtpw.com/" target=""></a>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import sha1 from 'js-sha1'

export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      menuList: [
        { name: '首页', url: 'index.html' },
        {
          name: '精品图库',
          url: 'catalog.html?title=精品图库',
          subs: [],
        },
        {
          name: '今日读图',
          url: 'catalog.html?title=今日读图',
          subs: [],
        },
        {
          name: '区镇图库',
          url: 'local.html',
          subs: [],
        },
        {
          name: '精彩视频',
          // url: "catalog.html?title=视频",
          url: '/fixTopic.html?id=17',
          subs: [],
        },
        {
          name: '专题活动',
          url: 'catalog.html?title=专题活动',
          subs: [],
        },
        { name: '签约摄影师', url: 'userShow.html' },
        // { name: "新闻", url: "fixTopic.html?id=16" },
      ], // 导航栏的数组
      clickIndex: -1,
      logoedShow: false,
      codeImg: '', // 验证码的图片路径
      logined: false, //是否登录的状态
      logoName: '',
      passWord: '',
      code: '', // 验证码
      syncData: {},
      noUpload: false,
      loginState: 1, // 是否登录的状态
      isActive: false, // 手机模式下的菜单展开
    }
  },
  created() {
    this.syncfresh()
  },
  mounted() {
    this.getCode()
  },
  methods: {
    onLoginConfirmClick() {
      var thiz = this
      thiz.loading = true
      if (thiz.logoName == '') {
        thiz.$message('手机号码不能为空')
        return
      }
      if (thiz.passWord == '') {
        thiz.$message('密码不能为空')
        return
      }
      if (thiz.code) {
        RD.pure()
          .user()
          .loginAdvanced(thiz.code, thiz.logoName, sha1(thiz.code.toLowerCase() + sha1(thiz.passWord)))
          .then(() => {
            thiz.logined = true
            thiz.loginState = 2
            thiz.code = ''
            thiz.loading = false
            // this.syncfresh();
            // thiz.logoedShow = false;
            location.reload()
            thiz.isSelect()
          })
          .catch((error) => {
            thiz.$message(error.msg)
            thiz.code = ''
            thiz.loading = false
          })
      } else {
        thiz.$message('请输入验证码再登录')
      }
    },
    onSureLogo() {
      var thiz = this
      thiz.loading = true
      if (thiz.logoName == '') {
        thiz.$message('用户名不能为空')
        return
      }
      if (thiz.passWord == '') {
        thiz.$message('密码不能为空')
        return
      }
      if (thiz.code) {
        RD.pure()
          .user()
          .login(thiz.code, thiz.logoName, sha1(thiz.passWord))
          .then(() => {
            thiz.logined = true
            thiz.loginState = 2
            thiz.code = ''
            thiz.loading = false
            location.reload()
            thiz.isSelect()
          })
          .catch((error) => {
            thiz.getCode()
            thiz.$message(error.msg)
            thiz.code = ''
            thiz.loading = false
          })
      } else {
        thiz.$message('请输入验证码再登录')
      }
    },
    onOutLogin() {
      // var thiz = this;
      RD.user()
        .logout()
        .then(() => {
          location.reload()
          // thiz.logined = false;
          // thiz.load();
          // thiz.loginState = 1;
        })
    },
    // sync刷新
    syncfresh() {
      var thiz = this
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.loginState = 2
          thiz.logined = true
          if (data.approveStatusName == '等待审批') {
            thiz.noUpload = false
          } else if (data.approveStatusName == '已通过' && data.upPicStores.length != 0) {
            thiz.noUpload = true
            thiz.isSelect()
          } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
            thiz.noUpload = true
            thiz.isSelect()
          }
          thiz.syncData = data
        })
        .catch(() => {
          thiz.loginState = 1
        })
    },
    // 检查是否有可以评选的活动
    isSelect() {
      var thiz = this
      RD.activityTopic()
        .checkVoter()
        .then((data) => {
          if (data.length != 0) {
            thiz.activityTopicSelectShow = true
          }
          thiz.activitySelectList = data
        })
    },
    onLogin() {
      var thiz = this
      thiz.logoedShow = true
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    getCode() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    handleClose() {
      var thiz = this
      thiz.logoedShow = false
    },
    onRetrieveCode() {
      this.getCode()
    },
    // 回首页
    onGoPicture() {
      this.$router.push('/index.html')
    },
    // 找回密码
    onForgetpswd() {
      let routerUrl = this.$router.resolve({
        path: '/forgetPassWord.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    onTouchMenu() {
      this.isActive = !this.isActive
    },
    // 上传图片
    onUploadPic() {
      if (this.logined) {
        let routerUrl = this.$router.resolve({
          path: '/upPost.html',
        })
        window.open(routerUrl.href, '_blank')
      } else {
        this.$message('请先登录账户再上传')
      }
    },
  },
}
</script>
<style scoped lang="less">
.mobile-present {
  position: relative;
  height: auto;
  width: 750px;
  z-index: 99;
  .top {
    height: 100px;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    .top-list {
      width: 278px;
      position: relative;
      z-index: 10000;
      i {
        display: block;
        height: 80px;
        width: 80px;
        color: white;
        position: relative;
        left: 4px;
        top: 4px;
        background: no-repeat 50% / contain url(../assets/mobileMenu.png);
      }
      .menu-con {
        .menu-name {
          height: 85px;
          line-height: 85px;
          width: 150px;
          color: #000;
          font-size: 26px;
        }
        .menu-name:nth-child(1) {
          a {
            color: #e63439;
            font-weight: bold;
          }
        }
      }
      .menu-list {
        // opacity: 0;
        display: none;
        // height: 429px;
        width: 161px;
        background: rgba(250, 250, 250, 0.94);
        border-radius: 1px 1px 1px 1px;
        position: relative;
      }
      .menu-list.active {
        // opacity: 1;
        display: block;
        // height: 429px;
      }
    }
    .top-img {
      display: flex;
      margin-top: 1rem;
      cursor: pointer;
      width: 200px;
      .pic {
        width: 200px;
        height: 75px;
        background: no-repeat url(../assets/new_logo.png);
        background-size: contain;
        margin-top: 4px;
      }
    }
    .top-other {
      display: flex;
      width: 150px;
      height: 70px;
      position: absolute;
      right: 0px;
      top: 0px;
      .line {
        position: absolute;
        right: 68px;
        top: 22px;
        width: 1px;
        height: 24px;
        background-color: #d9d9d9;
        opacity: 1;
      }
    }
  }
}
</style>
