<template>
  <div class="local">
    <div class="local-con mobile-present">
      <div class="local-name">
        <!-- <div class="name" v-for="k in typeRootList" :key="k.id" @click="queryPost(k.id, k.name)" :class="{ active: selectedId === k.id }">
          {{ k.name }}
        </div> -->
        <div class="name">{{ $route.query.name }}</div>
      </div>
      <div class="local-list">
        <!-- <div class="local-title">
          <div class="text">{{ localName }}共有稿件:{{ postCount }}</div>
          <div class="page">
            <div class="page-container">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="20"
                :hide-on-single-page="true"
                layout="prev, pager, next, jumper"
                :total="downTotal"
              ></el-pagination>
            </div>
          </div>
        </div> -->
        <div class="local-post">
          <waterfall :line-gap="360" :watch="postList">
            <waterfall-slot
              class="local-list-item"
              style="margin-left: 10px; margin-bottom: 10px"
              v-for="(item, index) in postList"
              :key="item.id + 'local_' + index"
              :width="200"
              :height="item.frameHeight"
              :margin-left="10"
            >
              <div class="item-inner" @click="onLookPost(item.id)">
                <div class="item-img" :height1="item.postImageHeight" :style="{ backgroundImage: `url(` + item.imgURL + `)` }"></div>
                <div class="item-title">{{ item.title }}</div>
                <div class="item-author">
                  <div class="author-img"></div>
                  <div class="author-name">{{ item.author }}</div>
                </div>
              </div>
            </waterfall-slot>
          </waterfall>
        </div>
      </div>
      <div class="more-post">
        <div class="btn" @click="onMore">加载更多</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'
import Waterfall from 'vue-waterfall/lib/waterfall'
import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot'

export default {
  components: { Bottom, Waterfall, WaterfallSlot },
  data: function () {
    return {
      typeRootList: [], // 地址的root
      typeAnhuiList: [], // 的root
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      totalPage: 1,
      postCount: '',
      query: {},
      localName: '吴江区',
      selectedId: -1,
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      console.log('param', thiz.$route.query)

      thiz.query.addrId = thiz.$route.query.id
      thiz.query.storeIds = [1]
      RD.pure()
        .query()
        .query(thiz.query, thiz.page, 10)
        .then((items) => {
          items.list.forEach((k) => {
            k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
            if (k.postImageHeight >= k.postImageWidth) {
              k.frameHeight = ((k.postImageHeight * 120) / k.postImageWidth) * 1.5 + 120
              k.postImageHeight = ((k.postImageHeight * 100) / k.postImageWidth) * 1.2
              // k.frameHeight = 460
              // k.postImageHeight = 340
              console.log('i', k.frameHeight)
            } else {
              k.frameHeight = 160
              k.postImageHeight = 110
            }
          })
          thiz.postCount = items.pager.total
          thiz.postList = thiz.postList.concat(items.list)
          thiz.currentPage = items.pager.currentPage
          thiz.totalPage = items.pager.totalPage
        })
    },
    // 加载更多
    onMore() {
      console.log('page', this.page)
      if (this.page == this.totalPage) {
        this.$alert('已经加载全部了', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      } else {
        this.page++
        this.reload()
      }
    },
    onGoReset() {
      this.reload()
    },
    queryPost(id, name) {
      var thiz = this
      var post = {}
      post.addrId = id
      thiz.localName = name
      thiz.selectedId = id
      post.storeIds = [1]
      RD.pure()
        .query()
        .query(post, thiz.page, 10)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
          })
          thiz.postCount = items.pager.total
          thiz.postList = items.list
          thiz.currentPage = items.pager.currentPage
          thiz.downTotal = items.pager.total
        })
    },
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.queryPost(thiz.clickId)
    },
    onLookPost(postId) {
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
  },
}
</script>

<style scoped lang="less">
.local {
  height: auto;
  .local-con {
    width: 750px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    background-color: #f5f5f5;
    .local-name {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;
      height: 220px;
      background: no-repeat url(../assets/fixtopic.png);
      background-size: cover;
      .name {
        width: 730px;
        margin: 0 auto;
        font-size: 38px;
        line-height: 220px;
        font-weight: bold;
      }
    }
    // .active {
    //   background-color: #f48888;
    //   color: #fff;
    // }

    .local-list {
      position: relative;
      width: 730px;
      margin: 10px auto 0;
      .local-list-item {
        background-color: #fff;
        margin-right: 10px;
        margin-bottom: 10px;
        .item-inner {
          height: 100%;
          width: 96%;
          position: relative;
          .item-img {
            // height: 70%;
            min-height: calc(100% - 80px);
            background: no-repeat 50% / cover;
          }
          .item-title {
            height: 30px;
            font-size: 20px;
            text-align: left;
            font-weight: bold;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000;
            width: 98%;
            margin-left: 5px;
            opacity: 1;
          }
          .item-author {
            display: flex;
            height: 30px;
            line-height: 30px;
            .author-img {
              width: 24px;
              height: 24px;
              border-radius: 12px;
              margin-top: 4px;
              background: no-repeat url(../assets/head.png) center;
              background-size: contain;
            }
            .author-name {
              margin-left: 10px;
              font-size: 20px;
            }
          }
        }
        // .item-inner:hover {
        //   .item-title {
        //     opacity: 1;
        //   }
        // }
      }
    }
    .more-post {
      padding-bottom: 20px;
      padding-top: 20px;
      .btn {
        width: 120px;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        border-radius: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
}
.el-tree {
  height: 600px;
  .el-tree-node {
    height: 80px !important;
    display: inline-block;
  }
}
.addr {
  display: inline-block;
  font-size: 1.5rem;
}

// @media only screen and (max-width: 600px) {
//   .whole_box {
//     width: calc(100% - 10px);
//     padding-top: calc((100% - 20px) * 1.35);
//   }
// }
// @media only screen and (min-width: 600px) {
//   .whole_box {
//     width: calc(100% - 10px);
//     padding-top: calc((100% - 20px) * 1.35);
//   }
// }

// @media only screen and (min-width: 960px) {
//   .whole_box {
//     width: calc(30% - 20px);
//     padding-top: calc((25% - 20px) * 1.35);
//   }
// }

@media only screen and (min-width: 1200px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 1600px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
</style>
