<template>
  <div class="whole">
    <Nav></Nav>
    <div class="index-content">
      <template v-for="(content, index) in contents">
        <!-- 轮播 -->
        <div :key="content.type + '_' + index" v-if="content.type == 'TOP_SHOW'" class="whole front-show">
          <TOPSHOW :content="content" :logined="logined"></TOPSHOW>
        </div>
        <!-- 固定栏目-->
        <div class="web-item ft-con" :key="content.type + '_' + index" v-if="content.type == 'FT'">
          <FT :content="content" :logined="logined"></FT>
        </div>

        <!-- 视频固定栏目 -->
        <div class="web-item ftv-con" :key="content.type + '_' + index" v-if="content.type == 'FTV'">
          <FTV :content="content" :logined="logined"></FTV>
        </div>

        <!-- 广告组 -->
        <div class="ads web-item" :key="content.type + '_' + index" v-if="content.type == 'ADS'">
          <ADS :content="content" :logined="logined"></ADS>
        </div>

        <!-- FTS 新闻图片 -->
        <div class="web-item fts-con" :key="content.type + '_' + index" v-if="content.type == 'FTS'">
          <FTS :content="content" :logined="logined"></FTS>
        </div>
      </template>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import FTV from '@/views/index/ftv.vue'
import FT from '@/views/index/ft.vue'
import TOPSHOW from '@/views/index/topShow.vue'
import ADS from '@/views/index/ads.vue'
import FTS from '@/views/index/fts.vue'

export default {
  name: '',
  components: { Nav, Bottom, FTV, FT, TOPSHOW, ADS, FTS },
  data() {
    return {
      contents: [], // 包河分站的数据
      logined: false,
      userData: {}, //
    }
  },
  created() {
    this.reload()
  },
  mounted() {},
  methods: {
    reload() {
      var thiz = this
      RD.pure()
        .user()
        .sync()
        .then((myInfo) => {
          thiz.userData = myInfo
          this.logined = true
        })
      RD.index()
        .main(2)
        .then((data) => {
          data.contents.forEach((content, contentIndex) => {
            content.picIndex = 0
            switch (content.type) {
              case 'TOP_SHOW':
                break
              case 'FT':
                break
              case 'FTV':
                break
              case 'TOP_FT':
                break
            }
          })
          thiz.contents = data.contents
        })
    },
    next(contentIndex) {
      var thiz = this
      thiz.contents[contentIndex].picIndex++
      if (thiz.contents[contentIndex].picIndex >= thiz.contents[contentIndex].list.length) {
        thiz.contents[contentIndex].picIndex = 0
      }
    },
    scrollTo(contentIndex, navIndex) {
      this.contents[contentIndex].picIndex = navIndex
    },
    stopTimer(contentIndex) {
      clearInterval(timers[contentIndex])
    },
    restartTimer(contentIndex) {
      if (timers[contentIndex]) {
        clearInterval(timers[contentIndex])
      }
      timers[contentIndex] = setInterval(() => {
        this.next(contentIndex)
      }, 2000)
    },
    selectGapByType(type) {
      switch (type) {
        case 'TOP_SHOW':
          return 3000
        default:
          return 3000
      }
    },
    // 轮播图的点击进入详情页
    onfrontPost(contentIndex) {
      var postid = this.contents[0].list[contentIndex].postId
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postid },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 首页的栏目查看稿件
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 新闻，图库等栏目
    onClickTopic(topicId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .index-content {
    width: 750px;
    background-color: #f7f7f7;
    .front-show {
      height: 650px;
      position: relative;
      margin: 0px auto;
      left: 0px;
      right: 0px;
      .pic-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .front-pic {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 500ms ease-in-out;
          cursor: pointer;
          .pic-inner {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .pic-inner-left {
            position: absolute;
            top: 0px;
            left: -50%;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .inner {
              position: absolute;
              top: 0px;
              right: -100.1%;
              width: 200%;
              height: 100%;
              filter: blur(10px);
              transform: scaleX(-1);
              background: repeat 50% / contain;
            }
          }
          .pic-inner-right {
            position: absolute;
            top: 0px;
            right: -50%;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .inner {
              position: absolute;
              top: 0px;
              left: -100.1%;
              width: 200%;
              height: 100%;
              filter: blur(10px);
              transform: scaleX(-1);
              background-color: black;
              background: repeat 50% / contain;
            }
          }
        }
        .front-pic.active {
          opacity: 1;
        }
      }
      .nav-container {
        position: absolute;
        bottom: 1.25rem;
        right: 53%;
        transform: translate(20rem);
        width: 25rem;
        height: 2.5rem;
        display: flex;
        justify-content: space-between;
        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }
        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 4.2rem;
        }
      }
    }
    .web-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .item-title-container {
        position: relative;
        height: 10rem;
        width: 750px;
        margin: 1.7rem auto;
        line-height: 10rem;
        .item-title {
          font-size: 18px;
          cursor: pointer;
          display: inline-block;
          height: 80px;
          margin-left: 20px;
          padding: 0px 1.5rem;
        }
      }
    }
  }
}

@media all and (min-width: 801px) {
  ::v-deep .web-item {
    .item-title-container {
      height: 10rem;
      width: 120rem;
      margin: 5rem auto;
      line-height: 4rem;

      .item-title {
        font-size: 34px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 40px;
        height: 5rem;
        cursor: pointer;
      }
      .item-icon {
        height: 3.6rem;
        width: auto;
        font-size: 24px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        color: #999999;
        line-height: 3rem;
        margin: 0 auto;
      }
      .item-red {
        width: 5.6rem;
        height: 0.5rem;
        background: #e42121;
        margin: 0.5rem auto;
      }
    }
  }
}
::v-deep .web-item {
  display: flex;
  flex-direction: column;

  .item-title-container {
    position: relative;

    .item-title {
      cursor: pointer;
      display: inline-block;
    }
  }
}
</style>
