<template>
  <div class="whole">
    <div class="postWhole desktop-present">
      <div class="video-detail" v-if="videoStatus">
        <div class="video-play">
          <div class="title">{{ postInfo.title }}</div>
          <video :src="postInfo.videoURL" :poster="postInfo.videoimgURL" controls loop></video>
        </div>
        <div class="video-text">
          <div>作者：{{ postInfo.author }}</div>
          <div>视频ID：{{ postInfo.id }}</div>
          <div>上传时间：{{ postInfo.upTime }}</div>
          <div>地点：{{ newAddress }}</div>
          <div>类型：{{ newTypes }}</div>
          <div class="visited" v-if="postInfo.visit != 0">访问量：{{ postInfo.visit }}</div>
        </div>
        <div class="video-btn" v-if="postInfo.canDownloadMiddlePic">
          <div class="v-b" @click="onVideoDownload(postInfo.id)">下载视频</div>
        </div>
        <div class="video-maintext">
          <div>正文:</div>
          <div class="main-text-preview">{{ postInfo.mainText }}</div>
        </div>
      </div>
      <div class="post-detail" v-if="!videoStatus">
        <div class="posttext">
          <div class="title">{{ postInfo.title }}</div>
          <div class="bigPic">
            <div class="pic-con" :style="{ left: '-' + transformlargeDistance + 'rem' }">
              <div class="big-img" v-for="(k, navIndex) in postInfo.pics" :key="k.id" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPic(navIndex)">
                <div class="bigpic-left">
                  <div class="left-icon" @click.stop="onBigPicLeft">
                    <div class="left-d el-icon-d-arrow-left"></div>
                  </div>
                </div>
                <div class="bigpic-right">
                  <div class="right-icon" @click.stop="onBigPicRight">
                    <div class="right-d el-icon-d-arrow-right"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bigpic-number" v-if="postInfo.pics">{{ picIndex + 1 }}/{{ postInfo.pics.length }}</div>
            <div class="pic-text" v-if="postInfo.pics">{{ postInfo.pics[picIndex].text }}</div>
          </div>
          <div class="other">
            <div>稿件ID：{{ postInfo.id }}</div>
            <div>上传时间：{{ postInfo.upTime }}</div>
            <div>地点：{{ newAddress }}</div>
            <div v-if="postInfo.visit != 0">访问量：{{ postInfo.visit }}</div>
            <div>类型：{{ newTypes }}</div>
            <!-- <div v-if="postInfo.editors.length != 0">责编：{{ editors }}</div> -->
          </div>
          <div class="zongText">{{ postInfo.mainText }}</div>
          <div class="editor" v-if="postInfo.editors.length != 0">
            <div class="editor-show">
              <div>(责编：{{ editors }})</div>
            </div>
          </div>
        </div>
        <div class="post-author" v-if="postInfo.publicForUser">
          <div class="hasAuthor">
            <div class="author-img" :style="{ backgroundImage: `url(` + postInfo.userHead + `)` }"></div>
            <div class="author">{{ postInfo.author }}</div>
            <div class="author-info">{{ postInfo.userShow.coultAllPubPosts }}个作品，{{ postInfo.userShow.coultAllPubPics }}张图</div>
            <div class="author-more"><a target="_blank" :href="'userShowDetail.html?id=' + postInfo.userShow.id">查看更多>></a></div>
          </div>
          <div class="symbol">
            <div class="zan">
              <div class="zan-icon" @click="onGetZan(postInfo.id)"></div>
              <span v-bind:class="{ red: tagStyle }">点赞</span>
            </div>
            <div class="collect">
              <div class="shoucang-icon" @click="onCollect()"></div>
              <span>收藏</span>
            </div>
            <div>
              <div class="share-icon" @click="onGetShare(postInfo.id)"></div>
              <span>分享</span>
            </div>
            <a class="bshareDiv" href="https://www.bshare.cn/share"></a>
          </div>
          <div class="tags">
            <div class="tag-title">标签：</div>
            <div class="tag-list">
              <div v-for="h in postInfo.tags" :key="h">{{ h }}</div>
            </div>
          </div>
          <div class="postbtn">
            <el-button type="danger" :disabled="!postInfo.downloadOri">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadOri ? onDownNumber('downo') : function () {}">
                <span>批量下载原图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.downloadMiddle">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadMiddle ? onDownNumber('downm') : function () {}">
                <span>批量下载中图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.downloadSmall">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadSmall ? onDownNumber('downs') : function () {}">
                <span>批量下载小图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.addCart">
              <div class="car"></div>
              <div class="number" @click="postInfo.addCart ? onDownNumber('car') : function () {}">
                <span>加入购物车</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <!-- <el-button type="danger" :disabled="!postInfo.canDownloadSignPic">
              <div class="bianji"></div>
              <div
                class="number"
                @click="
                  postInfo.canDownloadSignPic
                    ? onDownNumber('sign')
                    : function () {}
                "
              >
                <span>签发采编平台</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button> -->
          </div>
        </div>
        <div class="no-author" v-else>
          <div class="no-head"></div>
          <div>暂无用户信息</div>
          <div class="symbol">
            <div class="zan">
              <div class="zan-icon" @click="onGetZan(postInfo.id)"></div>
              <span v-bind:class="{ red: tagStyle }">点赞</span>
            </div>
            <div class="collect">
              <div class="shoucang-icon" @click="onCollect()"></div>
              <span>收藏</span>
            </div>
            <div>
              <div class="share-icon" @click="onGetShare(postInfo.id)"></div>
              <span>分享 </span>
            </div>
            <a class="bshareDiv" href="https://www.bshare.cn/share"></a>
          </div>
          <div class="tags">
            <div class="tag-title">标签：</div>
            <div class="tag-list">
              <div v-for="h in postInfo.tags" :key="h">{{ h }}</div>
            </div>
          </div>
          <div class="postbtn">
            <el-button type="danger" :disabled="!postInfo.downloadOri">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadOri ? onDownNumber('downo') : function () {}">
                <span>批量下载原图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.downloadMiddle">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadMiddle ? onDownNumber('downm') : function () {}">
                <span>批量下载中图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.downloadSmall">
              <div class="down-icon"></div>
              <div class="number" @click="postInfo.downloadSmall ? onDownNumber('downs') : function () {}">
                <span>批量下载小图</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <el-button type="danger" :disabled="!postInfo.addCart">
              <div class="car"></div>
              <div class="number" @click="postInfo.addCart ? onDownNumber('car') : function () {}">
                <span>加入购物车</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button>
            <!-- <el-button type="danger" :disabled="!postInfo.canDownloadSignPic">
              <div class="bianji"></div>
              <div
                class="number"
                @click="
                  postInfo.canDownloadSignPic
                    ? onDownNumber('sign')
                    : function () {}
                "
              >
                <span>签发采编平台</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button> -->
          </div>
        </div>
      </div>
      <div class="post-pics" v-if="!videoStatus">
        <div class="pics-down" v-if="loginStatus">
          <el-checkbox @change="onAllSelected" v-model="allCheck" :indeterminate="isIndeterminate">全选</el-checkbox>
        </div>
        <div class="post-con">
          <!-- <div class="post_pic_detail">
            <el-checkbox class="selected" v-model="selectedPic"></el-checkbox>
            <div class="post_pic_img"></div>
            <div class="post_pic_text">
              <div>图片说明：</div>
              <div>图片标签：</div>
              <div>图片Id：</div>
            </div>
          </div>
          <div class="post_pic_detail"></div>
          <div class="post_pic_detail"></div> -->
          <div class="post-img" v-for="(p, navIndex) in postInfo.pics" :key="p.id + '_nav'">
            <el-checkbox class="selected" v-model="p.selectedPic" @change="onChangeSelect" v-if="loginStatus"></el-checkbox>
            <div
              class="bg-img"
              :style="{ backgroundImage: `url(` + p.sImgURL + `)` }"
              :class="{
                transform: isTransform,
                transformRight: isTransformRight,
                active: picIndex == navIndex,
              }"
              @click="onEnlarge(navIndex)"
            ></div>
            <!-- <img
              class="bg-img"
              :src="p.sImgURL"
              :class="{
                transform: isTransform,
                transformRight: isTransformRight,
                active: picIndex == navIndex,
              }"
              @click="onEnlarge(navIndex)"
            /> -->
            <div class="pictext">
              <div class="status" v-if="!p.onnet && p.onnet != undefined">状态：下网图片</div>
              <div class="fen" :title="p.text">分说明：{{ p.text }}</div>
              <!-- <div class="tag"> -->
              <!-- <div class="tag-title">图片标签：</div> -->
              <!-- <div class="tag-list">
                    <div v-for="k in p.tags" :key="k">{{ k }}</div>
                  </div>
                </div> -->
              <div class="phototime">拍摄时间：{{ p.exifTime }}</div>
              <div class="phototype">相机型号：{{ p.exifModel }}</div>
              <div class="picId">ID:{{ p.id }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gohome">
        <a target="_top" :href="'index.html'">
          <img :src="gohomeimg" alt="" />
        </a>
      </div>

      <div class="allImg" v-if="largePicShow">
        <div class="largeImg">
          <div class="close el-icon-circle-close" @click="onColseLarge"></div>
          <div class="large-title-range" @mouseenter="onMoveUp()"></div>
          <div
            class="large-title"
            :class="{
              hide: isswiperright,
            }"
          >
            <div class="logo"></div>
            <div class="title">
              <div class="title-top">{{ postInfo.title }}</div>
              <div class="bottom">
                <div class="author">作者：{{ postInfo.author }}</div>
                <div class="time">时间：{{ postInfo.eventTime }}</div>
                <div class="addr">地点：{{ newAddress }}</div>
                <div class="type">类型：{{ newTypes }}</div>
              </div>
            </div>
          </div>
          <div class="large-detail" :style="{ left: '-' + picIndex * 80 + 'vw' }">
            <div class="img" v-for="(p, showIndex) in postInfo.pics" :key="p.id" :class="{ active: swiperIndex == showIndex }">
              <div
                class="tu-img"
                :style="{
                  backgroundImage: `url(` + p.imgURL + `)`,
                }"
              ></div>
              <div
                class="allText"
                :class="{
                  hide: isswiperright,
                }"
              >
                <div>主说明:{{ postInfo.mainText }}</div>
              </div>
              <div
                class="Text"
                :class="{
                  hide: isswiperright,
                }"
              >
                <div class="fenText">分说明：{{ p.text }}</div>
              </div>
              <div class="fengText-range" @mouseenter="onMoveUp()"></div>
            </div>
          </div>
          <div class="swiper">
            <div
              class="swiper-back"
              :class="{
                hide: isswiperright,
              }"
            >
              <div class="large-left">
                <div class="large-icon" @click="onLargeLeft()"></div>
              </div>
              <div class="swiper-list">
                <div class="swiper-con" :style="{ left: transformDistance + 'rem' }">
                  <div
                    class="swiper-img"
                    ref="swiperpic"
                    v-for="(p, swpIndex) in postInfo.pics"
                    :key="p.id"
                    :class="{
                      active: swiperIndex == swpIndex,
                    }"
                    @click="onSwiper(swpIndex)"
                  >
                    <img :src="p.imgURL" alt="" class="swiper_img" />
                  </div>
                </div>
              </div>
              <div class="large-right">
                <div class="larger-icon" @click="onLargeRight()"></div>
              </div>
            </div>

            <div class="photo-ico" @click="onReBack">
              <div class="ico-img"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分享的固定按钮 -->
      <div class="shareQR">
        <div class="share-text">分享到</div>
        <div class="share-img">
          <img :src="QRcodeImg" alt="" />
        </div>
      </div>
    </div>
    <form :action="cartUrl" ref="addCartPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="ssPicUrl" ref="ssPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="ooPicUrl" ref="ooPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="mmPicUrl" ref="mmPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="signPicUrl" ref="signPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="videoPicUrl" ref="videoPicForm" method="GET" target="_blank"></form>

    <div class="postWhole mobile-present" v-if="!videoStatus">
      <div class="mobile-title">{{ postInfo.title }}</div>
      <div class="mobile-text" v-bind:class="{ active: textMoreShow == true, autohieght: textAutoShow == true }">{{ postInfo.mainText }}</div>
      <div class="mobile-moreTextUp" v-if="textMoreShow == true && textAutoShow == false" @click="onMoreTextUp">收起<span class="el-icon-arrow-up"></span></div>
      <div class="mobile-moreTextDown" v-if="textMoreShow == false && textAutoShow == false" @click="onMoreTextDown">阅读全文<span class="el-icon-arrow-down"></span></div>
      <!-- <div class="mobile-editor" v-if="postInfo.editors.length != 0">
        <div class="editor">(责编：{{ editors }})</div>
      </div> -->
      <!-- <div class="mobile-time">
        <div>{{ postInfo.upTime }} {{ postInfo.author }}摄</div>
        <div class="visited" v-if="postInfo.visit != 0">访问量：{{ postInfo.visit }}</div>
      </div> -->
      <!-- <img class="mobile-frontImg" :src="postInfo.frontImg" @click="onMobileImageClick(postInfo.frontImg)" /> -->
      <!-- <div class="mobile-frontText" v-if="postInfo.pics">
        <div class="kuo" v-if="!isFrontKuo" @click="onKuo">首图小说明： 展开</div>
        <div class="kuo" v-if="isFrontKuo" @click="onKuo">首图小说明： 收起</div>
        <div class="text" v-bind:class="{ kuo: isFrontKuo }">
          <div v-for="h in postInfo.pics" :key="h.id">
            <span v-if="h.front">{{ h.text }}</span>
          </div>
        </div>
      </div> -->

      <div v-for="p in postInfo.pics" :key="p.id + '_mobile_p'">
        <div class="no-front">
          <img class="mobile-img" :src="p.imgURL" @click="onMobileImageClick(p.imgURL)" />
          <!-- <div class="mobile-picauthor">
            吴江图片库 <span>{{ postInfo.author }}</span> 摄
          </div> -->
          <div class="mobile-pictext" @click="onPicDetail(p.id)">点击查看图片详情</div>
        </div>
      </div>
    </div>
    <div class="postWhole mobile-present" v-if="videoStatus">
      <div class="video-detail">
        <div class="video-play">
          <div class="title">{{ postInfo.title }}</div>
          <video :src="postInfo.videoURL" :poster="postInfo.videoimgURL" controls loop width="1200" height="523"></video>
        </div>
        <div class="video-text">
          <div>作者：{{ postInfo.author }}</div>
          <div>视频ID：{{ postInfo.id }}</div>
          <div>上传时间：{{ postInfo.upTime }}</div>
          <div>地点：{{ newAddress }}</div>
          <div>类型：{{ newTypes }}</div>
          <div class="visited" v-if="postInfo.visit != 0">访问量：{{ postInfo.visit }}</div>
        </div>
        <div class="video-btn" v-if="postInfo.canDownloadMiddlePic">
          <div class="v-b">下载视频</div>
        </div>
        <div class="video-maintext">
          <div>正文:</div>
          <!-- <pre>{{ postInfo.mainText }}</pre> -->
          <div class="main-text-preview">{{ postInfo.mainText }}</div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>加入收藏失败，请使用Ctrl+D进行添加</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import gohomePic from '../assets/replay_img.png'
import QRCode from 'qrcode'
import wx from 'weixin-js-sdk'
// import bShare from "";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      postInfo: { editors: [] }, //稿件信息
      isFrontKuo: false,
      newTypes: '', // 转换后的类型
      newAddress: '', // 转换后的地址
      editors: '', // 转换后的编辑者
      gohomeimg: gohomePic, // 返回首页的图标
      isTransform: false,
      isTransformRight: false,
      isswiperform: false, // 点击移动一个
      isswipermRight: false,
      picIndex: 0,
      swiperIndex: 0, // 轮播的选中的哪一个
      largePicShow: false, // 是否显示图片的大图
      postPicArr: [],
      hasLength: false, // 是否被选中
      selectedLength: 0,
      allCheck: false, //默认全选的状态
      tagStyle: false, // 点赞后的样式
      selected: [], // 被选中的图片数组
      picIdsString: '',
      selectedPic: '',
      QRcodeImg: '', // 分享二维码的地址
      ssPicUrl: '', // 小图from地址
      ooPicUrl: '', // 原图from地址
      cartUrl: '', // 购物车from地址
      mmPicUrl: '', // 中图from地址
      signPicUrl: '', // 采编from地址
      videoPicUrl: '', // 视频form地址
      isswiperright: false, // 往右移动
      dialogVisible: false, //显示收藏的弹框
      isIndeterminate: false, // 半选的状态
      transformDistance: 1, // 查看大图时的小图的移动距离
      transformlargeDistance: 0, // 点击小图，中图的变化距离
      videoStatus: false, // 视频稿件的显示与隐藏
      loginStatus: false, // 是否登录的状态
      textMoreShow: false, // 是否展开更多文字。mobile下
      textAutoShow: false,
    }
  },
  created() {
    console.log('id by path', this.$route.params.id)
    RD.pure()
      .user()
      .sync()
      .then(() => {
        this.loginStatus = true
      })
    this.reload()
  },
  mounted() {
    let thiz = this
    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', thiz.onBridgeReady(), false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', thiz.onBridgeReady())
        document.attachEvent('onWeixinJSBridgeReady', thiz.onBridgeReady())
      }
    } else {
      thiz.onBridgeReady()
    }
  },
  methods: {
    onBridgeReady() {},
    onMobileImageClick(imageURL) {
      if (WeixinJSBridge != undefined) {
        let imageURLs = []
        this.postInfo.pics.forEach((pic) => {
          imageURLs.push(pic.imgURL)
        })
        WeixinJSBridge.invoke('imagePreview', {
          current: imageURL,
          urls: imageURLs,
        })
      }
    },
    reload() {
      var thiz = this
      var shareUrl = window.location.href
      var postId = this.$route.params.id
      if (!postId) {
        postId = this.$route.query.id
      }
      RD.pure()
        .post()
        .id(postId)
        .one()
        .then((data) => {
          var newArr = []
          data.eventTypeNamePaths.forEach((i) => {
            var type = []
            i.forEach((k) => {
              type.push(k)
            })

            newArr.push(type.join('>'))
          })

          thiz.newTypes = newArr.join('●')
          // postInfo.editors
          var editor = []
          data.editors.forEach((s) => {
            editor.push(s)
          })
          thiz.editors = editor.join(' ')

          data.pics.forEach((k) => {
            k.imgURL = RD.pic().id(k.id).mImage_URL()
            k.sImgURL = RD.pic().id(k.id).sImage_URL()
            k.selectedPic = false
          })
          data.frontImg = RD.pic().id(data.frontPicId).mImage_URL()
          var newaddr = []
          data.eventAddrNamePath.forEach((k) => {
            newaddr.push(k)
          })
          thiz.newAddress = newaddr.join('>')
          if (data.publicForUser) {
            data.userHead = RD.userShow().id(data.userShow.id).headPic_URL()
          }
          if (data.postType == 'VIDEO') {
            thiz.videoStatus = true
            data.videoimgURL = RD.video().id(data.id).poster_URL()
            data.videoURL = RD.video().id(data.id).mvideo_URL()
          } else {
            thiz.videoStatus = false
          }

          if (data.mainText.length <= 120) {
            thiz.textAutoShow = true
          } else {
            thiz.textAutoShow = false
          }
          data.mainText = data.mainText.trim()
          thiz.shareSign(data)
          document.title = '吴江图片库---' + data.title
          thiz.postInfo = data
          thiz.postPicArr = JSON.parse(JSON.stringify(thiz.postInfo.pics))
        })
        .catch((err) => {
          if (err.code == -2) {
            var msg = '此稿件未通过审核，是否进入吴江图片库首页？'
            this.$confirm(msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.$router.push('/index.html')
              })
              .catch(() => {})
          } else {
            var msg = err.msg + ',是否回到首页'
            this.$confirm(msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.$router.push('/index.html')
              })
              .catch(() => {})
          }
        })

      QRCode.toDataURL(shareUrl)
        .then((url) => {
          this.QRcodeImg = url
        })
        .catch((err) => {
          console.error('err', err)
        })
    },
    shareSign(data) {
      var link = location.href
      var descNow = data.mainText.substring(0, 99)
      if (data.postType == 'VIDEO') {
        var frontImgUrl = RD.video().id(data.id).poster_URL()
      } else if (data.postType == 'PIC') {
        var frontImgUrl = RD.pic().id(data.frontPicId).sImage_URL()
      }
      console.log('mxsl', frontImgUrl)
      wx.ready(function (res) {
        wx.updateAppMessageShareData({
          title: '吴江图片库' + '《' + data.title.trim() + '》',
          desc: descNow,
          link: link,
          imgUrl: frontImgUrl,
          trigger: function (res) {},
          success: function (res) {},
          cancel: function (res) {},
          fail: function (res) {},
        })
        wx.updateTimelineShareData({
          title: '吴江图片库' + '《' + data.title.trim() + '》',
          link: link,
          imgUrl: frontImgUrl,
          trigger: function (res) {},
          success: function (res) {},
          cancel: function (res) {},
          fail: function (res) {},
        })
        wx.onMenuShareAppMessage({
          title: '吴江图片库' + '《' + data.title.trim() + '》',
          link: link,
          imgUrl: frontImgUrl,
          trigger: function (res) {},
          success: function (res) {},
          cancel: function (res) {},
          fail: function (res) {},
        })
      })
      RD.wx()
        .sign(link)
        .then((data) => {
          wx.config({
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature, // 签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
          })
          wx.error(function (res) {
            console.log(res)
          })
        })
    },
    onChangeSelect() {
      var thiz = this
      thiz.selected = []
      thiz.postInfo.pics.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.selected.push(k.id)
        }
      })
      thiz.selectedLength = thiz.selected.length
      if (thiz.selected.length >= 1) {
        thiz.hasLength = true
      }
      if (thiz.selected.length == thiz.postInfo.pics.length) {
        thiz.allCheck = true
        thiz.isIndeterminate = false
      } else if (thiz.selected.length == 0) {
        thiz.allCheck = false
        thiz.isIndeterminate = false
      } else if (thiz.selected.length >= 1) {
        thiz.isIndeterminate = true
      }
    },
    // 全选
    onAllSelected() {
      var thiz = this
      if (thiz.allCheck) {
        var allSelected = []
        thiz.postInfo.pics.forEach((k) => {
          k.selectedPic = true
          allSelected.push(k.id)
        })
        thiz.selectedLength = allSelected.length
        if (allSelected.length > 1) {
          thiz.hasLength = true
        }
        thiz.selected = allSelected
        if (thiz.selected.length == thiz.postInfo.pics.length) {
          thiz.allCheck = true
          thiz.isIndeterminate = false
        }
      } else {
        thiz.selected = []
        thiz.isIndeterminate = false
        thiz.selectedLength = 0
        thiz.allCheck = false
        thiz.postInfo.pics.forEach((k) => {
          k.selectedPic = false
        })
      }
    },
    // 下载视频
    onVideoDownload(videoId) {
      console.log('videoDown')
      var thiz = this
      thiz.videoPicUrl = RD.video().id(videoId).mvideo_URL()
      thiz.$nextTick(() => {
        thiz.$refs.videoPicForm.submit()
      })
    },
    onDownNumber(type) {
      var thiz = this
      console.log('sf', thiz.selected)
      if (thiz.selected.length == 0) {
        thiz.$message('请先勾选图片，然后进行操作')
        return
      }
      thiz.picIdsString = JSON.stringify(thiz.selected)
      if (type == 'car') {
        thiz.cartUrl = RD.pic().toCart(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.addCartPicForm.submit()
        })
      } else if (type == 'downs') {
        thiz.ssPicUrl = RD.pic().ssImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.ssPicForm.submit()
        })
      } else if (type == 'downo') {
        thiz.ooPicUrl = RD.pic().oImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.ooPicForm.submit()
        })
      } else if (type == 'downm') {
        thiz.mmPicUrl = RD.pic().mmImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.mmPicForm.submit()
        })
      } else if (type == 'sign') {
        thiz.signPicUrl = RD.pic().signOut(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.signPicForm.submit()
        })
      }
    },
    onLargeLeft() {
      clearInterval(timers)
      this.isswiperright = false
      this.next()
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0
      } else if (this.swiperIndex == 2) {
        this.swiperIndex = this.swiperIndex - 1
      } else if (this.swiperIndex == 1) {
        this.swiperIndex = this.swiperIndex - 1
      } else {
        this.swiperIndex = this.swiperIndex - 1
        this.$nextTick(function () {
          this.calculate()
        })
      }
    },
    onLargeRight() {
      clearInterval(timers)
      this.isswiperright = false
      this.next()
      if (this.swiperIndex == this.postInfo.pics.length - 2) {
        this.swiperIndex = this.swiperIndex + 1
      } else if (this.swiperIndex == this.postInfo.pics.length - 3) {
        this.swiperIndex = this.swiperIndex + 1
      } else if (this.swiperIndex == this.postInfo.pics.length - 1) {
        this.swiperIndex = this.swiperIndex + 0
      } else {
        this.swiperIndex = this.swiperIndex + 1
        this.$nextTick(function () {
          this.calculate()
        })
      }
    },
    // 点击大图，查看图片详情
    onLookPic(navIndex) {
      this.largePicShow = true
      this.swiperIndex = navIndex
      this.$nextTick(function () {
        this.calculate()
      })
      this.next()
    },
    next() {
      timers = setInterval(() => {
        this.isswiperright = true
      }, 8000)
    },
    // 大图预览的关闭
    onColseLarge() {
      this.largePicShow = false
      clearInterval(timers)
    },
    // 范围上移
    onMoveUp() {
      this.isswiperright = false
      clearInterval(timers)
    },
    // 计算移动距离
    calculate() {
      var toMiddleOffset = 0
      for (var i = 0; i < this.swiperIndex; i++) {
        toMiddleOffset += this.$refs.swiperpic[i].clientWidth + 10
      }
      toMiddleOffset += this.$refs.swiperpic[this.swiperIndex].clientWidth / 2
      // console.log('toMiddleOffset', toMiddleOffset)
      var offset = 300 - toMiddleOffset
      if (offset > 0) {
        offset = 0
      }
      var allWidth = 0
      for (var k = 0; k < this.postInfo.pics.length; k++) {
        allWidth += this.$refs.swiperpic[k].clientWidth + 10
      }
      if (offset <= 600 - allWidth) {
        offset = 600 - allWidth
      }
      this.transformDistance = offset / 10
      // console.log('toMiddleOffset', this.transformDistance)
    },
    onSwiper(index) {
      clearInterval(timers)
      this.isswiperright = false
      this.next()
      this.swiperIndex = index
      this.$nextTick(function () {
        this.calculate()
      })
    },
    // 点击小图列表
    onEnlarge(index) {
      var thiz = this
      thiz.transformlargeDistance = index * 102.9
      thiz.picIndex = index
    },
    // 点赞
    onGetZan(postId) {
      var thiz = this
      if (!thiz.tagStyle) {
        RD.post()
          .id(postId)
          .tagUp()
          .then(() => {
            thiz.$message('点赞成功')
            thiz.tagStyle = true
          })
      }
    },
    // 收藏
    onCollect() {
      try {
        window.external.addFavorite(window.location, document.title)
      } catch (e) {
        try {
          window.sidebar.addPanel(window.location, document.title, '')
        } catch (e) {
          // sweetAlert("加入收藏失败，请使用Ctrl+D进行添加");
          this.dialogVisible = true
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 分享
    onGetShare() {
      console.log('466')
      this.$message('请点击右侧分享按钮进行分享')
    },

    // 收回文字
    onReBack() {
      this.isswiperright = !this.isswiperright
      if (this.isswiperright == false) {
        clearInterval(timers)
      }
    },
    // 详情的首页的大图的翻看，左
    onBigPicLeft() {
      console.log('left', this.picIndex)
      if (this.picIndex > 0) {
        this.transformlargeDistance = this.transformlargeDistance - 102.9
        this.picIndex = this.picIndex - 1
      } else {
        this.transformlargeDistance = 0
        this.picIndex = 0
        this.$message('已经是第一张图片了')
      }
    },
    // 详情的首页的大图的翻看，右
    onBigPicRight() {
      var piclength = this.postInfo.pics.length - 1
      if (this.picIndex == piclength) {
        this.$message('已经是最后一张了')
        this.picIndex = piclength
      } else {
        this.transformlargeDistance = this.transformlargeDistance + 102.9
        this.picIndex = this.picIndex + 1
      }
    },
    // mobile下的展开更多正文
    onMoreTextUp() {
      this.textMoreShow = false
    },
    // mobile下的展开更多正文
    onMoreTextDown() {
      this.textMoreShow = true
    },
    onKuo() {
      this.isFrontKuo = !this.isFrontKuo
    },
    // 图片详情页
    onPicDetail(picId) {
      var postId = this.postInfo.id
      this.$router.push({
        path: '/picDetail.html',
        query: { picId: picId, postId: postId },
      })
    },
  },
}
</script>

<style scoped lang="less">
.nav-list {
  z-index: 2;
}
.postWhole.desktop-present {
  min-height: 66.6rem;
  background-color: #fff;
  width: 120rem;
  margin: 0 auto;
  // border-top: 0.1rem solid #ccc;
  position: relative;
  .video-detail {
    min-height: calc(100vh - 28.1rem);
    width: 100%;
    .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      font-size: 3rem;
      height: 10rem;
      line-height: 10rem;
    }
    video {
      width: 120rem;
      height: 67.5rem;
      object-fit: contain;
      background: #000;
    }
    .video-text {
      width: 100%;
      height: auto;
      margin: 2rem 0rem;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.5rem;
      min-height: 4rem;
      padding-top: 1.5rem;
      padding-left: 0.5rem;
      line-height: 3rem;
      div {
        text-align: left;
        margin-right: 2.5rem;
      }
      .visited {
        color: red;
      }
    }
    .video-btn {
      height: 5rem;
      .v-b {
        width: 10rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 1.5rem;
        border-radius: 2rem;
        border: 1px solid #ccc;
        background-color: #409eff;
        color: #fff;
        cursor: pointer;
      }
    }
    .video-maintext {
      width: 100%;
      height: auto;
      margin: 2rem 0rem;
      padding-left: 0.5rem;
      font-size: 1.8rem;
      div {
        text-align: left;
        margin-right: 2.5rem;
      }
      .main-text-preview {
        text-align: left;
        text-indent: 3rem;
        line-height: 3.2rem;
        font-size: 1.6rem;
        text-overflow: ellipsis;
        overflow-y: auto;
        display: -webkit-box;
        white-space: pre-wrap;
        // -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .post-detail {
    height: auto;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    .posttext {
      width: 102.9rem;
      height: 100%;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 10rem;
        line-height: 10rem;
      }
      .bigPic {
        width: 100%;
        height: 63.1rem;
        display: flex;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        .bigpic-number {
          color: #fff;
          position: absolute;
          bottom: 2rem;
          left: 0rem;
          height: 2rem;
          width: 100%;
          text-align: left;
          font-size: 1.3rem;
          font-style: italic;
        }
        .pic-text {
          color: #fff;
          position: absolute;
          bottom: 0rem;
          left: 0rem;
          height: 2rem;
          width: 100%;
          text-align: left;
          font-size: 1.3rem;
          font-style: normal;
        }
        .pic-con {
          height: 63.1rem;
          position: absolute;
          width: auto;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          flex-shrink: 0;
          transition: all 800ms ease-in-out;
          .big-img {
            overflow: hidden;
            min-width: 102.9rem;
            height: 63.1rem;
            background: no-repeat 50% / contain;
            transition: all 0.3s ease-in-out;
            background-color: #bfbfbf;
            position: relative;
            .bigpic-left {
              position: absolute;
              top: 0rem;
              left: -4.8rem;
              width: 49rem;
              height: 63.1rem;
              .left-icon {
                position: absolute;
                transition: all 800ms ease-in-out;
                top: 45%;
                left: -3.5rem;
                background-color: rgba(0, 0, 0, 0.4);
                height: 7rem;
                width: 8rem;
                font-size: 2rem;
                cursor: pointer;
                .el-icon-d-arrow-left {
                  color: #fff;
                  font-size: 5rem;
                  margin-top: 1rem;
                }
              }
            }
            .bigpic-right {
              position: absolute;
              transition: all 800ms ease-in-out;
              top: 0rem;
              left: 56.7rem;
              width: 46.5rem;
              height: 63.1rem;
              .right-icon {
                position: absolute;
                transition: all 800ms ease-in-out;
                top: 45%;
                right: -7.7rem;
                background-color: rgba(0, 0, 0, 0.4);
                height: 7rem;
                width: 8rem;
                font-size: 2rem;
                cursor: pointer;
                .el-icon-d-arrow-right {
                  color: #fff;
                  font-size: 5rem;
                  margin-top: 1rem;
                }
              }
            }
            .bigpic-left:hover {
              .left-icon {
                left: 3.5rem;
              }
            }
            .bigpic-right:hover {
              .right-icon {
                right: 0rem;
              }
            }
          }
        }
      }
      .other {
        width: 100%;
        height: auto;
        margin-top: 2rem;
        background-color: #bfbfbf;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.5rem;
        min-height: 4rem;
        padding-top: 1rem;
        padding-left: 0.5rem;
        line-height: 3rem;
        box-sizing: border-box;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
      }
      .zongText {
        width: 100%;
        margin-top: 3rem;
        text-align: left;
        text-indent: 3rem;
        line-height: 2.2rem;
        font-size: 1.6rem;
        height: 11rem;
        text-overflow: ellipsis;
        overflow-y: auto;
        display: -webkit-box;
        white-space: pre-wrap;
        // -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
      .editor {
        display: flex;
        width: 100%;
        height: 5rem;
        font-size: 1.6rem;
        line-height: 5rem;
        position: relative;
        .editor-show {
          position: absolute;
          right: 2.5rem;
        }
      }
      .tag {
        display: flex;
        font-size: 1.2rem;
        margin-left: 3rem;
        margin-top: 2rem;
        .sign {
          display: flex;
          div {
            background-color: #dcdcdc;
            width: 5rem;
            height: 2rem;
            line-height: 2rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
    .no-author {
      width: 12%;
      height: 18rem;
      margin-left: 2rem;
      margin-top: 11.2rem;
      .no-head {
        height: 100%;
        width: 100%;
        background: no-repeat url(../assets/head.png) center;
        background-size: contain;
      }
      .symbol {
        height: auto;
        width: 100%;
        margin: 2.5rem auto;
        line-height: 3rem;
        div {
          border: 0.1rem solid #ccc;
          width: 15rem;
          height: 3rem;
          margin: 1rem auto;
          display: flex;
          .zan-icon,
          .shoucang-icon,
          .share-icon {
            width: 2rem;
            height: 2rem;
            border: none;
            margin-left: 4.5rem;
            margin-top: 0.4rem;
            cursor: pointer;
          }
          .zan-icon {
            background: no-repeat url(../assets/dianzan.png);
            background-size: cover;
          }
          span {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            margin-right: 5rem;
          }
          .shoucang-icon {
            background: no-repeat url(../assets/shoucang.png);
            background-size: cover;
          }
          .share-icon {
            background: no-repeat url(../assets/fenxiang_2.png);
            background-size: cover;
          }
          .red {
            color: red;
          }
        }
      }
      .tags {
        max-height: 137px;
        width: 16.2rem;
        margin: 0 auto;
        display: flex;
        .tag-title {
          font-size: 1.3rem;
          width: 4.9rem;
        }
        .tag-list {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          width: 15rem;
          div {
            width: auto;
            min-width: 6rem;
            height: 3rem;
            line-height: 3rem;
            background-color: #dcdcdc;
            margin-left: 0.5rem;
            margin-top: 0.5rem;
          }
        }
      }
      .postbtn {
        margin-top: 1.6rem;
        .el-button {
          text-align: left;
          width: 15rem;
          margin-top: 1.5rem;
        }
        .el-button:first-child {
          margin-left: 1rem;
        }
      }
    }
    .post-author {
      width: 15rem;
      height: 100%;
      position: relative;
      margin-top: 11.4rem;
      margin-left: 2rem;
      .hasAuthor {
        position: relative;
        height: 20.4rem;
        .author-img {
          height: 10rem;
          width: 10rem;
          border-radius: 50%;
          margin: 0 auto;
          border: 0.1rem solid #000;
          background: no-repeat 50% / cover;
        }
        .author {
          margin-top: 1rem;
          font-size: 1.5rem;
          font-weight: bold;
        }
        .author-info {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
        .author-more {
          position: absolute;
          right: 1rem;
          top: 85%;
          bottom: 5rem;
          cursor: pointer;
        }
      }

      .symbol {
        height: auto;
        width: 100%;
        margin: 2.5rem auto;
        line-height: 3rem;
        div {
          border: 0.1rem solid #ccc;
          width: 17rem;
          height: 3rem;
          margin: 1rem auto;
          display: flex;
          .zan-icon,
          .shoucang-icon,
          .share-icon {
            width: 2rem;
            height: 2rem;
            border: none;
            margin-left: 6.5rem;
            margin-top: 0.4rem;
            cursor: pointer;
          }
          .zan-icon {
            background: no-repeat url(../assets/dianzan.png);
            background-size: cover;
          }
          span {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            margin-right: 5rem;
          }
          .shoucang-icon {
            background: no-repeat url(../assets/shoucang.png);
            background-size: cover;
          }
          .share-icon {
            background: no-repeat url(../assets/fenxiang_2.png);
            background-size: cover;
          }
          .red {
            color: red;
          }
        }
      }
      .tags {
        height: auto;
        width: 19.2rem;
        margin: 0 auto;
        display: flex;
        .tag-title {
          font-size: 1.3rem;
          width: 4.9rem;
        }
        .tag-list {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          width: 15rem;
          div {
            width: auto;
            min-width: 6rem;
            height: 3rem;
            line-height: 3rem;
            background-color: #dcdcdc;
            margin-left: 0.5rem;
            margin-top: 0.5rem;
          }
        }
      }
      .postbtn {
        margin-top: 1.6rem;
        .el-button {
          text-align: left;
          width: 15rem;
          margin-top: 1.5rem;
        }
        .el-button:first-child {
          margin-left: 1rem;
        }
      }
    }
  }
  .post-pics {
    height: auto;
    width: 100%;
    .pics-down {
      text-align: left;
      margin: 2rem;
      display: flex;
      .el-checkbox {
        line-height: 2.8rem;
      }
      .el-button {
        margin-left: 2rem;
      }
      .down {
        width: 18.6rem;
        height: 3rem;
        cursor: pointer;
        line-height: 3rem;
        text-align: center;
        background-color: #e60012;
        border-radius: 02rem;
        margin-right: 1rem;
        margin-left: 1rem;
        color: #fff;
        font-size: 1.2rem;
        position: relative;
        display: flex;
        padding-left: 1.5rem;
        .down-icon {
          width: 2.2rem;
          height: 2.2rem;
          margin-top: 0.2rem;
          background: no-repeat url(../assets/xiazai.png);
          background-size: cover;
        }
        .car {
          width: 2.8rem;
          height: 2.8rem;
          background: no-repeat url(../assets/car.png);
          background-size: cover;
        }
        .bianji {
          width: 2rem;
          height: 2rem;
          margin-top: 0.3rem;
          background: no-repeat url(../assets/bianji.png);
          background-size: cover;
        }
      }
      .number {
        margin-left: 1rem;
      }
    }
    .post-con {
      height: auto;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 3rem 0rem;
      overflow: hidden;
      background-color: #eeeeee;
      .post-img {
        width: 27rem;
        height: auto;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        margin-bottom: 2rem;
        background: no-repeat 50% / cover;
        position: relative;
        .selected {
          position: absolute;
          top: -2remx;
          left: 0rem;
        }
        .bg-img {
          width: 100%;
          height: 16rem;
          background: no-repeat 50%;
          background-size: contain;
        }
        .pictext {
          text-align: left;
          font-size: 1.5rem;
          margin-top: 2rem;
          line-height: 2rem;
          height: auto;
          position: relative;
          .status {
            color: red;
          }
          .fen {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .tag {
            height: auto;
            display: flex;
            .tag-title {
              width: 8rem;
            }
            .tag-list {
              width: 19rem;
              display: flex;
              flex-wrap: wrap;
              div {
                width: auto;
                margin-right: 0.5rem;
              }
            }
          }
          .picId {
            // position: absolute;
            // bottom: 1rem;
            height: 3rem;
          }
          .phototime {
            height: 3rem;
          }
          .phototype {
            height: auto;
          }
        }
      }
    }
  }
  .gohome {
    position: fixed;
    bottom: 16%;
    margin-left: -5rem;
    z-index: 99;
    a {
      width: 3rem;
      display: inline-block;
      height: 5remx;
    }
  }
  .allImg {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    z-index: 105;
    .largeImg {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      position: relative;
      .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 4.6rem;
        height: 4.6rem;
        color: red;
        font-size: 3.5rem;
        cursor: pointer;
        z-index: 22224;
      }
      .swithch {
        position: absolute;
        right: 8rem;
        top: 3rem;
      }
      .large-title-range {
        height: 9rem;
        width: 100%;
        position: absolute;
        top: 0rem;
        z-index: 22223;
      }
      .large-title {
        position: absolute;
        top: 0rem;
        left: 0rem;
        color: #fff;
        height: 9rem;
        line-height: 5rem;
        text-align: left;
        font-size: 2.4rem;
        display: flex;
        background-color: rgba(38, 39, 43, 0.94);
        transition: all 500ms ease-in-out;
        z-index: 22222;
        width: 100%;
        .logo {
          width: 20rem;
          height: 8rem;
          background: no-repeat url(../assets/logodetail.png);
          background-size: contain;
          margin-top: 2rem;
        }
        .title {
          border-left: 0.1rem solid #ccc;
          padding-left: 2rem;
          .bottom {
            display: flex;
            flex-wrap: nowrap;
            .author,
            .time,
            .addr,
            .type {
              font-size: 1.5rem;
              height: 4.8rem;
              width: auto;
              margin-right: 1rem;
            }
          }
        }
      }
      .large-title.hide {
        top: -10rem;
      }
      .large-detail {
        display: flex;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        transition: all 500ms ease-in-out;
        flex-direction: row;
        .img {
          width: 0rem;
          height: 100%;
          margin: 0 auto;
          background: #000;
          transition: all 0.3s ease-in-out;
          position: relative;
          overflow: hidden;
          .tu-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / contain;
          }
          .tu-imgcontain {
            width: 100%;
            height: 80%;
            background: no-repeat 50% / cover;
            margin-top: 2rem;
          }
          .allText {
            transition: all 800ms ease-in-out;
            width: 38.6rem;
            position: absolute;
            left: 0rem;
            top: 11.1rem;
            height: 60rem;
            line-height: 3.6rem;
            padding: 0.5rem;
            font-size: 1.8rem;
            background-color: rgba(38, 39, 43, 0.94);
            color: #fff;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-indent: 2em;
          }
          .allText.hide {
            left: -40rem;
          }
          .fengText-range {
            position: absolute;
            bottom: -8rem;
            left: 0rem;
            height: 8rem;
            width: 100%;
          }
          .Text {
            transition: all 800ms ease-in-out;
            margin: 0 auto;
            width: 100%;
            position: absolute;
            left: 0rem;
            bottom: 0rem;
            background-color: rgba(38, 39, 43, 0.94);
            height: 7rem;
            line-height: 7rem;
            font-size: 1.5rem;
            .zongText,
            .fenText {
              color: #fff;
              text-align: left;
              text-indent: 3rem;
              line-height: 3rem;
            }
          }
          .Text.hide {
            bottom: -10rem;
          }
        }
        .img.active {
          width: 100%;
          height: 100%;
        }
      }
      .swiper {
        height: 12%;
        width: 80rem;
        position: absolute;
        bottom: 9.5rem;
        right: 0rem;
        .swiper-back {
          transition: all 800ms ease-in-out;
          position: absolute;
          right: 11.3rem;
          top: 1rem;
          width: 80rem;
          height: 100%;
          .large-left {
            width: 5rem;
            height: 8rem;
            background-color: rgba(38, 39, 43, 0.94);
            position: absolute;
            left: 9.1rem;
            top: 1rem;
            .large-icon {
              position: absolute;
              top: 30%;
              left: 1.7rem;
              width: 3rem;
              height: 3rem;
              border-top: 0.6rem solid #fff;
              border-left: 0.6rem solid #fff;
              transform: rotate(-45deg);
            }
          }

          .large-right {
            width: 5rem;
            height: 7.8rem;
            background-color: rgba(38, 39, 43, 0.94);
            position: absolute;
            top: 1rem;
            right: -1rem;
            .larger-icon {
              position: absolute;
              top: 32%;
              right: 1.3rem;
              width: 3rem;
              height: 3rem;
              transform: rotate(-45deg);
              border-bottom: 0.6rem solid #fff;
              border-right: 0.6rem solid #fff;
            }
          }

          .swiper-list {
            width: 60rem;
            height: 8rem;
            position: absolute;
            right: 5rem;
            top: 1rem;
            overflow: hidden;
            .swiper-con {
              position: absolute;
              left: 0px;
              top: 0px;
              width: auto;
              height: 100%;
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              flex-shrink: 0;
              transition: all 800ms ease-in-out;
              box-sizing: border-box;
              .swiper-img {
                width: auto;
                height: 98%;
                margin-right: 1rem;
                box-sizing: border-box;
                .swiper_img {
                  height: 100%;
                  background: no-repeat 50% / contain;
                }
              }

              .transform {
                transform: translateX(-10vw);
              }
              .transformRight {
                transform: translateX(10vw);
              }
              .swiper-img.active {
                border: 0.1rem solid red;
              }
            }
          }
        }

        .swiper-back.hide {
          right: -85rem;
        }
        .transformXright {
          transform: translateX(100vw);
          transition: all 2400ms ease-in-out;
        }

        .photo-ico {
          position: absolute;
          right: 0rem;
          top: 2rem;
          width: 9rem;
          height: 7.8rem;
          background-color: #000;
          z-index: 99;
          .ico-img {
            width: 8rem;
            height: 5.8rem;
            margin-top: 1rem;
            margin-left: 1rem;
            background: no-repeat url(../assets/photoicon.png);
            background-size: contain;
          }
        }
      }
    }
  }
}
.postWhole.mobile-present {
  width: 750px;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  min-height: calc(100vh - 217px);
  .mobile-title {
    font-size: 45px !important;
    font-weight: bold;
    font-family: '黑体';
    text-align: center;
  }
  .mobile-time {
    font-size: 24px;
    margin: 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    .editor {
      margin-left: 5px;
    }
    .visited {
      width: auto;
      line-height: 31px;
      font-size: 24px;
      color: red;
      margin-left: 15px;
    }
  }
  .mobile-text {
    font-size: 30px !important;
    margin: 35px 35px 0px;
    line-height: 65px;
    // white-space: pre-wrap;
    overflow: hidden;
    text-indent: 67px;
    word-break: break-all; //对英文有效，在溢出处换行
    text-align: justify; //文字向两侧对齐，对最后一行无效。
    text-justify: auto;
  }
  .active {
    height: auto !important;
  }
  .autohieght {
    height: auto !important;
  }
  .mobile-moreTextDown {
    height: auto;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
  .mobile-editor {
    font-size: 30px;
    position: relative;
    height: 70px;
    line-height: 50px;
    .editor {
      position: absolute;
      right: 150px;
    }
  }
  .mobile-moreTextUp {
    height: 50px;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
  .mobile-frontImg {
    display: block !important;
    width: 730px;
  }
  .mobile-frontText {
    height: auto;
    font-size: 21px;
    margin-top: 15px;
    line-height: 30px;
    margin-left: 10px;
    font-weight: bold;
    font-family: '宋体';
    .text {
      height: 0px;
      display: none;
    }
    .kuo {
      display: block;
      height: auto;
    }
  }
  .front {
    display: none;
  }
  .no-front {
    width: 730px;
    margin: 0 auto;
    .mobile-img {
      width: 100%;
    }
  }

  .mobile-pictext {
    font-size: 30px;
    text-align: center;
    margin: 10px 0px;
    width: 730px;
    padding: 0rem 10px;
    cursor: pointer;
    span {
      margin: 0px 10px;
    }
  }
  .mobile-picauthor {
    text-align: center;
    font-size: 30px;
    margin: 10px 0px;
    width: 730px;
    padding: 0rem 10px;
  }
  .video-detail {
    min-height: calc(100vh - 28.1rem);
    width: 750px;
    .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      font-size: 3rem;
      height: 10rem;
      line-height: 10rem;
    }
    video {
      width: 750px;
      height: 422px;
      object-fit: cover;
    }
    .video-text {
      width: 100%;
      height: auto;
      margin: 2rem 0rem;
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      min-height: 4rem;
      padding-top: 1.5rem;
      padding-left: 0.5rem;
      line-height: 40px;
      div {
        text-align: left;
        margin-right: 2.5rem;
      }
      .visited {
        color: red;
      }
    }
    .video-btn {
      height: 50px;
      .v-b {
        width: 100px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        border-radius: 20px;
        border: 1px solid #ccc;
        background-color: #409eff;
        color: #fff;
        cursor: pointer;
      }
    }
    .video-maintext {
      width: 100%;
      height: auto;
      margin: 2rem 0rem;
      padding-left: 0.5rem;
      font-size: 16px;
      div {
        text-align: left;
        margin-right: 2.5rem;
      }
      .main-text-preview {
        text-align: left;
        text-indent: 3rem;
        line-height: 2.2rem;
        font-size: 1.6rem;
        height: 11rem;
        text-overflow: ellipsis;
        overflow-y: auto;
        display: -webkit-box;
        white-space: pre-wrap;
        // -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }
}
.shareQR {
  position: fixed;
  top: 400px;
  right: -190px;
  display: flex;
  height: 180px;
  transition: all 200ms ease-in-out;
  transition-delay: 1s;
  border: 3px solid rgba(0, 0, 0, 0.5);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  .share-text {
    width: 28px;
    height: 100%;
    background: #ccc;
    writing-mode: tb-rl;
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
    padding-right: 10px;
  }
  .share-img {
    width: 180px;
    height: 180px;
    background-color: #fff;
    img {
      margin-top: 10px;
    }
  }
}
.shareQR:hover {
  right: 0px;
  transition-delay: 0s;
}
</style>
