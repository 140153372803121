<template>
  <div class="user-detail">
    <div class="detail-con mobile-present">
      <!-- <div class="detail-mobile-title">{{ reporterOne.name }}</div> -->
      <div class="detail-mobile-userImg">
        <img class="mobile_img" :src="reporterOne.imgURL" />
      </div>
      <div class="mobile-user-text">
        <div class="mobile-user-title">艺术简介</div>
        <div>
          <span>{{ reporterOne.name }}</span>
          <span v-html="reporterOne.detail"></span>
        </div>
      </div>
      <div class="mobile-user-pic">
        <div class="pic-show">{{ reporterOne.name }}作品赏析</div>
        <div class="pic-list" v-for="post in postpicList" :key="post.id">
          <img class="pic-img" :src="post.imageURL" alt="" />
        </div>
      </div>
    </div>
    <div class="detail-con desktop-present">
      <div class="detail-top">
        <div class="kuan">
          <div class="tip"></div>
        </div>
        <div class="detail-txet">
          <div class="detail-img">
            <div class="m_img" :style="{ backgroundImage: `url(` + reporterOne.imgURL + `)` }"></div>
          </div>
          <div class="detail-comment">
            <span class="name">{{ reporterOne.name }}</span>
            <span v-html="reporterOne.detail"></span>
          </div>
        </div>
      </div>
      <div class="detail-post">
        <div class="post-title">
          <div class="name">优秀作品</div>
          <div class="en-name">Excellent Works</div>
          <div class="more">
            <a :href="'/userPostMore.html' + '?id=' + reporterOne.id" target="_blank">更多>></a>
          </div>
        </div>
        <div class="post-list">
          <div class="post-item" v-for="post in postpicList" :key="post.id">
            <div class="item">
              <div class="item-img" :style="{ backgroundImage: `url(` + post.imageURL + `)` }" @click="onLookPost(post.id)"></div>
              <div class="item-info">
                <div class="title">{{ post.title }}</div>
                <div class="name">---- {{ post.author }} ----</div>
                <div class="time">{{ post.eventTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-container">
          <el-pagination
            class="pager"
            @current-change="onPageChange"
            :current-page="pager.currentPage"
            :page-size="pager.pageSize"
            :hide-on-single-page="false"
            layout="prev, pager, next, jumper"
            :total="pager.total"
          ></el-pagination>
        </div>
      </div>
      <div class="gohome">
        <a target="_top" :href="'index.html'">
          <img :src="gohomeimg" alt="" />
        </a>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'
import gohomePic from '../assets/replay_img.png'

export default {
  components: { Bottom },
  data: function () {
    return {
      reporterOne: {}, //记者的信息
      swiperIndex: 0, // 轮播的index
      transformDistance: 1, // 图片移动的距离
      transformSwiperDistance: 1,
      gohomeimg: gohomePic, // 返回首页的图标
      postpicList: [],
      picText: '',
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
    }
  },
  created() {
    this.reload()
  },
  methods: {
    async reload() {
      console.log('sjjs', this.$route.query)
      if (this.$route.query.code) {
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithPic(this.$route.query.code)
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL()
            data.headPicDetails.forEach((k) => {
              k.imageURL = RD.pic().id(k.id).mImage_URL()
            })
            // this.postpicList = data.headPicDetails
            // if (this.postpicList.length != 0) {
            //   this.picText = this.postpicList[0].text
            // }

            this.reporterOne = data
            this.getPost()
          })
      } else {
        console.log('post')
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithoutType()
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL()
            data.headPostDetails.forEach((k) => {
              k.imageURL = RD.pic().id(k.frontPicId).mImage_URL()
            })
            this.postpicList = data.headPostDetails
            this.reporterOne = data
            this.getPost()
          })
      }
    },
    getPost() {
      const thiz = this
      var query = {}
      query.author = thiz.reporterOne.name
      query.storeIds = [1]
      query.order = 2
      RD.query()
        .query(query, thiz.pager.currentPage, 20)
        .then((data) => {
          data.list.forEach((k) => {
            k.imageURL = RD.pic().id(k.frontPicId).mImage_URL()
          })
          thiz.postpicList = data.list
          thiz.pager = data.pager
        })
    },
    onPageChange(newPage) {
      console.log('hjj', newPage)
      this.pager.currentPage = newPage
      this.getPost()
    },
    // 稿件内的小图
    postpic(index) {
      console.log('ss', index)
      console.log('oppp', this.reporterOne.headPostDetails[index].id)
      RD.post()
        .id(this.reporterOne.headPostDetails[index].id)
        .one()
        .then((info) => {
          console.log('ipp', info)
          info.pics.forEach((j) => {
            j.imgURL = RD.pic().id(j.id).mImage_URL()
          })
          this.postpicList = info.pics
        })
    },
    // 小图的点击
    onClickSwiper(index) {
      var thiz = this
      thiz.transformDistance = index * 110
      this.swiperIndex = index
      this.picText = this.postpicList[index].text
      // this.postpic(index)
    },
    // 图片左移
    onGoleft() {
      console.log('aa', this.swiperIndex)
      if (this.swiperIndex >= 1) {
        this.transformDistance = this.transformDistance - 110
        this.swiperIndex = this.swiperIndex - 1
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1
      } else {
        this.transformDistance = 0
        this.swiperIndex = 0
        this.$message('已经是第一张图片了')
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 图片右移
    onGoright() {
      var piclength = this.postpicList.length - 1
      if (this.swiperIndex == piclength) {
        this.$message('已经是最后一张了')
        this.swiperIndex = piclength
      } else {
        this.transformDistance = this.transformDistance + 110
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1
        this.swiperIndex = this.swiperIndex + 1
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 小图左移5张
    onGoPicLeft() {
      console.log('currIndex', this.swiperIndex)
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0
        this.$message('已经是第一张了')
      } else if (this.swiperIndex == 2) {
        this.calculate()
      } else if (this.swiperIndex == 1) {
        this.calculate()
      } else if (this.swiperIndex == 3) {
        this.calculate()
      } else if (this.swiperIndex == 4) {
        this.calculate()
      } else if (this.swiperIndex == 5) {
        this.transformDistance = this.transformDistance - 110 * 5
        this.swiperIndex = 0
        this.transformSwiperDistance = 0
      } else if (this.swiperIndex == 6) {
        this.swiperIndex = this.swiperIndex - 2
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex - 2)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex - 2)
      } else if (this.swiperIndex == 7) {
        this.swiperIndex = this.swiperIndex - 3
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex - 1)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex - 1)
      } else if (this.swiperIndex == 8) {
        this.swiperIndex = this.swiperIndex - 4
        this.transformDistance = this.transformDistance - 110 * this.swiperIndex
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * this.swiperIndex
      } else if (this.swiperIndex == 9) {
        this.swiperIndex = this.swiperIndex - 5
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex + 1)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex + 1)
      } else {
        this.swiperIndex = this.swiperIndex - 5
        this.transformDistance = this.transformDistance - 110 * 5
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * 5
      }
      this.picText = this.postpicList[this.swiperIndex].text
      console.log('now', this.swiperIndex)
    },
    // 小图右移5张
    onGoPicRight() {
      var piclength = this.postpicList.length - 1
      if (this.swiperIndex == piclength) {
        this.$message('已经是最后一张了')
        this.swiperIndex = piclength
      } else if (this.swiperIndex == piclength - 3) {
        this.calculateright()
      } else if (this.swiperIndex == piclength - 2) {
        this.calculateright()
      } else if (this.swiperIndex == piclength - 1) {
        this.calculateright()
      } else if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 5
        this.transformDistance = this.transformDistance + 110 * this.swiperIndex
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1 * this.swiperIndex
      } else {
        this.transformDistance = this.transformDistance + 110 * 5
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1 * 5
        this.swiperIndex = this.swiperIndex + 5
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 计算距离
    calculate() {
      this.swiperIndex = this.swiperIndex - 1
      this.transformDistance = this.transformDistance - 110
      this.transformSwiperDistance = this.transformSwiperDistance - 22.1
    },
    // 计算距离
    calculateright() {
      this.swiperIndex = this.swiperIndex + 1
      this.transformDistance = this.transformDistance + 110
      this.transformSwiperDistance = this.transformSwiperDistance + 22.1
    },
    // 查看稿件
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post_' + postId + '.html',
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.user-detail {
  width: 100%;
  height: auto;
  background-color: #f1f1f1;

  .detail-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    .detail-top {
      height: auto;
      position: relative;
      .kuan {
        position: absolute;
        bottom: -2rem;
        left: 0rem;
        width: 100%;
        border: 0.3rem solid #ff8c00;
        height: calc(100% - 5rem);
        .tip {
          height: calc(100% - 3rem);
          width: 98%;
          position: absolute;
          top: 14px;
          left: 1%;
          margin: 0 auto;
          border: 0.1rem solid #ccc;
        }
      }
      .detail-txet {
        position: relative;
        height: auto;
        width: 50%;
        left: 25%;
        top: 0rem;
        background: #f1f1f1;
        .detail-img {
          height: 12rem;
          width: 60rem;
          .m_img {
            margin: 0.5rem auto;
            width: 10rem;
            height: 10rem;
            border-radius: 5rem;
            background: no-repeat 50% / cover;
          }
        }
        .detail-comment {
          height: auto;
          width: 60rem;
          text-align: left;
          font-size: 1.4rem;
          color: #545454;
          text-indent: 2em;
          line-height: 3.6rem;
          padding: 0rem 1rem;
          .name {
            font-size: 1.6rem;
            font-weight: bold;
            margin: 0rem 0.5rem;
          }
        }
      }
    }
    .detail-post {
      height: auto;
      flex-wrap: wrap;
      width: 120rem;
      margin: 4rem auto;
      .post-title {
        height: 48px;
        width: 120rem;
        margin: 0 auto;
        margin-bottom: 20px;
        font-weight: lighter;
        position: relative;
        text-align: left;
        .name {
          font-size: 36px;
        }
        .en-name {
          position: absolute;
          left: 15rem;
          top: 15px;
          vertical-align: bottom;
          margin-left: 10px;
          font-size: 22px;
          color: #b5b5b5;
          font-family: 'Microsoft Yahei', 'Simsun';
        }
        .more {
          position: absolute;
          right: 0px;
          top: 14px;
          font-size: 18px;
          font-weight: lighter;
          color: #aeaeae;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .post-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .post-item {
          width: 29rem;
          height: auto;
          background: #fff;
          .item {
            .item-img {
              width: 100%;
              height: 196px;
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
            }
            .item-info {
              width: 265px;
              margin: 10px auto;
              .title {
                height: 3rem;
                line-height: 30px;
                margin-top: 22px;
                font-size: 18px;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .name {
                text-align: center;
                height: 20px;
                line-height: 20px;
                color: #aeaeae;
                font-size: 12px;
                font-family: 'Microsoft Yahei', 'Simsun';
              }
              .time {
                text-align: center;
                height: 20px;
                line-height: 20px;
                color: #aeaeae;
                font-size: 12px;
              }
            }
          }
        }
        .post-item:nth-child(7 + n) {
          display: none;
        }
      }
    }
    .page-container {
      text-align: right;
      margin-top: 20px;
    }
    .gohome {
      position: fixed;
      bottom: 27%;
      margin-left: -5rem;
      a {
        width: 3rem;
        display: inline-block;
        height: 5rem;
      }
    }
  }
  .detail-con.mobile-present {
    width: 750px;
    .detail-mobile-userImg {
      width: 50%;
      height: 50%;
      border-radius: 189px;
      margin: 0 auto;
      overflow: hidden;
      .mobile_img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / contain;
      }
    }
    .mobile-user-text {
      font-size: 35px;
      margin: 20px;
      letter-spacing: 0.1rem;
      line-height: 80px;
      font-family: Microsoft YaHei;
      text-align: left;
      .mobile-user-title {
        height: 60px;
        line-height: 60px;
      }
      span {
        font-weight: bold;
        margin-right: 20px;
      }
    }
    .mobile-user-pic {
      .pic-show {
        font-size: 35px;
        height: 70px;
        line-height: 70px;
      }
      .pic-list {
        width: 100%;
        margin-bottom: 20px;
        .pic-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
      }
    }
  }
}
</style>
