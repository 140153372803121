<template>
  <!-- <div class="all" v-loading.fullscreen.lock="loading" :element-loading-text="loadingTip" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"> -->
  <div class="all">
    <Nav></Nav>
    <div class="uppost" ref="upPage">
      <input ref="upfileSelector" type="file" multiple="multiple" accept=".jpg, .jpeg, .JPG, .JPEG" class="invisible" @change="fileChange($event, 'pic')" />
      <input ref="upPftfileSelector" type="file" multiple="multiple" accept=".pdf, .PDF" class="invisible" @change="fileChange($event, 'pdf')" />
      <div class="uppost-con">
        <div class="uppost-title">首页>上传图片</div>
        <div class="uppost-detail">
          <div class="tip">
            <div class="red">注意事项：</div>
            <div>请尽量使用IE或者火狐浏览器进行上传，保证兼容性。 IE需要版本10以上</div>
            <div>请点击"选择图片"进行图片选择。可以多选，只接受jpg，jpeg后缀名文件。</div>
            <div>选择图片后，图片会依次上传。</div>
            <div>图片(单张)大小不得超过50MB。</div>
            <div>上传过程中，您可以对文章进行编辑，修改，可以调整图片的顺序。</div>
            <div>带红星*都是必填，必选项,稿件类型必须选中一个，至少二级</div>
          </div>
          <div class="small-pic-preview">
            <img v-for="pic in picList" :src="previewImageURL(pic)" :key="'pic_small_' + pic.key" />
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic('pic')">添加图片</el-button>
            <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button>
            <el-button v-if="pdfShow" @click="onAddPic('pdf')">添加PDF文件</el-button>
          </div>
          <!-- 图片总上传进度条 -->
          <div class="allProgress-ontainer" v-if="allProgress != 0">
            <div class="all-progress">
              <div class="progress" :style="{ width: allProgress + '%' }" v-bind:class="{ green: allProgress == 100 }"></div>
            </div>
            <div class="small-progress">图片上传总进度条：{{ allProgress + '%' }}</div>
          </div>
          <div class="up-con">
            <div class="up-left">
              <div>
                <span class="title">新闻标题：<i>(*)</i></span>
                <!-- <span></span> -->
                <el-input v-model="post.title" type="text" ref="postTitle"></el-input>
              </div>
              <div>
                <span class="title">目标稿库：<i>(*)</i></span>
                <el-radio-group v-model="post.targetStore" class="contentradio">
                  <el-radio v-for="upStore in userInfo.upPicStores" :key="'store_' + upStore.id" class="radio" :label="upStore.id">{{ upStore.name }}</el-radio>
                </el-radio-group>
              </div>
              <div>
                <span class="title">发生时间：<i>(*)</i></span>
                <el-date-picker v-model="post.eventTime" type="date" value-format="yyyy-MM-dd" :default-value="new Date()" placeholder="选择日期"></el-date-picker>
              </div>
              <div>
                <span class="title">发生地点：<i>(*)</i></span>
                <el-cascader
                  class="content"
                  :options="$rtm.store.addrTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=" > "
                  v-model="addr_picker_arr"
                  popper-class="addr-select-popper"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型1：<i>(*)</i></span>
                <el-cascader
                  class="content"
                  popper-class="addr-select-popper"
                  :options="$rtm.store.typeTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=">"
                  v-model="type_picker_arr1"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型2：</span>
                <el-cascader
                  class="content"
                  popper-class="addr-select-popper"
                  :options="$rtm.store.typeTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=">"
                  v-model="type_picker_arr2"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型3：</span>
                <el-cascader
                  class="content"
                  popper-class="addr-select-popper"
                  :options="$rtm.store.typeTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=">"
                  v-model="type_picker_arr3"
                ></el-cascader>
              </div>
              <div>
                <span class="title">署名：<i>(*)</i></span>
                <el-input v-model="post.author" placeholder></el-input>
              </div>
              <div>
                参与投稿活动：
                <div>
                  <el-checkbox-group class="activity" v-model="ftIdCheck">
                    <el-checkbox v-for="k in atopics" :key="k.id" :label="k.id">
                      {{ k.shortTitle }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="up-right">
              <div>
                稿件正文：<i>(*)</i>
                <el-input v-model="post.mainText" type="textarea" :rows="7" ref="postMainText_d"></el-input>
              </div>
              <div>
                备注：
                <el-input v-model="post.note" type="textarea" :rows="4" ref="postNote"></el-input>
              </div>
              <div class="postTag">
                稿件标签：
                <div class="post-tag">
                  <el-tag :key="tag" v-for="tag in postTagsList" closable :disable-transitions="false" @click="changeInput(tag)" @close="handleClose(tag)">{{ tag }}</el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="editTagShow"
                    v-model="newEdit"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="onEditTags"
                    @blur="onEditTags"
                  ></el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="onAddTags">+</el-button>
                </div>
                <div class="born" @click="onBornPostTag(post.title, post.mainText, post.author)">生成</div>
              </div>
              <el-button type="primary" class="save" @click="onSavePost()">保存</el-button>
            </div>
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic('pic')">添加图片</el-button>
            <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button>
            <el-button v-if="pdfShow" @click="onAddPic('pdf')">添加PDF文件</el-button>
          </div>
          <div class="pic-list">
            <!-- <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }"> -->
            <div class="pic-item" v-for="(pic, picIndex) in picList" :key="previewImageURL(pic)" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }">
              <div class="othee">
                <div
                  :style="{
                    backgroundImage: 'url(' + previewImageURL(pic) + ')',
                  }"
                  class="pic-image"
                >
                  <div class="progress" :style="{ width: pic.progress + '%' }"></div>
                  <i class="el-icon-top up" @click="onUpImage(pic.key)"></i>
                  <i class="el-icon-close close" @click="onDeleteImage(picIndex)"></i>
                  <i class="el-icon-bottom down" @click="onDownImage(pic.key)"></i>
                </div>
                <div class="pic-info">
                  <div class="progress-container">
                    <div class="progress" :style="{ width: pic.progress + '%' }" v-bind:class="{ green: pic.done == true }"></div>
                  </div>
                  <div class="status-line">
                    <el-button :disabled="!pic.textMsg" @click="onCopyPicText(picIndex)">复制本说明到其他无说明的图片</el-button>
                    <!-- 文件名：{{ pic.fileName }}  -->
                    状态：{{ pic.done ? '已上传完毕' : '已上传' + pic.progress + '%' }} 拍摄时间：{{ pic.picTime }}
                    <span v-if="!pic.textMsg">(未填写图片分说明)</span>
                  </div>
                  <div class="status-line" v-if="pic.error">
                    <span><span class="red">上传出错：</span>{{ pic.errorMsg }}</span>
                  </div>
                  <el-input
                    type="textarea"
                    class="text"
                    :rows="4"
                    placeholder="图片说明格式：时间+地点+一句话概括图片内容"
                    v-model="pic.text"
                    resize="none"
                    @input="onChangePicText(picIndex)"
                  ></el-input>
                </div>
              </div>
              <!-- <div class="tag" v-if="picList.length != 0">
                图片标签：
                <div class="tag-list">
                  <el-tag :key="tag" v-for="tag in pic.tags" closable :disable-transitions="false" @click="changepictag(tag, picIndex)" @close="onDelPicTag(tag, picIndex)">{{
                    tag
                  }}</el-tag>
                  <el-input
                    class="input-new-tags"
                    v-if="pic.editPicTagShow"
                    v-model="newPicEdit"
                    :ref="'savepicTagInput_' + picIndex"
                    size="small"
                    @keyup.enter.native="onEditPicTags(picIndex)"
                    @blur="onEditPicTags(picIndex)"
                  ></el-input>
                  <el-button v-else class="button-new-tags" size="small" @click="onAddPicTags(picIndex)">+</el-button>
                </div>
                <div class="tag-del" @click="onGoback(picIndex)">一键恢复</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import axios from 'axios'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      post: {
        title: '',
        author: '',
        checked: false,
        mainText: '',
        note: '',
        type: [],
        tags: [],
        pics: [],
        eventTime: new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(1, '0') + '-' + ('' + new Date().getDate()).padStart(1, '0'),
        targetStore: 0,
      },
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      type_picker_arr1: [],
      type_picker_arr2: [],
      type_picker_arr3: [],
      addr_picker_arr: [],
      picList: [],
      atopics: [],
      ftIdCheck: [],
      userInfo: {},
      postTagsList: [], // 稿件的tags
      editTagShow: false, // 编辑稿件标签
      editTag: '', // 新编辑后的标签
      newEdit: '', // 稿件标签的值
      newPicEdit: '', // 图片标签的值
      picUploading: false, //图片正在上传中
      picUploadingWaitingList: [], //图片上传等待列表
      errorPicIndex: -1, // 上传错误的图片index
      allProgress: 0, // 所有图片的总进度
      pdfShow: false, // PDF按钮的展示与隐藏
      loading: false, // 加载
      loadingTip: '加载中',
    }
  },
  created() {
    const thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        if (data.noStoreRights.indexOf('PDF_UPLOAD') != -1) {
          thiz.pdfShow = true
        }
        thiz.post.author = data.defaultAuthorName
        if (data.approveStatus != 'APPROVED') {
          thiz.$alert('未审批账户不能上传稿件', '导航错误', {
            confirmButtonText: '确定',
            callback: () => {
              thiz.$router.replace('/myInfo.html')
            },
          })
          return
        }
        if (data.upPicStores.length != 0) {
          var newarr = []
          data.upPicStores.forEach((k) => {
            newarr.push(k.id)
          })
          if (newarr.indexOf(1024) != -1) {
            this.post.targetStore = 1024
          } else {
            this.post.targetStore = data.upPicStores[0].id
          }
        } else {
          thiz.$message('当前账户没有上传照片的库')
        }

        thiz.userInfo = data
      })
      .catch(() => {
        this.$alert('您当前处于未登录状态, 请先登录?', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/index.html')
          })
          .catch(() => {})
      })

    console.log('123tougao', this.$route.query)
    // if (this.$route.query.ftId) {
    //   RD.activityTopic(this.$route.query.ftId)
    //     .one()
    //     .then((data)=>{
    //       thiz.post.note = '可投稿至' + data.title
    //       var ft ={}
    //       ft.id = data.id
    //       ft.brief = data.brief
    //       ft.shortTitle = data.shortTitle
    //       ft.sponser = data.sponser
    //       ft.startTime = data.startTime
    //       ft.stopTime = data.stopTime
    //       ft.title = data.title
    //       thiz.atopics.push(ft)
    //     })
    //     console.log('mxx',thiz.atopics);
    // }
    RD.activityTopic()
      .forUpload()
      .then((topics) => {
        // ftIdCheck
        var ftId = parseInt(this.$route.query.ftId)
        topics.forEach((topic) => {
          if (topic.id == ftId) {
            thiz.ftIdCheck.push(ftId)
          }
        })
        console.log('opp', thiz.ftIdCheck)
        thiz.atopics = topics
      })
    this.reload()
  },
  mounted() {
    const thiz = this
    this.$refs.upPage.ondragover = function (e) {
      if (e.dataTransfer.items.length == 0) {
        e.stopPropagation()
        e.preventDefault()
      } else {
        var f = e.dataTransfer.items[e.dataTransfer.items.length - 1]
        if (f.kind != 'string') {
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
    this.$refs.upPage.ondrop = function (e) {
      if (e.dataTransfer.items.length == 0) {
        e.stopPropagation()
        e.preventDefault()
      } else {
        var f = e.dataTransfer.items[e.dataTransfer.items.length - 1]
        if (f.kind != 'string') {
          e.stopPropagation()
          e.preventDefault()
        }
      }
      var list = e.dataTransfer.files
      for (var i = 0; i < list.length; i++) {
        var f2 = list[i]
        thiz.dealWithFile(f2)
        thiz.dealPdfWithFile(f2)
      }
    }

    this.$refs.postTitle.$refs.input.ondrop = function (e) {
      e.stopPropagation()
    }
    // this.$refs.postTitle_m.$refs.input.ondrop = function (e) {
    //   e.stopPropagation()
    // }
    this.$refs.postMainText_d.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    // this.$refs.postMainText_m.$refs.textarea.ondrop = function (e) {
    //   e.stopPropagation()
    // }
    this.$refs.postNote.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    // this.$refs.postNote_m.$refs.textarea.ondrop = function (e) {
    //   e.stopPropagation()
    // }
  },
  methods: {
    onSavePost() {
      if (this.picList.length == 0) {
        this.$rtm.message.error('没有上传图片！')
        return
      }
      console.log('jk', this.addr_picker_arr)
      for (var pic of this.picList) {
        if (pic.error) {
          this.$rtm.message.error('有图片的出错状态没有处理，不能保存！')
          return
        }
      }
      var allPicDone = true
      for (var pic of this.picList) {
        if (!pic.done) {
          allPicDone = false
        }
      }
      if (!allPicDone) {
        this.$rtm.message.error('有图片不合法或者尚未上传完毕，不能保存！')
        return
      }
      if (this.post.title.length == 0) {
        this.$rtm.message.error('标题不能为空!')
        return
      }
      if (this.post.targetStore == 0) {
        this.$rtm.message.error('请选择输出稿库')
        return
      }
      if (this.addr_picker_arr.length == 0) {
        this.$rtm.message.error('请选则发生地点')
        return
      }
      if (this.addr_picker_arr.length == 1) {
        if (this.addr_picker_arr[0] == 13) {
        } else {
          this.$rtm.message.error('地点必须选择到二级或以上')
          return
        }
      }
      if (this.type_picker_arr1.length == 0 && this.type_picker_arr2.length == 0 && this.type_picker_arr3.length == 0) {
        this.$rtm.message.error('请选择至少一个类型')
        return
      }
      if (this.type_picker_arr1.length <= 1 && this.type_picker_arr2.length <= 1 && this.type_picker_arr3.length <= 1) {
        this.$rtm.message.error('至少选择一个二级或以上类型')
        return
      }
      if (this.post.author == undefined) {
        this.$rtm.message.error('请输入署名')
        return
      }
      if (this.post.eventTime.length == 0) {
        this.$rtm.message.error('请选择发生时间')
        return
      }
      if (this.post.mainText.length == 0) {
        this.$rtm.message.error('请输入正文')
        return
      }
      var post = {}
      post.storeId = this.post.targetStore
      post.title = this.post.title
      post.author = this.post.author
      post.mainText = this.post.mainText
      post.note = this.post.note
      post.eventTime = this.post.eventTime
      post.eventAddrId = this.addr_picker_arr[this.addr_picker_arr.length - 1]
      post.typeIds = []
      if (this.type_picker_arr1.length != 0) {
        post.typeIds.push(this.type_picker_arr1[this.type_picker_arr1.length - 1])
      }
      if (this.type_picker_arr2.length != 0) {
        post.typeIds.push(this.type_picker_arr2[this.type_picker_arr2.length - 1])
      }
      if (this.type_picker_arr3.length != 0) {
        post.typeIds.push(this.type_picker_arr3[this.type_picker_arr3.length - 1])
      }
      // if (post.typeIds.length == 1) {
      //   this.$rtm.message.error('稿件类型至少要选择两个')
      //   return
      // }

      post.pics = []
      // console.log('savepic', this.picList)
      for (var picInList of this.picList) {
        // post.pics.push({
        //   uuid: picInList.uuid,
        //   tags: picInList.tags,
        //   text: picInList.text,
        // })
        var textl = picInList.text.trim()
        if (textl.length == 0) {
          picInList.textMsg = false
          this.$message({
            type: 'warning',
            message: '请填写图片小说明',
          })
          return
        }
        post.pics.push({
          uuid: picInList.uuid,
          text: picInList.text,
        })
      }

      var target = []
      for (var i = 0; i < post.typeIds.length; i++) {
        var targetNumber = post.typeIds[i]
        if (target.indexOf(targetNumber) != -1) {
          break
        } else {
          target.push(targetNumber)
        }
      }
      post.typeIds = target
      post.tags = this.postTagsList
      post.acts = this.ftIdCheck
      // this.atopics.forEach((atopic) => {
      //   if (atopic.selected) {
      //     post.acts.push(atopic.id)
      //   }
      // })
      console.log('mxpost', post)
      // this.loadingTip = '稿件提交中，请耐心等待'
      // this.loading = true
      RD.myPost()
        .upPost(post)
        .then((postId) => {
          this.$router.push('/myPost.html')
          // setTimeout(() => {
          //   this.loadingTip = '稿件已经提交，等待提交后的预处理完成'
          //   this.afterPostUploadCheck(postId)
          // }, 1000)
        })
        .catch((error) => {
          // this.loading = false
          thiz.$alert('上传失败：' + error.msg)
          console.log('catch', error, post)
        })
    },
    // 预处理的过程
    afterPostUploadCheck(postId) {
      //this.$router.push("/myPost.html");
      RD.pure()
        .myPost()
        .checkPostAfterWork(postId)
        .then(() => {
          this.loading = false
          this.$alert('上传完毕，请到我的稿件中查看上传的稿件', {
            showClose: false,
          }).then(() => {
            this.$router.push('/myPost.html')
          })
        })
        .catch((error) => {
          this.loadingTip = '稿件已经提交，提交后的预处理进行中，' + error.msg
          setTimeout(() => {
            this.afterPostUploadCheck(postId)
          }, 300)
        })
    },
    findPicIndexByKey(key) {
      for (var i = 0; i < this.picList.length; i++) {
        if (this.picList[i].key == key) {
          return i
        }
      }
      // for (var i = 0; i < this.picList.length; i++) {
      //   console.log('js', i, this.picList[i]);
      //   if (this.picList[i].key == key) {
      //     return i
      //   }
      // }
      return -1
    },
    onUpImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == 0) {
        this.$message({
          type: 'warning',
          message: '已经到顶!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex - 1]
      var nodeToUp = this.picList[picIndex]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex - 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    onDeleteImage(index) {
      const thiz = this
      var picIndex = index
      console.log('del', picIndex)
      this.$confirm('将要删除选择的图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        for (var i = 0; i < thiz.picList.length; i++) {
          if (i > picIndex) {
            thiz.picList[i].moveUp = true
          }
        }
        thiz.$nextTick(() => {
          setTimeout(() => {
            thiz.picList.forEach((pic) => {
              pic.moveUp = false
            })
            this.picList.splice(picIndex, 1)
            thiz.$message({
              type: 'success',
              message: '删除成功!',
            })
          }, 300)
        })
      })
    },
    onDownImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == this.picList.length - 1) {
        this.$message({
          type: 'warning',
          message: '已经到底!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex]
      var nodeToUp = this.picList[picIndex + 1]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex + 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    dealWithFile(file) {
      const thiz = this
      console.log(file)
      console.log(file)
      var name = file.name
      var size = file.size
      var url
      if (window.navigator.userAgent.indexOf('Chrome') >= 1 || window.navigator.userAgent.indexOf('Safari') >= 1) {
        url = window.webkitURL.createObjectURL(file)
      } else {
        url = window.URL.createObjectURL(file)
      }
      console.log('文件名：' + name + ' 文件类型：' + size + ' url: ' + url)
      if (!name.toLocaleLowerCase().endsWith('.jpg') && !name.toLocaleLowerCase().endsWith('.jpeg')) {
        return
      }
      var pic = {}
      pic.key = parseInt(Math.random() * 10000000)
      pic.fileName = name
      pic.size = size
      pic.url = url
      pic.done = false
      pic.progress = 0
      pic.moveUp = false
      pic.moveDown = false
      pic.editPicTagShow = false
      pic.text = ''
      pic.textMsg = false
      pic.error = false
      pic.errorMsg = ''
      // pic.tags = []
      this.picList.push(pic)
      ;((file, key) => {
        thiz.appendPicUploadWork(file, key)
      })(file, pic.key)
    },
    appendPicUploadWork(file, key) {
      this.picUploadingWaitingList.unshift({
        file: file,
        key: key,
      })
      if (!this.picUploading) {
        this.uploadWithProgress()
      }
    },
    uploadWithProgress() {
      this.picUploading = true
      var workItem = this.picUploadingWaitingList.pop()
      if (!workItem) {
        this.picUploading = false
        return
      }
      var file = workItem.file
      var key = workItem.key
      const thiz = this
      let param = new FormData()
      param.append('file', file)
      axios({
        url: RD.myPost().upPicWithExif_URL(),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param,
        withCredentials: true,
        onUploadProgress: function (progressEvent) {
          for (var pic of thiz.picList) {
            if (pic.key == key) {
              pic.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
              thiz.calculateAllProgress()
            }
          }
        },
      })
        .then((data) => {
          console.log('upPicResult', data)
          console.log('piclist', thiz.picList)
          thiz.picUploading = false
          this.uploadWithProgress()
          if (data.data.code == 0) {
            console.log('jjjs')
            // if (data.data.data.exif.DateTimeOriginal != undefined || data.data.data.exif.DateTime != undefined) {
            console.log('can up')
            for (var pic of thiz.picList) {
              if (pic.key == key) {
                pic.done = true
                pic.uuid = data.data.data.uuid
                if (data.data.data.exif.DateTimeOriginal) {
                  pic.picTime = data.data.data.exif.DateTimeOriginal
                } else {
                  pic.picTime = data.data.data.exif.DateTime
                }
                // pic.tags = data.data.data.tags
              }
              pic.editPicTagShow = false
              // pic.prePictag = JSON.parse(JSON.stringify(pic.tags))
            }
            // } else {
            //   console.log('no datatime')
            //   var timeMsg = '图片时间不规范'
            //   this.delErrorPic(key, timeMsg)
            // }
          } else {
            ;((toCheckKey, msg) => {
              console.log(toCheckKey, msg)
              this.delErrorPic(toCheckKey, msg)
            })(key, data.data.msg)
          }
        })
        .catch(() => {
          thiz.picUploading = false
          this.uploadWithProgress()
          console.log('upPicFail')
          thiz.$message('上传失败，请重新上传图片')
        })
    },
    // pdf的axios处理
    uploadWithPdfProgress() {
      this.picUploading = true
      var workItem = this.picUploadingWaitingList.pop()
      if (!workItem) {
        this.picUploading = false
        return
      }
      var file = workItem.file
      var key = workItem.key
      const thiz = this
      let param = new FormData()
      param.append('file', file)
      this.loading = true
      thiz.loadingTip = '上传中'
      axios({
        url: RD.myPost().upPDF_URL(),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param,
        withCredentials: true,
        onUploadProgress: function (progressEvent) {
          console.log('pdfsuccess', progressEvent)
          // this.picInList = [];
          thiz.loadingTip = '上传中，进度' + parseInt((progressEvent.loaded * 100) / progressEvent.total) + '%'
          console.log('totalprogress', thiz.loadingTip)
        },
      })
        .then((data) => {
          console.log('upPdfResult', data)
          thiz.picUploading = false
          this.uploadWithPdfProgress()
          if (data.data.code == 0) {
            setTimeout(() => {
              this.checkPDF(data.data.data)
            }, 1000)

            // for (var pic of thiz.picList) {
            //   if (pic.key == key) {
            //     pic.done = true;
            //     pic.uuid = data.data.data.uuid;
            //     pic.tags = data.data.data.tags;
            //   }
            //   pic.editPicTagShow = false;
            //   // pic.prePictag = JSON.parse(JSON.stringify(pic.tags))
            // }
          } else {
            ;((toCheckKey, msg) => {
              console.log(toCheckKey, msg)
              this.delErrorPic(toCheckKey, msg)
            })(key, data.data.msg)
          }
        })
        .catch((errMsg) => {
          thiz.picUploading = false
          this.uploadWithPdfProgress(type)
          console.log('upPicFail-msg', errMsg)
          this.delErrorPic(key, errMsg)
        })
    },
    // pdf的核实
    checkPDF(uuid) {
      // setInterval
      RD.pure()
        .myPost()
        .checkPDFAnalyzeStatus(uuid)
        .then((info) => {
          console.log('jk', this.picList)
          this.loading = false
          info.images.forEach((k) => {
            var pic = {}
            pic.type = 'pdf'
            pic.uuid = k.uuid
            pic.done = true
            pic.progress = 100
            pic.key = parseInt(Math.random() * 10000000)
            this.picList.push(pic)
          })
          console.log('finall', this.picList)
        })
        .catch((error) => {
          console.log('error', error)
          this.loadingTip = error.msg
          if (error.code == 13) {
            setTimeout(() => {
              this.checkPDF(uuid)
              this.loading = true
            }, 1000)
          } else if (error.code == 14) {
            this.$alert(error.msg, '提示', {
              type: 'warning',
            })
          }
        })
    },
    // 图片处理
    previewImageURL(pic) {
      if (pic.id) {
        return RD.pic().id(pic.id).sImage_URL()
      } else if (pic.url) {
        return pic.url
      } else {
        return RD.myPost().sImage_URL(pic.uuid)
      }
    },
    calculateAllProgress() {
      const thiz = this
      var allProgressA = 0
      for (var pic of thiz.picList) {
        allProgressA += pic.progress
      }
      thiz.allProgress = parseInt((allProgressA / thiz.picList.length) * 100) / 100
    },
    delErrorPic(key, msg) {
      var thiz = this
      // console.log('hsjs', key, msg)
      // this.$confirm(msg + '，即将要删除，请重新上传图片', '提示', {
      //   confirmButtonText: '确定',
      //   showCancelButton: false,
      //   type: 'warning',
      // }).then(() => {
      //   var picIndex = this.findPicIndexByKey(key)
      //   console.log('skks', picIndex)
      //   console.log('piclist after del', thiz.picList)
      //   for (var i = 0; i < thiz.picList.length; i++) {
      //     if (i > picIndex) {
      //       thiz.picList[i].moveUp = true
      //     }
      //   }
      //   thiz.$nextTick(() => {
      //     setTimeout(() => {
      //       thiz.picList.forEach((pic) => {
      //         pic.moveUp = false
      //       })
      //       this.picList.splice(picIndex, 1)
      //       thiz.$message({
      //         type: 'success',
      //         message: '删除成功!',
      //       })
      //     }, 300)
      //   })
      // })
      var picIndex = this.findPicIndexByKey(key)
      this.picList[picIndex].error = true
      this.picList[picIndex].errorMsg = msg
    },
    fileChange(event, type) {
      console.log(event.target.files)
      for (var file of event.target.files) {
        if (type == 'pic') {
          this.dealWithFile(file)
        } else {
          this.dealPdfWithFile(file)
        }
      }
    },
    onAddPic(type) {
      // this.$refs.upfileSelector.click()
      if (type == 'pic') {
        this.$refs.upfileSelector.click()
      } else if (type == 'pdf') {
        this.$refs.upPftfileSelector.click()
      }
    },
    // pdf的处理
    dealPdfWithFile(file) {
      const thiz = this
      var name = file.name
      var size = file.size
      console.log('文件名：' + name + ' 文件类型：' + size)
      if (!name.toLocaleLowerCase().endsWith('.pdf') && !name.toLocaleLowerCase().endsWith('.PDF')) {
        return
      }
      var pic = {}
      pic.key = parseInt(Math.random() * 10000000)
      pic.fileName = name
      pic.size = size
      pic.done = false
      // thiz.picList.push(pic);
      ;((file, key) => {
        thiz.appendPdfUploadWork(file, key)
      })(file, pic.key)
    },
    appendPdfUploadWork(file, key) {
      console.log('mxx', file, key)
      this.picUploadingWaitingList.unshift({
        file: file,
        key: key,
      })
      if (!this.picUploading) {
        this.uploadWithPdfProgress()
      }
    },
    onUsePicFileAsPicText() {
      console.log('onUsePicFileAsPicText')
      this.picList.forEach((pic) => {
        console.log('onUsePicFileAsPicText', pic.fileName)
        pic.text = pic.fileName.toLowerCase().replace('.jpg', '').replace('.jpeg', '')
        var picText = pic.text
        var picLength = picText.trim()
        if (picLength.length != 0) {
          pic.textMsg = true
        } else {
          pic.textMsg = false
        }
      })
    },
    reload() {
      const thiz = this
      document.title = '吴江图片库---上传页面'
      if (this.$rtm.store.typeTree.length == 0) {
        RD.type()
          .tree()
          .then((data) => {
            this.$rtm.store.typeTree = data
          })
      }
      if (this.$rtm.store.addrTree.length == 0) {
        RD.addr()
          .tree()
          .then((data) => {
            this.$rtm.store.addrTree = data
          })
      }
      thiz.type_picker_arr = thiz.treeList
    },
    // 自动生成稿件标签
    onBornPostTag(title, maintext, author) {
      if (this.post.title == '') {
        this.$message('新闻标题不能为空')
        return
      }
      if (this.post.mainText == '') {
        this.$message('稿件正文不能为空')
        return
      }
      if (this.post.author == '') {
        this.$message('署名不能为空')
        return
      }
      RD.myPost()
        .analyzeTags(title, maintext, author)
        .then((data) => {
          this.postTagsList = data
        })
    },
    // 编辑标签
    onEditTags() {
      let taginputValue = this.newEdit
      if (taginputValue !== '') {
        if (this.postTagsList.indexOf(taginputValue) === -1) {
          this.postTagsList.push(taginputValue)
        }
      }
      this.editTagShow = false
      this.newEdit = ''
    },
    // 点击编辑标签
    changeInput(tags) {
      console.log('focus', tags)
      this.postTagsList.splice(this.postTagsList.indexOf(tags), 1)
      this.editTagShow = true
      this.$nextTick(function () {
        this.$refs.saveTagInput.focus()
      })
      this.newEdit = tags
    },
    // 删除稿件标签
    handleClose(tag) {
      this.postTagsList.splice(this.postTagsList.indexOf(tag), 1)
    },
    // 新增稿件标签
    onAddTags() {
      console.log('addtag')
      this.editTagShow = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 点击图片的标签
    changepictag(tags, picarrIndex) {
      this.picList[picarrIndex].tags.splice(this.picList[picarrIndex].tags.indexOf(tags), 1)
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(function () {
        this.$nextTick(function () {
          console.log('shs', this.$refs['savepicTagInput_' + picarrIndex][0])
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
      this.newPicEdit = tags
    },
    // 删除图片的标签
    onDelPicTag(tag, picIndex) {
      this.$nextTick(function () {
        this.picList[picIndex].tags.splice(this.picList[picIndex].tags.indexOf(tag), 1)
      })
    },
    // 编辑图片标签
    onEditPicTags(picIndex) {
      let tagPicValue = this.newPicEdit
      if (tagPicValue !== '') {
        if (this.picList[picIndex].tags.indexOf(tagPicValue) === -1) {
          this.picList[picIndex].tags.push(tagPicValue)
        }
      }
      this.picList[picIndex].editPicTagShow = false
      this.newPicEdit = ''
    },
    // 添加图片标签
    onAddPicTags(picarrIndex) {
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          console.log('hjj', this.picList)

          console.log('ussu', this.$refs['savepicTagInput_' + picarrIndex][0])
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
    },
    // 一键恢复
    onGoback(picIndex) {
      this.picList[picIndex].tags = JSON.parse(JSON.stringify(this.picList[picIndex].prePictag))
    },
    //  图片说明是否为空的操作
    onChangePicText(picIndex) {
      var thiz = this
      // console.log('picChange', thiz.picList[picIndex])
      var picText = thiz.picList[picIndex].text
      var picLength = picText.trim()
      if (picLength.length != 0) {
        thiz.picList[picIndex].textMsg = true
      } else {
        thiz.picList[picIndex].textMsg = false
      }
    },
    // 复制无图片说明操作
    onCopyPicText(picIndex) {
      var thiz = this
      const picText = thiz.picList[picIndex].text
      thiz.picList.forEach((pic) => {
        const picL = pic.text.trim()
        if (picL.length == 0) {
          pic.text = picText
          pic.textMsg = true
        }
      })
    },
  },
}
</script>
<style lang="less">
.addr-select-popper .el-radio__inner {
  border: 1px solid #cf0000 !important;
}
</style>
<style scoped lang="less">
.moveUp {
  transition: all 300ms ease-in-out;
  top: -160px !important;
}
.moveDown {
  transition: all 300ms ease-in-out;
  top: 160px !important;
}
.invisible {
  width: 0px;
  height: 0px;
  opacity: 0;
}
@media all and (max-width: 800px) {
  .uppost {
    background-color: #f1f1f1;
    margin: 20px 0px;
    width: 750px;
    .uppost-con {
      width: 750px;
      margin: 0 auto;
      background-color: #ccc;
      .uppost-title {
        height: 40px;
        width: 100%;
        text-align: left;
        line-height: 40px;
        font-size: 16px;
        margin-left: 5px;
      }
      .uppost-detail {
        width: 100%;
        .tip {
          padding: 15px 20px;
          border: 1px solid #f3e8c8;
          background-color: #fffeef;
          text-align: left;
          font-size: 14px;
          line-height: 35px;
          .red {
            color: red;
            font-size: 14px;
          }
        }
        .allProgress-ontainer {
          height: 5rem;
          margin-left: 1rem;
          .small-progress {
            height: 3rem;
            line-height: 3rem;
            font-size: 1.5rem;
            color: red;
          }
          .all-progress {
            width: 100%;
            height: 1rem;
            position: relative;
            background-color: rgba(255, 0, 0, 0.4);
            border-radius: 0.5rem;
            overflow: hidden;
            .progress {
              display: block;
              height: 1rem;
              position: absolute;
              left: 0rem;
              top: 0rem;
              background-color: green;
            }
            .progress.green {
              background-color: green;
            }
          }
        }
        .small-pic-preview {
          width: 100%;
          overflow: hidden;
          text-align: left;
          background-color: #ccc;
          img {
            height: 100px;
            display: inline-block;
            margin: 10px;
          }
        }
        .pic-list {
          width: 100%;
          overflow: hidden;
          background-color: #ccc;
          .pic-item {
            position: relative;
            height: auto;
            width: 96%;
            margin: 10px 2%;
            top: 0px;
            .othee {
              display: flex;
              .pic-image {
                width: 280px;
                height: 170px;
                background: no-repeat 50% / cover;
                position: relative;
                overflow: hidden;
                margin: 0px 20px;
                .progress {
                  display: block;
                  height: 5px;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  background-color: rgba(255, 0, 0, 0.4);
                }
                i {
                  display: block;
                  line-height: 40px;
                  font-size: 25px;
                  background-color: rgba(48, 38, 38, 0.4);
                  color: rgb(226, 223, 223);
                  border-radius: 20px;
                  overflow: hidden;
                  height: 40px;
                  width: 40px;
                  right: -50px;
                }
                .up {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 5px;
                }
                .close {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 55px;
                }
                .down {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 105px;
                }
              }
              .pic-image:hover {
                i {
                  right: 50px;
                }
              }
              .pic-info {
                flex: 1;
                height: 170px;
                display: flex;
                flex-direction: column;
                .status-line {
                  flex: 1;
                  // min-height: 60px;
                  font-size: 14px;
                  text-align: left;
                  span {
                    color: red;
                    margin-left: 1rem;
                  }
                  .el-button {
                    padding: 10px 5px;
                  }
                }
                .text {
                  vertical-align: bottom;
                }
                .progress-container {
                  height: 6px;
                  width: 100%;
                  position: relative;
                  background-color: rgba(255, 0, 0, 0.4);
                  border-radius: 0.5rem;
                  overflow: hidden;
                  margin-bottom: 2px;
                  .progress {
                    display: block;
                    height: 1rem;
                    position: absolute;
                    left: 0rem;
                    top: 0rem;
                    background-color: green;
                  }
                  .progress.green {
                    background-color: green;
                  }
                }
              }
            }
            .tag {
              height: auto;
              text-align: left;
              font-size: 1.3rem;
              margin-left: 20px;
              margin-top: 20px;
              display: flex;
              width: 800px;

              .tag-list {
                border: 1px solid #f6f6f6;
                height: auto;
                box-sizing: border-box;
                border-radius: 5px;
                width: 600px;
                .el-tag {
                  margin: 8px 2px;
                }
                .button-new-tag {
                  margin-left: 10px;
                  margin-top: 8px;
                  height: 32px;
                  line-height: 30px;
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .input-new-tags {
                  width: 130px;
                  margin-left: 10px;
                  vertical-align: bottom;
                }
              }
              .tag-del {
                width: 80px;
                height: 45px;
                line-height: 45px;
                margin-left: 20px;
                border-radius: 5px;
                background-color: #716e6e;
                color: #fff;
                text-align: center;
                cursor: pointer;
              }
            }
          }
        }
        .up-con {
          border: 1px solid #f3e8c8;
          background-color: #fffeef;
          padding: 15px 30px;
          line-height: 50px;
          display: flex;
          font-size: 1.3rem;
          text-align: left;

          .up-left {
            width: 50%;
            .title {
              display: inline-block;
              width: 100px;
              i {
                font-style: normal;
              }
            }
            .contentradio {
              margin-top: 16px;
              width: 70%;
              .radio {
                display: inline-bloc;
                height: 30px;
              }
            }
            .content {
              margin: 5px 0px;
              width: 70%;
              .radio {
                display: inline-bloc;
                height: 30px;
              }
            }
            div {
              .el-input {
                width: 70%;
              }
            }
            .beizhu {
              margin-left: 80px;
              line-height: 30px;
            }
          }
          .up-right {
            width: 50%;
            .save {
              margin-top: 30px;
              margin-left: 20%;
              width: 60%;
              font-weight: bold;
            }
            .postTag {
              height: auto;
              margin-top: 20px;
              display: flex;
              .post-tag {
                width: 390px;
                height: auto;
                box-sizing: border-box;
                border-radius: 5px;
                background-color: #fff;
                border: 1px solid #ccc;
                .el-tag {
                  margin: 8px 2px;
                }
                .button-new-tags {
                  margin-left: 10px;
                  margin-top: 8px;
                  height: 32px;
                  line-height: 30px;
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .input-new-tag {
                  width: 130px;
                  margin-left: 10px;
                  vertical-align: bottom;
                }
              }
              .born {
                width: 70px;
                height: 45px;
                margin-left: 10px;
                text-align: center;
                border: 1px solid #ccc;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
        }
        .add-pic {
          height: 50px;
          background: #ccc;
          margin: 10px;
          text-align: left;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .uppost {
    background-color: #f1f1f1;
    // margin: 20px 0px;
    .uppost-con {
      width: 120rem;
      margin: 0 auto;
      background-color: #ccc;
      .uppost-title {
        height: 6rem;
        width: 100%;
        text-align: left;
        line-height: 6rem;
        font-size: 1.2rem;
        margin-left: 5px;
      }
      .uppost-detail {
        width: 100%;
        .tip {
          padding: 1.5rem 3rem;
          border: 1px solid #f3e8c8;
          background-color: #fffeef;
          text-align: left;
          font-size: 1.3rem;
          line-height: 35px;
          .red {
            color: red;
            font-size: 1.4rem;
          }
        }
        .allProgress-ontainer {
          height: 5rem;
          margin-left: 1rem;
          .small-progress {
            height: 3rem;
            line-height: 3rem;
            font-size: 1.5rem;
            color: red;
          }
          .all-progress {
            width: 100%;
            height: 1rem;
            position: relative;
            background-color: rgba(255, 0, 0, 0.4);
            border-radius: 0.5rem;
            overflow: hidden;
            .progress {
              display: block;
              height: 1rem;
              position: absolute;
              left: 0rem;
              top: 0rem;
              background-color: green;
            }
            .progress.green {
              background-color: green;
            }
          }
        }
        .small-pic-preview {
          width: 100%;
          overflow: hidden;
          text-align: left;
          background-color: #ccc;
          img {
            height: 10rem;
            display: inline-block;
            margin: 1rem;
          }
        }
        .pic-list {
          width: 100%;
          overflow: hidden;
          background-color: #ccc;
          .pic-item {
            position: relative;
            // min-height: 15.6rem;
            height: auto;
            width: 98%;
            margin: 10px 1%;
            top: 0px;
            .othee {
              display: flex;
              .pic-image {
                width: 280px;
                min-height: 16rem;
                background: no-repeat 50% / contain;
                background-color: #000;
                position: relative;
                overflow: hidden;
                margin: 0px 20px;
                .progress {
                  display: block;
                  height: 5px;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  background-color: rgba(255, 0, 0, 0.4);
                }
                i {
                  display: block;
                  line-height: 40px;
                  font-size: 25px;
                  background-color: rgba(48, 38, 38, 0.4);
                  color: rgb(226, 223, 223);
                  border-radius: 20px;
                  overflow: hidden;
                  height: 40px;
                  width: 40px;
                  right: -50px;
                }
                .up {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 5px;
                }
                .close {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 55px;
                }
                .down {
                  position: absolute;
                  transition: all 200ms ease-in-out;
                  top: 105px;
                }
              }
              .pic-image:hover {
                i {
                  right: 50px;
                }
              }
              .pic-info {
                flex: 1;
                min-height: 150px;
                display: flex;
                flex-direction: column;
                .status-line {
                  flex: 1;
                  // min-height: 4rem;
                  font-size: 1.4rem;
                  text-align: left;
                  span {
                    color: red;
                    margin-left: 1rem;
                  }
                  .el-button {
                    padding: 10px 5px;
                  }
                }
                .text {
                  // flex: 1;
                  vertical-align: bottom;
                }
                .progress-container {
                  height: 1rem;
                  width: 100%;
                  position: relative;
                  background-color: rgba(255, 0, 0, 0.4);
                  border-radius: 0.5rem;
                  overflow: hidden;
                  margin-bottom: 0.3rem;
                  .progress {
                    display: block;
                    height: 1rem;
                    position: absolute;
                    left: 0rem;
                    top: 0rem;
                    background-color: green;
                  }
                  .progress.green {
                    background-color: green;
                  }
                }
              }
            }
            .tag {
              height: auto;
              text-align: left;
              font-size: 1.3rem;
              margin-left: 20px;
              margin-top: 20px;
              display: flex;
              width: 800px;

              .tag-list {
                border: 1px solid #f6f6f6;
                height: auto;
                box-sizing: border-box;
                border-radius: 5px;
                width: 600px;
                .el-tag {
                  margin: 8px 2px;
                }
                .button-new-tag {
                  margin-left: 10px;
                  margin-top: 8px;
                  height: 32px;
                  line-height: 30px;
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .input-new-tags {
                  width: 130px;
                  margin-left: 10px;
                  vertical-align: bottom;
                }
              }
              .tag-del {
                width: 80px;
                height: 45px;
                line-height: 45px;
                margin-left: 20px;
                border-radius: 5px;
                background-color: #716e6e;
                color: #fff;
                text-align: center;
                cursor: pointer;
              }
            }
          }
        }
        .up-con {
          border: 1px solid #f3e8c8;
          background-color: #fffeef;
          padding: 15px 30px;
          line-height: 50px;
          display: flex;
          font-size: 1.3rem;
          text-align: left;

          .up-left {
            width: 50%;
            .title {
              display: inline-block;
              width: 100px;
              i {
                font-style: normal;
                color: #e42121;
              }
            }
            .contentradio {
              margin-top: 16px;
              width: 70%;
              .radio {
                display: inline-bloc;
                height: 30px;
              }
            }
            .content {
              margin: 5px 0px;
              width: 70%;
              .radio {
                display: inline-bloc;
                height: 30px;
              }
            }
            div {
              .el-input {
                width: 70%;
              }
            }
            .beizhu {
              margin-left: 80px;
              line-height: 30px;
            }
          }
          .up-right {
            width: 50%;
            i {
              font-style: normal;
              color: #e42121;
            }
            .save {
              margin-top: 30px;
              margin-left: 20%;
              width: 60%;
              font-weight: bold;
            }
            .postTag {
              height: auto;
              margin-top: 20px;
              display: flex;
              .post-tag {
                width: 390px;
                height: auto;
                box-sizing: border-box;
                border-radius: 5px;
                background-color: #fff;
                border: 1px solid #ccc;
                .el-tag {
                  margin: 8px 2px;
                }
                .button-new-tags {
                  margin-left: 10px;
                  margin-top: 8px;
                  height: 32px;
                  line-height: 30px;
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .input-new-tag {
                  width: 130px;
                  margin-left: 10px;
                  vertical-align: bottom;
                }
              }
              .born {
                width: 70px;
                height: 45px;
                margin-left: 10px;
                text-align: center;
                border: 1px solid #ccc;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
        }
        .add-pic {
          height: 50px;
          background: #ccc;
          margin: 10px;
          text-align: left;
        }
      }
    }
  }
}
</style>
