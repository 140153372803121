<template>
  <div class="bindMobile">
    <Nav></Nav>
    <div class="bind-all desktop-present">
      <div class="bind-txet">您的位置：<span @click="onGomyinfo">首页</span>>>个人信息>>绑定手机</div>
      <div class="bind-con">
        <div class="tip">欢迎您绑定手机号码，如果您已经绑定过手机，绑定新手机的同时将会解绑之前绑定的手机号</div>
        <div class="bind-mobile">
          <span>手机：</span>
          <el-input v-model="mobile" placeholder="请输入手机号" @blur="onSendMobile" clearable></el-input>
        </div>
        <div class="yanzhengCode">
          <span>图形验证码：</span>
          <el-input v-model="imgCode" placeholder="请输入图形验证码" clearable></el-input>
          <img :src="codeImg" alt="" class="yan-img" @click="onRetrieveCode" />
        </div>
        <div class="mobileCode">
          <span>短信验证码：</span>
          <el-input v-model="code" placeholder="请输入短信验证码" clearable></el-input>
          <el-button @click="onSendCode">发送验证码</el-button>
        </div>
        <div class="btn">
          <el-button @click="onBind" type="primary">绑定手机号</el-button>
        </div>
      </div>
    </div>
    <div class="bind-all mobile-present">
      <div class="bind-txet">您的位置：<span @click="onGomyinfo">首页</span>>>个人信息>>绑定手机</div>
      <div class="bind-con">
        <div class="tip">欢迎您绑定手机号码，如果您已经绑定过手机，绑定新手机的同时将会解绑之前绑定的手机号</div>
        <div class="bind-mobile">
          <span>手机：</span>
          <el-input v-model="mobile" placeholder="请输入手机号" @blur="onSendMobile" clearable></el-input>
        </div>
        <div class="yanzhengCode">
          <span>图形验证码：</span>
          <el-input v-model="imgCode" placeholder="请输入图形验证码" clearable></el-input>
          <img :src="codeImg" alt="" class="yan-img" v-if="!$rtm.store.logined" @click="onRetrieveCode" />
        </div>
        <div class="mobileCode">
          <span>短信验证码：</span>
          <el-input v-model="code" placeholder="请输入短信验证码" clearable></el-input>
          <el-button @click="onSendCode">发送验证码</el-button>
        </div>
        <div class="btn">
          <el-button @click="onBind" type="primary">绑定手机号</el-button>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      mobile: '', //手机号
      imgCode: '', // 图像验证
      code: '', // 短信验证
      codeImg: '',
      returnCode: '',
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        thiz.reload()
      })
      .catch((error) => {
        console.log('err', error)
        if (error.code == '-1') {
          thiz.$message('需要登录')
          thiz.$router.push('/index.html')
        }
      })
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    onRetrieveCode() {
      this.reload()
    },
    onSendMobile() {
      var thiz = this
      if (!isNaN(parseInt(thiz.mobile)) === false) {
        thiz.$message('手机号不合法, 请重新填写')
        thiz.mobile = ''
        return
      } else if (thiz.mobile.length != 11) {
        thiz.$message('手机号不合法, 请重新填写')
        thiz.input = ''
        return
      } else if (thiz.mobile.slice(0, 1) != '1') {
        thiz.$message('手机号不合法, 请重新填写')
        thiz.mobile = ''
        return
      }
    },
    // 发送验证码
    onSendCode() {
      var thiz = this
      if (thiz.imgCode == '') {
        thiz.$message('图形验证码不能为空')
        return
      }
      if (thiz.mobile) {
        RD.user()
          .confirmSMS(thiz.imgCode, thiz.mobile)
          .then(() => {
            thiz.$message('手机发送验证码成功，请注意查收')
          })
      } else {
        thiz.$message('请先输入手机号')
      }
    },
    // 绑定
    onBind() {
      var thiz = this
      if (thiz.imgCode == '') {
        thiz.$message('图形验证码不能为空')
        return
      }
      if (thiz.code == '') {
        thiz.$message('短信验证码不能为空')
        return
      }
      RD.user()
        .bindMobile(thiz.code)
        .then(() => {
          thiz.$message('绑定成功')
          this.$router.push('/myInfo.html')
        })
    },
    onGomyinfo() {
      this.$router.push('/myInfo.html')
    },
  },
}
</script>

<style lang="less" scoped>
.bindMobile {
  background-color: #f1f1f1;
  .bind-all.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 50.4rem);
    .bind-txet {
      height: 4rem;
      background-color: #ccc;
      font-size: 1.6rem;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 2rem;
      span {
        color: red;
        cursor: pointer;
      }
    }
    .bind-con {
      height: 60.2rem;
      background-color: #dfdfdf;
      .tip {
        height: 4.5rem;
        line-height: 4.5rem;
        font-size: 1.6rem;
        padding-left: 2rem;
        font-weight: bold;
        text-align: left;
      }
      .code,
      .bind-mobile,
      .mobileCode,
      .yanzhengCode {
        padding-left: 5rem;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.3rem;
        text-align: left;
        display: flex;
        span {
          display: inline-block;
          width: 10rem;
        }
        .el-input {
          width: 30rem;
        }
        .el-button {
          padding: 5px 12px;
          height: 4rem;
          margin-top: 5px;
        }
        img {
          margin-left: 5px;
          height: 38px;
          margin-top: 7px;
        }
      }
    }
  }
  .bind-all.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 588px;
    .bind-txet {
      height: 40px;
      background-color: #ccc;
      font-size: 1.3rem;
      line-height: 40px;
      text-align: left;
      padding-left: 20px;
      span {
        color: red;
        cursor: pointer;
      }
    }
    .bind-con {
      height: 562px;
      background-color: #dfdfdf;
      .tip {
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        padding-left: 20px;
        font-weight: bold;
        text-align: left;
      }
      .code,
      .bind-mobile,
      .mobileCode,
      .yanzhengCode {
        padding-left: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        text-align: left;
        span {
          display: inline-block;
          width: 120px;
        }
        .el-input {
          width: 30rem;
        }
        .el-button {
          padding: 5px 12px;
          height: 4rem;
          margin-top: 5px;
        }
        img {
          margin-left: 5px;
          height: 38px;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
