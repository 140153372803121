<template>
  <div class="whole">
    <Nav></Nav>
    <div class="uppost" ref="upPage">
      <input ref="upfileSelector" type="file" multiple="multiple" accept=".jpg, .jpeg, .JPG, .JPEG" class="invisible" @change="fileChange($event)" />
      <div class="uppost-con">
        <div class="uppost-title">首页>上传稿件</div>
        <div class="uppost-detail">
          <div class="tip">
            <div class="red">注意事项：</div>
            <div>用户自己编辑稿件过程中，编辑可以随时打开用户的稿件。</div>
            <div>如果编辑开始审阅您的稿件，您的稿件的更改将不能被保存。</div>
            <div class="red">您也可以直接拖动文件到此页面进行文件上传！（此功能在某些版本浏览器上可能无法使用）</div>
          </div>
          <div class="small-pic-preview">
            <img v-for="pic in picList" :src="pic.url" :key="'pic_small_' + pic.key" />
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button>
          </div>
          <div class="up-con">
            <div class="up-left">
              <div>
                <span class="title">新闻标题：</span>
                <el-input v-model="post.title" placeholder></el-input>
              </div>
              <div>
                <span class="title">目标稿库：</span>
                <el-radio-group v-model="post.targetStore" class="content">
                  <el-radio v-for="upStore in userInfo.upPicStores" :key="'store_' + upStore.id" class="radio" :label="upStore.id">{{ upStore.name }}</el-radio>
                </el-radio-group>
              </div>
              <div>
                <span class="title">发生时间：</span>
                <el-date-picker v-model="post.eventTime" type="date" value-format="yyyy-MM-dd" :default-value="new Date()" placeholder="选择日期"></el-date-picker>
              </div>
              <div>
                <span class="title">发生地点：</span>
                <el-cascader
                  class="content"
                  :options="$rtm.store.addrTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=" > "
                  v-model="addr_picker_arr"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型1：</span>
                <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr1"></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型2：</span>
                <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr2"></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型3：</span>
                <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr3"></el-cascader>
              </div>
              <div>
                <span class="title">署名：</span>
                <el-input v-model="post.author" placeholder></el-input>
              </div>
            </div>
            <div class="up-right">
              <div>
                稿件正文：
                <el-input v-model="post.mainText" type="textarea" :rows="7"></el-input>
              </div>
              <div>
                备注：
                <el-input v-model="post.note" type="textarea" :rows="4"></el-input>
              </div>
              <!-- <div class="postTag">
                稿件标签：
                <div class="post-tag">
                  <el-tag :key="tag" v-for="tag in post.tags" closable :disable-transitions="false" @click="changeInput(tag)" @close="handleClose(tag)">{{ tag }}</el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="editTagShow"
                    v-model="newEdit"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="onEditTags"
                    @blur="onEditTags"
                  ></el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="onAddTags">+</el-button>
                </div>
                <div class="born" @click="onBornPostTag(post.title, post.mainText, post.author)">生成</div>
              </div> -->
              <el-button class="save" @click="onSavePost()">保存</el-button>
            </div>
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button>
          </div>
          <div class="pic-list">
            <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }">
              <div class="othee">
                <div :style="{ backgroundImage: 'url(' + pic.url + ')' }" class="pic-image">
                  <i class="el-icon-top up" @click="onUpImage(pic.key)"></i>
                  <i v-if="!pic.exist" class="el-icon-close close" @click="onDeleteImage(pic.key)"></i>
                  <i class="el-icon-bottom down" @click="onDownImage(pic.key)"></i>
                </div>
                <div class="pic-info">
                  <div class="progress-container">
                    <div class="progress" :style="{ width: pic.progress + '%' }" v-bind:class="{ green: pic.done == true }"></div>
                  </div>
                  <div class="status-line">
                    <el-button :disabled="!pic.textMsg" @click="onCopyPicText(picIndex)">复制本说明到其他无说明的图片</el-button>
                    <!-- 文件名：{{ pic.fileName }}  -->
                    状态：{{ pic.done ? '已上传完毕' : '已上传' + pic.progress + '%' }} 拍摄时间：{{ pic.picTime }}
                    <span v-if="!pic.textMsg">(未填写图片分说明)</span>
                  </div>
                  <div class="status-line" v-if="pic.error">
                    <span><span class="red">上传出错：</span>{{ pic.errorMsg }}</span>
                  </div>
                  <el-input
                    type="textarea"
                    class="text"
                    :rows="4"
                    placeholder="图片说明格式：时间+地点+一句话概括图片内容"
                    v-model="pic.text"
                    resize="none"
                    @input="onChangePicText(picIndex)"
                  ></el-input>
                </div>
              </div>

              <!-- <div class="tag">
                图片标签：
                <div class="tag-list">
                  <el-tag
                    :key="tag"
                    v-for="tag in pic.tags"
                    closable
                    :disable-transitions="false"
                    @click="changepictag(tag, picIndex)"
                    @close="onDelPicTag(tag, picIndex)"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tags"
                    v-if="pic.editPicTagShow"
                    v-model="newPicEdit"
                    :ref="'savepicTagInput_' + picIndex"
                    size="small"
                    @keyup.enter.native="onEditPicTags(picIndex)"
                    @blur="onEditPicTags(picIndex)"
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tags"
                    size="small"
                    @click="onAddPicTags(picIndex)"
                    >+</el-button
                  >
                </div>
                <div class="tag-del" @click="onGoback(picIndex)">一键恢复</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import axios from 'axios'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      post: {
        id: 0,
        title: '',
        author: '',
        checked: false,
        mainText: '',
        note: '',
        type: [],
        tags: [],
        pics: [],
        eventTime: new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(2, '0') + '-' + ('' + (new Date().getDate() + 1)).padStart(2, '0'),
        targetStore: 0,
      },
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      type_picker_arr1: [],
      type_picker_arr2: [],
      type_picker_arr3: [],
      addr_picker_arr: [],
      picList: [],
      atopics: [],
      userInfo: {},
      postTagsList: [], // 稿件的tags
      editTagShow: false,
      newEdit: '',
      newPicEdit: '', // 图片标签的输入值
    }
  },
  created() {
    const thiz = this
    console.log('query', this.$route.query.id)
    RD.post()
      .id(this.$route.query.id)
      .one()
      .then((postFromWeb) => {
        console.log('kll', postFromWeb)
        thiz.post.id = postFromWeb.id
        thiz.post.title = postFromWeb.title
        thiz.post.targetStore = postFromWeb.storeId
        thiz.post.eventTime = postFromWeb.eventTime
        thiz.post.author = postFromWeb.author
        thiz.post.mainText = postFromWeb.mainText
        thiz.post.note = postFromWeb.note
        thiz.addr_picker_arr = postFromWeb.eventAddrId
        thiz.post.tags = postFromWeb.tags
        thiz.post.pics = postFromWeb.pics
        if (postFromWeb.eventTypeIdPaths[0]) {
          thiz.type_picker_arr1 = postFromWeb.eventTypeIdPaths[0]
        }
        if (postFromWeb.eventTypeIdPaths[1]) {
          thiz.type_picker_arr2 = postFromWeb.eventTypeIdPaths[1]
        }
        if (postFromWeb.eventTypeIdPaths[2]) {
          thiz.type_picker_arr3 = postFromWeb.eventTypeIdPaths[2]
        }
        postFromWeb.pics.forEach((picExist) => {
          picExist.editPicTagShow = false
          picExist.prePicTags = JSON.parse(JSON.stringify(picExist.tags))

          var pic = {}
          pic.id = picExist.id
          pic.key = parseInt(Math.random() * 100000)
          pic.fileName = ''
          pic.url = RD.pic().id(picExist.id).sImage_URL()
          pic.done = true
          pic.exist = true
          pic.moveUp = false
          pic.moveDown = false
          pic.editPicTagShow = false
          pic.text = picExist.text
          if (pic.text != '' || pic.text != undefined) {
            pic.textMsg = true
          }
          pic.tags = picExist.tags
          pic.prePicTags = JSON.parse(JSON.stringify(picExist.tags))
          this.picList.push(pic)
        })
      })
    this.reload()
    RD.user()
      .sync()
      .then((data) => {
        // thiz.post.author = data.defaultAuthorName
        thiz.userInfo = data
        if (data.upPicStores.length != 0) {
          this.post.targetStore = data.upPicStores[0].id
        }
      })
    RD.activityTopic()
      .forUpload()
      .then((topics) => {
        thiz.atopics = topics
      })
  },
  mounted() {
    const thiz = this
    document.ondragover = function (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    document.ondrop = function (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.$refs.upPage.ondragover = function (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.$refs.upPage.ondrop = function (e) {
      e.stopPropagation()
      e.preventDefault()
      console.log(e.dataTransfer)
      var list = e.dataTransfer.files
      for (var i = 0; i < list.length; i++) {
        var f = list[i]
        thiz.dealWithFile(f)
      }
    }
  },
  methods: {
    onSavePost() {
      if (this.picList.length == 0) {
        this.$message({
          type: 'warning',
          message: '没有上传图片！',
        })
        return
      }
      var allPicDone = true
      for (var pic of this.picList) {
        if (!pic.done) {
          allPicDone = false
        }
      }
      if (!allPicDone) {
        this.$message({
          type: 'warning',
          message: '有图片不合法或者尚未上传完毕，不能保存！',
        })
        return
      }
      if (this.post.title.length == 0) {
        this.$message({
          type: 'warning',
          message: '标题不能为空',
        })
        return
      }
      if (this.post.targetStore == 0) {
        this.$message({
          type: 'warning',
          message: '请选择输出稿库',
        })
        return
      }
      if (this.addr_picker_arr.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选则发生地点',
        })
        return
      }
      if (this.addr_picker_arr.length == 1) {
        this.$message({
          type: 'warning',
          message: '请选择二级地点',
        })
        return
      }
      if (this.type_picker_arr1.length == 0 && this.type_picker_arr2.length == 0 && this.type_picker_arr3.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择至少一个类型',
        })
        return
      }
      if (this.post.author.length == 0) {
        this.$message({
          type: 'warning',
          message: '请输入署名',
        })
        return
      }
      if (this.post.eventTime.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择发生时间',
        })
        return
      }
      if (this.post.mainText.length == 0) {
        this.$message({
          type: 'warning',
          message: '请输入正文',
        })
        return
      }
      var addrType = typeof this.addr_picker_arr
      var post = {}
      post.id = this.post.id
      // post.storeId = this.post.targetStore
      post.title = this.post.title
      post.author = this.post.author
      post.mainText = this.post.mainText
      post.note = this.post.note
      // post.reason = ''
      post.eventTime = this.post.eventTime
      if (addrType == 'number') {
        post.eventAddrId = this.addr_picker_arr
      } else {
        post.eventAddrId = this.addr_picker_arr[this.addr_picker_arr.length - 1]
      }
      post.types = []
      post.tags = this.post.tags
      if (this.type_picker_arr1.length != 0) {
        post.types.push(this.type_picker_arr1[this.type_picker_arr1.length - 1])
      }
      if (this.type_picker_arr2.length != 0) {
        post.types.push(this.type_picker_arr2[this.type_picker_arr2.length - 1])
      }
      if (this.type_picker_arr3.length != 0) {
        post.types.push(this.type_picker_arr3[this.type_picker_arr3.length - 1])
      }
      post.pics = []
      for (var picInList of this.picList) {
        var textl = picInList.text.trim()
        if (textl.length == 0) {
          picInList.textMsg = false
          this.$alert('请填写图片小说明', '警告', {
            confirmButtonText: '确定',
            callback: (action) => {},
          })
          return
        }
        if (picInList.uuid) {
          post.pics.push({
            uuid: picInList.uuid,
            text: picInList.text,
            tags: [],
          })
        } else {
          post.pics.push({
            id: picInList.id,
            text: picInList.text,
            tags: [],
          })
        }
      }
      console.log('mxpost', post)
      RD.myPost()
        .savePost(post)
        .then(() => {
          this.$router.push('/myPost.html')
        })
    },
    // 自动生成稿件标签
    onBornPostTag(title, maintext, author) {
      if (this.post.title == '') {
        this.$message('新闻标题不能为空')
        return
      }
      if (this.post.mainText == '') {
        this.$message('稿件正文不能为空')
        return
      }
      if (this.post.author == '') {
        this.$message('署名不能为空')
        return
      }
      RD.myPost()
        .analyzeTags(title, maintext, author)
        .then((data) => {
          this.post.tags = data
        })
    },
    findPicIndexByKey(key) {
      for (var i = 0; i < this.picList.length; i++) {
        if (this.picList[i].key == key) {
          return i
        }
      }
      return -1
    },
    onUpImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == 0) {
        this.$message({
          type: 'warning',
          message: '已经到顶!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex - 1]
      var nodeToUp = this.picList[picIndex]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex - 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    onDeleteImage(key) {
      const thiz = this
      var picIndex = this.findPicIndexByKey(key)
      this.$confirm('将要删除选择的图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        for (var i = 0; i < thiz.picList.length; i++) {
          if (i > picIndex) {
            thiz.picList[i].moveUp = true
          }
        }
        thiz.$nextTick(() => {
          setTimeout(() => {
            thiz.picList.forEach((pic) => {
              pic.moveUp = false
            })
            this.picList.splice(picIndex, 1)
            thiz.$message({
              type: 'success',
              message: '删除成功!',
            })
          }, 300)
        })
      })
    },
    onDownImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == this.picList.length - 1) {
        this.$message({
          type: 'warning',
          message: '已经到底!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex]
      var nodeToUp = this.picList[picIndex + 1]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex + 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    dealWithFile(file) {
      const thiz = this
      console.log(file)
      console.log(file)
      var name = file.name
      var size = file.size
      var url
      if (window.navigator.userAgent.indexOf('Chrome') >= 1 || window.navigator.userAgent.indexOf('Safari') >= 1) {
        url = window.webkitURL.createObjectURL(file)
      } else {
        url = window.URL.createObjectURL(file)
      }
      console.log('文件名：' + name + ' 文件类型：' + size + ' url: ' + url)
      if (!name.toLocaleLowerCase().endsWith('.jpg') && !name.toLocaleLowerCase().endsWith('.jpeg')) {
        return
      }
      var pic = {}
      pic.key = parseInt(Math.random() * 100000)
      pic.fileName = name
      pic.size = size
      pic.url = url
      pic.done = false
      pic.progress = 0
      pic.moveUp = false
      pic.textMsg = false
      pic.moveDown = false
      pic.text = ''
      this.picList.push(pic)
      ;((file, key) => {
        thiz.uploadWithProgress(file, key)
      })(file, pic.key)
    },
    uploadWithProgress(file, key) {
      const thiz = this
      let param = new FormData()
      param.append('file', file)
      axios({
        url: RD.myPost().upPicWithTags_URL(),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param,
        withCredentials: true,
        onUploadProgress: function (progressEvent) {
          for (var pic of thiz.picList) {
            if (pic.key == key) {
              pic.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
            }
          }
        },
      })
        .then((data) => {
          console.log('upPicResult', data)
          for (var pic of thiz.picList) {
            if (pic.key == key) {
              pic.done = true
              pic.uuid = data.data.data.uuid
              pic.tags = []
            }
          }
        })
        .catch(() => {
          console.log('upPicFail')
        })
    },
    fileChange(event) {
      console.log(event.target.files)
      for (var file of event.target.files) {
        this.dealWithFile(file)
      }
    },
    onAddPic() {
      this.$refs.upfileSelector.click()
    },
    onUsePicFileAsPicText() {
      var thiz = this
      this.picList.forEach((pic) => {
        if (pic.fileName == '' || pic.fileName == undefined) {
          pic.text = pic.text
          console.log('aleray')

          return
        } else {
          console.log('new')
          pic.text = pic.fileName.toLowerCase().replace('.jpg', '').replace('.jpeg', '')
          var picText = pic.text
          var picLength = picText.trim()
          if (picLength.length != 0) {
            pic.textMsg = true
          } else {
            pic.textMsg = false
          }
          return
        }
      })
    },
    reload() {
      const thiz = this
      if (this.$rtm.store.typeTree.length == 0) {
        RD.type()
          .tree()
          .then((data) => {
            this.$rtm.store.typeTree = data
          })
      }
      if (this.$rtm.store.addrTree.length == 0) {
        RD.addr()
          .tree()
          .then((data) => {
            this.$rtm.store.addrTree = data
          })
      }
      thiz.type_picker_arr = thiz.treeList
    },
    onAddTags() {
      console.log('add')
      this.editTagShow = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    onEditTags() {
      console.log('edit')
      let taginputValue = this.newEdit
      if (taginputValue !== '') {
        if (this.post.tags.indexOf(taginputValue) === -1) {
          this.post.tags.push(taginputValue)
        }
      }
      this.editTagShow = false
      this.newEdit = ''
    },
    handleClose(tag) {
      console.log('del')
      this.post.tags.splice(this.postTagsList.indexOf(tag), 1)
    },
    changeInput(tags) {
      console.log('click')
      this.post.tags.splice(this.post.tags.indexOf(tags), 1)
      this.editTagShow = true
      this.$nextTick(function () {
        this.$refs.saveTagInput.focus()
      })
      this.newEdit = tags
    },
    // 点击图片标签
    changepictag(tags, picarrIndex) {
      this.picList[picarrIndex].tags.splice(this.picList[picarrIndex].tags.indexOf(tags), 1)
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(function () {
        this.$nextTick(function () {
          console.log('shs', this.$refs['savepicTagInput_' + picarrIndex][0])
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
      this.newPicEdit = tags
    },
    onDelPicTag(tag, picIndex) {
      this.$nextTick(function () {
        this.picList[picIndex].tags.splice(this.picList[picIndex].tags.indexOf(tag), 1)
      })
    },
    onEditPicTags(picIndex) {
      let tagPicValue = this.newPicEdit
      if (tagPicValue !== '') {
        if (this.picList[picIndex].tags.indexOf(tagPicValue) === -1) {
          this.picList[picIndex].tags.push(tagPicValue)
        }
      }
      this.picList[picIndex].editPicTagShow = false
      this.newPicEdit = ''
    },
    onAddPicTags(picarrIndex) {
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
    },
    onGoback(picIndex) {
      this.$nextTick(() => {
        this.picList[picIndex].tags = JSON.parse(JSON.stringify(this.post.pics[picIndex].prePicTags))
      })
    },
    //  图片说明是否为空的操作
    onChangePicText(picIndex) {
      var thiz = this
      // console.log('picChange', thiz.picList[picIndex])
      var picText = thiz.picList[picIndex].text
      var picLength = picText.trim()
      if (picLength.length != 0) {
        thiz.picList[picIndex].textMsg = true
      } else {
        thiz.picList[picIndex].textMsg = false
      }
    },
    // 复制无图片说明操作
    onCopyPicText(picIndex) {
      var thiz = this
      const picText = thiz.picList[picIndex].text
      thiz.picList.forEach((pic) => {
        const picL = pic.text.trim()
        if (picL.length == 0) {
          pic.text = picText
          pic.textMsg = true
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.moveUp {
  transition: all 300ms ease-in-out;
  top: -160px !important;
}
.moveDown {
  transition: all 300ms ease-in-out;
  top: 160px !important;
}
.invisible {
  width: 0px;
  height: 0px;
  opacity: 0;
}
.uppost {
  background-color: #f1f1f1;
  margin: 20px 0px;
  .uppost-con {
    width: 1200px;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 60px;
      width: 100%;
      text-align: left;
      line-height: 60px;
      font-size: 1.2rem;
      margin-left: 5px;
    }
    .uppost-detail {
      width: 100%;
      .tip {
        padding: 15px 30px;
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        text-align: left;
        font-size: 1.3rem;
        line-height: 40px;
        .red {
          color: red;
          font-size: 1.4rem;
        }
      }
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 100px;
          display: inline-block;
          margin: 10px;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: auto;
          width: 96%;
          margin: 10px 2%;
          top: 0px;
          .othee {
            display: flex;
            .pic-image {
              width: 320px;
              height: 150px;
              background: no-repeat 50% / contain;
              position: relative;
              overflow: hidden;
              i {
                display: block;
                line-height: 40px;
                font-size: 25px;
                background-color: rgba(48, 38, 38, 0.4);
                color: rgb(226, 223, 223);
                border-radius: 20px;
                overflow: hidden;
                height: 40px;
                width: 40px;
                right: -50px;
              }
              .up {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 5px;
              }
              .close {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 55px;
              }
              .down {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 105px;
              }
            }
            .pic-image:hover {
              i {
                right: 50px;
              }
            }
            .pic-info {
              flex: 1;
              min-height: 150px;
              display: flex;
              flex-direction: column;
              .status-line {
                flex: 1;
                // min-height: 4rem;
                font-size: 1.4rem;
                text-align: left;
                span {
                  color: red;
                  margin-left: 1rem;
                }
                .el-button {
                  padding: 10px 5px;
                }
              }
              .text {
                // flex: 1;
                vertical-align: bottom;
              }
              .progress-container {
                height: 1rem;
                width: 100%;
                position: relative;
                background-color: rgba(255, 0, 0, 0.4);
                border-radius: 0.5rem;
                overflow: hidden;
                margin-bottom: 0.3rem;
                .progress {
                  width: 100%;
                  display: block;
                  height: 1rem;
                  position: absolute;
                  left: 0rem;
                  top: 0rem;
                  background-color: green;
                }
                .progress.green {
                  background-color: green;
                }
              }
            }
          }
          .tag {
            height: auto;
            text-align: left;
            font-size: 1.3rem;
            margin-left: 20px;
            margin-top: 20px;
            display: flex;
            width: 800px;

            .tag-list {
              border: 1px solid #f6f6f6;
              height: auto;
              box-sizing: border-box;
              border-radius: 5px;
              width: 600px;
              .el-tag {
                margin: 8px 2px;
              }
              .button-new-tag {
                margin-left: 10px;
                margin-top: 8px;
                height: 32px;
                line-height: 30px;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tags {
                width: 130px;
                margin-left: 10px;
                vertical-align: bottom;
              }
            }
            .tag-del {
              width: 80px;
              height: 45px;
              line-height: 45px;
              margin-left: 20px;
              border-radius: 5px;
              background-color: #716e6e;
              color: #fff;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
      .up-con {
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        padding: 15px 30px;
        line-height: 50px;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .title {
            display: inline-block;
            width: 100px;
          }
          .content {
            margin: 5px 0px;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 30px;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
          .beizhu {
            margin-left: 80px;
            line-height: 30px;
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 30px;
            margin-left: 40%;
          }
          .postTag {
            height: auto;
            margin-top: 20px;
            display: flex;
            .post-tag {
              width: 390px;
              height: auto;
              box-sizing: border-box;
              border-radius: 5px;
              background-color: #fff;
              border: 1px solid #ccc;
              .el-tag {
                margin: 8px 2px;
              }
              .button-new-tags {
                margin-left: 10px;
                margin-top: 8px;
                height: 32px;
                line-height: 30px;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tag {
                width: 130px;
                margin-left: 10px;
                vertical-align: bottom;
              }
            }
            .born {
              width: 70px;
              height: 45px;
              margin-left: 10px;
              text-align: center;
              border: 1px solid #ccc;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
      .add-pic {
        height: 50px;
        background: #ccc;
        margin: 10px;
        text-align: left;
      }
    }
  }
}
</style>
