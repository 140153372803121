<template>
  <div class="userShow">
    <div class="user-con mobile-present">
      <!-- 特约摄影 -->
      <div class="user-list">
        <div class="user-title">
          <div class="user-name">签约摄影师</div>
        </div>
        <div class="user-detail">
          <div class="user-reporter" v-for="k in userList_master" :key="'nav_' + k.id">
            <div class="reporter-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onUserDetail(k.id, 1)"></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 1)">-- {{ k.name }} --</div>
            </div>
          </div>
        </div>
        <!-- <div class="more" @click="onMorereporter(1)">更多...</div> -->
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Bottom },
  data: function () {
    return {
      userList_master: [], // 摄影列表
      userList_reporter: [], // 摄影列表
      userList_registed: [], // 摄影列表
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      RD.userShow()
        .tops()
        .then((k) => {
          k.forEach((i) => {
            i.users.forEach((h) => {
              h.imgURL = RD.userShow().id(h.id).headPic_URL()
            })
          })
          for (var subList of k) {
            if (subList.code == 1) {
              this.userList_master = subList.users
            }
            if (subList.code == 2) {
              this.userList_reporter = subList.users
            }
            if (subList.code == 3) {
              this.userList_registed = subList.users
            }
          }
        })
    },
    // 更多
    onMorereporter(code) {
      this.$router.push({
        path: '/userShowList.html',
        query: { code: code },
      })
    },
    onUserDetail(id, code) {
      this.$router.push({
        path: '/userShowDetail.html',
        query: { id: id, code: code },
      })
    },
  },
}
</script>

<style scoped lang="less">
.userShow {
  height: auto;
  width: 100%;
  background-color: #fff;
  .user-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    .user-list {
      width: 750px;
      margin: 0 auto;
      .user-title,
      .user-sec,
      .user-thr {
        text-align: left;
        height: 220px;
        line-height: 70px;
        display: flex;
        width: 750px;
        background: no-repeat url(../assets/fixtopic.png);
        background-size: cover;
        .user-name {
          width: 200px;
          margin: 0 auto;
          font-size: 38px;
          font-weight: bold;
          line-height: 220px;
        }
      }
      .user-detail {
        display: flex;
        flex-direction: row;
        width: 730px;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        margin-top: 20px;
        .user-reporter {
          width: 345px;
          height: 240px;
          overflow: hidden;
          margin-bottom: 2rem;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
          background-color: #f5f5f5;
          .reporter-img {
            width: 180px;
            height: 180px;
            margin: 10px auto 0;
            background: no-repeat 50% / cover;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
          }
          .reporter-con {
            height: 37px;
            line-height: 37px;
            text-align: center;
            .name {
              text-align: center;
              height: 20px;
              font-size: 24px;
              font-weight: bold;
              margin-top: 10px;
              cursor: pointer;
            }
          }
        }
      }
      .more {
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: right;
        font-size: 1.3rem;
        margin-right: 3rem;
        margin-top: 2rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
