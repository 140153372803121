<template>
  <div class="whole" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="title">{{ catalogTitle }}</div>
      </div>
      <div class="catalog-con">
        <!-- <waterfall :line-gap="360" :watch="newsList">
            <waterfall-slot
              class="fixtop-list-item"
              style="margin-left: 10px; margin-bottom: 10px"
              v-for="(item, index) in newsList"
              :key="item.id + 'fixtopic_' + index"
              :width="200"
              :height="item.frameHeight"
              :margin-left="10"
            >
              <div class="item-inner" @click="onLookPost(item.postId)">
                <div class="item-img" :height1="item.postImageHeight" :style="{ backgroundImage: `url(` + item.imgURL + `)` }"></div>
                <div class="item-title">{{ item.title }}</div>
                <div class="item-author">
                  <div class="author-img"></div>
                  <div class="author-name">{{ item.author }}</div>
                </div>
              </div>
            </waterfall-slot>
          </waterfall> -->
        <div class="catalog-list" v-for="p in newsList" :key="p.id">
          <div class="catalog-title" @click="onGoFixtopic(p.id)">
            <div class="title-icon"></div>
            <div class="title-left">{{ p.title }}</div>
            <div class="title-right el-icon-right"></div>
          </div>
          <div class="catalog-content">
            <div class="catalog-detail" v-for="k in p.posts" :key="'nav_' + k.postId">
              <div class="img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onGoFixtopic(p.id)"></div>
              <!-- <div class="title" @click="onLookPost(k.postId)">
                {{ k.title }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Bottom },
  data: function () {
    return {
      newsList: [], // 新闻图片的所有
      catalogTitle: '',
      loading: false,
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('param', thiz.$route.query)
      thiz.catalogTitle = thiz.$route.query.title
      this.getCatalogName()
    },
    getCatalogName() {
      var thiz = this
      thiz.loading = true

      RD.pure()
        .index()
        .catalogByName(thiz.$route.query.title)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts.forEach((k) => {
              k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()

              if (k.postImageHeight >= k.postImageWidth) {
                k.frameHeight = ((k.postImageHeight * 120) / k.postImageWidth) * 1.5 + 120
                k.postImageHeight = ((k.postImageHeight * 100) / k.postImageWidth) * 1.2
                // k.frameHeight = 460
                // k.postImageHeight = 340
                console.log('i', k.frameHeight)
              } else {
                k.frameHeight = 160
                k.postImageHeight = 110
              }
            })
          })
          thiz.newsList = data

          console.log('sjk', thiz.newsList)
          thiz.loading = false
        })
    },
    // 栏目更多
    onGoFixtopic(fixtopicId) {
      this.$router.push({
        path: '/fixTopic.html',
        query: { id: fixtopicId },
      })
    },
    getCatalogId(catalogId) {
      var thiz = this
      RD.index()
        .catalog(catalogId)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts.forEach((k) => {
              if (thiz.videoShow) {
                k.imgURL = RD.video().id(k.postId).poster_URL()
                k.posterShow = true
                k.videoURL = RD.video().id(k.postId).svideo_URL()
              } else {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
            })
          })
          thiz.newsList = data
        })
    },
    // 更多
    onCatalogMore(topicId) {
      this.$router.push({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this
      console.log('event', event)
      console.log('ref', thiz.$refs)
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == 'video_') {
          if (currentplay == key) {
            // 当前正在播放的
            console.log('currentplay')
          } else {
            // 其他的都暂停
            console.log('sibliingpause', thiz.$refs, thiz.$refs[key])
            thiz.$refs[key][0].pause()
          }
        }
      }
    },
    // 城市的fixtopic
    onLocalFixtopic(id, type) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: id, name: type },
      })
      window.open(routerUrl.href, '_blank')
    },
    onLookPost(postId) {
      console.log('ms', postId)
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
    // 鼠标移入
    onNoposter(index, fixedIndex) {
      this.newsList[fixedIndex].posts[index].posterShow = false
    },
  },
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  .catalog-whole {
    height: auto;
    background-color: #f5f5f5;
    min-height: 1000px;
    width: 750px;
    margin: 0 auto;
    .catalogtitle {
      height: 220px;
      line-height: 220px;
      text-align: center;
      width: 750px;
      margin: 0 auto;
      background: no-repeat url(../assets/fixtopic.png);
      background-size: cover;
      padding-top: 15px;
      display: flex;
      .title {
        width: 180px;
        margin: 0 auto;
        font-size: 38px;
        font-weight: bold;
      }
    }

    .catalog-con {
      height: auto;
      width: 730px;
      margin: 0 auto;
      margin-bottom: 30px;
      .catalog-list {
        position: relative;
        width: 730px;
        margin: 10px auto;
        background-color: #f5f5f5;
        .catalog-title {
          height: 60px;
          width: 730px;
          line-height: 60px;
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          background-color: rgba(255, 255, 255, 0.3);
          color: #fff;
          display: flex;
          z-index: 1;
          cursor: pointer;
          font-size: 30px;
          .title-icon {
            height: 40px;
            margin-top: 7px;
            width: 10px;
            background: rgba(215, 20, 12, 0.75);
          }
          .title-left {
            position: absolute;
            left: 3rem;
            top: 0px;
            cursor: pointer;
          }
          .title-right {
            position: absolute;
            right: 25px;
            top: 4px;
            width: 45px;
            cursor: pointer;
            color: rgba(215, 20, 12, 0.75);
            font-size: 50px;
            .el-icon-right {
              font-size: 30px;
            }
          }
        }
        .catalog-content {
          position: relative;
          margin-top: 10px;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          justify-content: space-between;
          gap: 15px;
          .catalog-detail {
            height: 250px;
            width: 730px;
            position: relative;
            .img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
            }
            .title {
              position: absolute;
              bottom: 0px;
              background-color: #cac7ca;
              width: 100%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 20px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              opacity: 0.8;
            }
          }
          .catalog-detail:nth-child(1n + 2) {
            display: none;
          }
          // video {
          //   object-fit: contain;
          //   width: 100%;
          //   height: 100%;
          //   width: 300px;
          //   height: 213px;
          // }
          .video-item {
            width: 28.7rem;
            height: 22rem;
            position: relative;
            video {
              width: 100%;
              height: 100%;
              background-color: #ccc;
              cursor: pointer;
            }
            .mute {
              display: block;
              position: absolute;
              right: 2rem;
              top: 2rem;
              font-size: 2rem;
              height: 3rem;
              width: 3rem;
              border: 2px solid white;
              border-radius: 2rem;
              line-height: 3rem;
              color: white;
            }
            .mute.active {
              color: red;
            }
          }
        }
      }
    }

    .more-post {
      padding-bottom: 20px;
      padding-top: 20px;
      .btn {
        width: 120px;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        border-radius: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .catalog-whole {
    height: auto;
    background-color: #fff;
    min-height: calc(100vh - 50.3rem);
    .catalogtitle {
      height: 4rem;
      text-align: left;
      width: 120rem;
      margin: 0 auto;
      font-size: 1.6rem;
      padding-top: 1.5rem;
      display: flex;
      border-bottom: 0.1rem solid #bfbfbf;
      .icon {
        width: 2rem;
        height: 2rem;
        background: no-repeat url(../assets/weizhi.png);
        background-size: cover;
        margin-right: 1rem;
      }
    }
    .catalog-con {
      width: 120rem;
      margin: 3rem auto;
      height: 100%;
      overflow: hidden;
      .local-con {
        height: auto;
        overflow: hidden;
        margin-bottom: 5rem;
        .local-title {
          height: 4rem;
          line-height: 4rem;
          font-size: 2.5rem;
        }
        .local-img-con {
          width: 240rem;
          margin-top: 2rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .local-list {
            width: 28.3rem;
            height: 30rem;
            margin-right: 1.7rem;
            .local-list-img {
              height: 80%;
              width: 100%;
              background: no-repeat 50% / cover;
              cursor: pointer;
            }
            .local-list-text {
              height: 4rem;
              line-height: 4rem;
              font-size: 1.5rem;
              cursor: pointer;
            }
          }
        }
        .local-biao-con {
          height: auto;
          width: 120rem;
          display: flex;
          flex-wrap: wrap;
          margin-top: 4rem;
          .local-biao-list {
            width: 19rem;
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            background: #f5f5f5;
            margin: 0.5rem;
            font-size: 1.4rem;
            cursor: pointer;
          }
          .local-biao-list:hover {
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
          }
        }
      }
      .catalog-list {
        height: auto;
        width: 100%;
        margin-bottom: 3rem;
        background-color: #fff;

        .catalog-title {
          height: 10rem;
          font-size: 1.3rem;
          line-height: 10rem;
          text-align: left;
          font-weight: bold;
          position: relative;
          background-color: #f5f5f5;
          .title-left {
            position: absolute;
            left: 46%;
            width: auto;
            font-size: 2rem;
            cursor: pointer;
          }
          .title-right {
            position: absolute;
            right: 3.4rem;
            top: 0rem;
            width: 6.7rem;
            cursor: pointer;
            color: #9b9b9b;
            .el-icon-right {
              font-size: 2.8rem;
            }
          }
        }
        .cataloag-content {
          position: relative;
          height: 45rem;
          .catalog-detail {
            overflow: hidden;
            .img {
              transition: all 200ms ease-in-out;
              cursor: pointer;
            }
            .title {
              opacity: 0.8;
              transition: all 200ms ease-in-out;
              font-size: 1.2rem;
              background-color: rgba(0, 0, 0, 0.6);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding: 0rem 1rem;
              height: 4rem;
              line-height: 4rem;
              width: calc(100% - 2rem);
              position: absolute;
              bottom: 0rem;
              left: 0rem;
              color: #fff;
              cursor: pointer;
            }
          }
          .catalog-detail:hover {
            .img {
              transform: scale(1.05);
            }
            .title {
              opacity: 1;
              font-size: 1.6rem;
            }
          }
          .catalog-detail {
            background: #000;
            position: relative;
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
            }
            .video-img {
              position: absolute;
              top: 0rem;
              left: 0rem;
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
              z-index: 2000;
            }
          }
        }
        .fixtopOne {
          .catalog-detail:nth-child(1) {
            width: 59.6rem;
            height: 44.1rem;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 60.5rem;
          }
          .catalog-detail:nth-child(3) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 90.8rem;
          }
          .catalog-detail:nth-child(4) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.5rem;
            left: 60.5rem;
          }
          .catalog-detail:nth-child(5) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.5rem;
            left: 90.8rem;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 27.6rem;
            height: 20rem;
            position: absolute;
            top: 22.5rem;
            left: 192.4rem;
          }
        }
        .fixtopTwo {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .catalog-detail {
            width: 29.3rem;
            height: 21.4rem;
            position: relative;
          }
        }
        .fixtopThree {
          .catalog-detail:nth-child(1) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(2) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 0rem;
            left: 30.3rem;
          }
          .catalog-detail:nth-child(3) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.6rem;
            left: 0rem;
          }
          .catalog-detail:nth-child(4) {
            width: 29.3rem;
            height: 21.6rem;
            position: absolute;
            top: 22.6rem;
            left: 30.3rem;
          }
          .catalog-detail:nth-child(5) {
            width: 59.6rem;
            height: 44.1rem;
            position: absolute;
            top: 0rem;
            left: 60.6rem;
          }
          .catalog-detail:nth-child(6),
          .catalog-detail:nth-child(7),
          .catalog-detail:nth-child(8) {
            width: 57.1rem;
            height: 45rem;
            position: absolute;
            top: 0rem;
            left: 162.8rem;
          }
        }
      }
    }
  }
}
</style>
