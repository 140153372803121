<template>
  <div class="info">
    <Nav></Nav>
    <div class="info-con desktop-present">
      <div class="info-title">
        您的位置：首页>>个人信息<span @click="onUpdata">(修改个人信息)</span> <span @click="onPassWord"> (修改密码)</span><span @click="onBindMobile"> (绑定手机)</span>
      </div>
      <div class="info-detail">
        <div class="info-left">
          <div class="info image">
            <div class="image-title">登录者头像：</div>
            <div
              class="image-url"
              :style="{
                backgroundImage: `url(` + $rtm.store.userInfo.imageUrl + `)`,
              }"
              @click="onEnlargePic"
            ></div>
            <el-button class="up">
              <el-upload class="upload-demo" :action="imgUploadURL" :with-credentials="true" :on-success="onUploadImg" :show-file-list="false" :multiple="true">上传图片</el-upload>
            </el-button>
          </div>
          <div class="info"><span>登录名：</span>{{ $rtm.store.userInfo.loginName }}</div>
          <div class="info"><span>姓名：</span>{{ $rtm.store.userInfo.chnName }}</div>
          <div class="info"><span>头衔：</span>{{ $rtm.store.userInfo.head }}</div>
          <div class="info"><span>默认笔名：</span>{{ $rtm.store.userInfo.defaultAuthorName }}</div>
          <div class="info"><span>地址：</span>{{ $rtm.store.userInfo.address }}</div>
          <div class="info"><span>固定电话：</span>{{ $rtm.store.userInfo.telephone }}</div>
          <div class="info"><span>邮箱:</span>{{ $rtm.store.userInfo.email }}</div>
          <div class="info"><span>创建时间：</span>{{ $rtm.store.userInfo.createTime }}</div>
          <div class="comment">
            <div class="name">备注:</div>
            {{ $rtm.store.userInfo.comment }}
          </div>
          <div class="info"><span>角色名：</span>{{ $rtm.store.userInfo.roleName }}</div>
          <!-- <div class="info">
            <span>上传权限：</span>{{ $rtm.store.userInfo.address }}
          </div> -->
          <div class="info" v-if="$rtm.store.userInfo.lastLogin"><span>当前IP:</span>{{ $rtm.store.userInfo.lastLogin.ip }}</div>
        </div>
        <div class="info-right">
          <div class="info"><span>工作单位：</span>{{ $rtm.store.userInfo.company }}</div>
          <div class="info"><span>邮编：</span>{{ $rtm.store.userInfo.postCode }}</div>
          <div class="info">
            <span>移动电话：</span>{{ $rtm.store.userInfo.mobile }}<i>{{ $rtm.store.userInfo.mobileConfirmed ? '（已绑定）' : '（未绑定）' }}</i>
          </div>
          <div class="info" v-if="$rtm.store.userInfo.lastLogin"><span>当前登录时间：</span>{{ $rtm.store.userInfo.lastLogin.time }}</div>
        </div>
      </div>
    </div>
    <div class="info-con mobile-present">
      <div class="info-title">
        您的位置：首页>>个人信息<span @click="onUpdata">(修改个人信息)</span> <span @click="onPassWord"> (修改密码)</span><span @click="onBindMobile"> (绑定手机)</span>
      </div>
      <div class="info-detail">
        <div class="info-left">
          <div class="info image">
            <div class="image-title">登录者头像：</div>
            <div
              class="image-url"
              :style="{
                backgroundImage: `url(` + $rtm.store.userInfo.imageUrl + `)`,
              }"
              @click="onEnlargePic"
            ></div>
            <el-button class="up">
              <el-upload class="upload-demo" :action="imgUploadURL" :with-credentials="true" :on-success="onUploadImg" :show-file-list="false" :multiple="true">上传图片</el-upload>
            </el-button>
          </div>
          <div class="info"><span>登录名：</span>{{ $rtm.store.userInfo.loginName }}</div>
          <div class="info"><span>姓名：</span>{{ $rtm.store.userInfo.chnName }}</div>
          <div class="info"><span>头衔：</span>{{ $rtm.store.userInfo.head }}</div>
          <div class="info"><span>默认笔名：</span>{{ $rtm.store.userInfo.defaultAuthorName }}</div>
          <div class="info"><span>地址：</span>{{ $rtm.store.userInfo.address }}</div>
          <div class="info"><span>固定电话：</span>{{ $rtm.store.userInfo.telephone }}</div>
          <div class="info"><span>邮箱:</span>{{ $rtm.store.userInfo.email }}</div>
          <div class="info"><span>创建时间：</span>{{ $rtm.store.userInfo.createTime }}</div>
          <div class="comment">
            <div class="name">备注:</div>
            {{ $rtm.store.userInfo.comment }}
          </div>
          <div class="info"><span>角色名：</span>{{ $rtm.store.userInfo.roleName }}</div>
          <!-- <div class="info">
            <span>上传权限：</span>{{ $rtm.store.userInfo.address }}
          </div> -->
          <div class="info" v-if="$rtm.store.userInfo.lastLogin"><span>当前IP:</span>{{ $rtm.store.userInfo.lastLogin.ip }}</div>
        </div>
        <div class="info-right">
          <div class="info"><span>工作单位：</span>{{ $rtm.store.userInfo.company }}</div>
          <div class="info"><span>邮编：</span>{{ $rtm.store.userInfo.postCode }}</div>
          <div class="info">
            <span>移动电话：</span>{{ $rtm.store.userInfo.mobile }}<i>{{ $rtm.store.userInfo.mobileConfirmed ? '（已绑定）' : '（未绑定）' }}</i>
          </div>
          <div class="info" v-if="$rtm.store.userInfo.lastLogin"><span>当前登录时间：</span>{{ $rtm.store.userInfo.lastLogin.time }}</div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myInfo: {}, // 个人信息
      imgUploadURL: '',
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        data.imageUrl = RD.user().headPic_URL()
        thiz.$rtm.store.userInfo = data
        thiz.$rtm.store.logined = true
      })
      .catch((error) => {
        console.log('err', error)
        if (error.code == '-1') {
          thiz.$message('需要登录')
          thiz.$router.push('/index.html')
        }
      })
    thiz.imgUploadURL = RD.user().uploadHeadPic_URL()
    console.log('image', thiz.imageUrl)
  },
  methods: {
    // 修改信息
    onUpdata() {
      var thiz = this
      thiz.$router.push('/updataInfo.html')
    },
    // 修改密码
    onPassWord() {
      var thiz = this
      thiz.$router.push('/updataPassWord.html')
    },
    // 绑定手机
    onBindMobile() {
      var thiz = this
      thiz.$router.push('/bindMobile.html')
    },
    // 更换头像成功后
    onUploadImg(res, file) {
      console.log('46', file)
      var thiz = this
      thiz.$rtm.store.userInfo.imageUrl = RD.user().uploadHeadPic_URL() + '?_=' + new Date().getTime()
    },
    onEnlargePic() {
      console.log('kl')
    },
  },
}
</script>
<style scoped lang="less">
.info {
  background-color: #f1f1f1;
  .info-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: 60.2rem;
    .info-title {
      height: 4rem;
      background-color: #ccc;
      font-size: 1.6rem;
      line-height: 4.5rem;
      text-align: left;
      span {
        color: red;
        cursor: pointer;
      }
    }
    .info-detail {
      min-height: 61.85rem;
      background-color: #dfdfdf;
      display: flex;
      .info-left,
      .info-right {
        flex: 1;
        margin-left: 2rem;
        .info {
          height: 3rem;
          line-height: 2rem;
          text-align: left;
          font-size: 1.4rem;
          background-color: #dfdfdf;
          font-weight: bold;
          span {
            display: inline-block;
            width: 10.1rem;
          }
          i {
            color: red;
            font-style: normal;
          }
        }
        .comment {
          height: auto;
          font-size: 1.4rem;
          font-weight: bold;
          text-align: left;
          display: flex;
          .name {
            display: inline-block;
            flex-shrink: 1;
            width: 50rem;
            text-align: left;
          }
        }
        .image {
          height: 13rem;
          width: 100%;
          display: flex;
          margin: 1rem 0rem;
          .image-title {
            width: 10rem;
            height: 100%;
          }
          .image-url {
            width: 12rem;
            height: 12rem;
            border-radius: 6rem;
            background: no-repeat 50% / cover;
            border: 0.1rem solid #ccc;
            margin-left: 3rem;
          }
          .up {
            margin-top: 2rem;
            margin-left: 2rem;
            width: 10rem;
            height: 4.5rem;
          }
        }
      }
    }
  }
  .info-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 588px;
    .info-title {
      height: 60px;
      background-color: #ccc;
      font-size: 16px;
      line-height: 60px;
      text-align: left;
      span {
        color: red;
        cursor: pointer;
      }
    }
    .info-detail {
      min-height: 528px;
      background-color: #dfdfdf;
      display: flex;
      .info-left,
      .info-right {
        flex: 1;
        margin-left: 20px;
        .info {
          height: 30px;
          line-height: 30px;
          text-align: left;
          font-size: 14px;
          background-color: #dfdfdf;
          font-weight: bold;
          span {
            display: inline-block;
            width: 120px;
          }
          i {
            color: red;
            font-style: normal;
          }
        }
        .commment {
          display: flex;
          font-size: 15px;
          .name {
            width: 120px;
            text-align: left;
          }
        }
        .image {
          height: 13rem;
          width: 100%;
          display: flex;
          margin: 1rem 0rem;
          .image-title {
            width: 10rem;
            height: 100%;
          }
          .image-url {
            width: 12rem;
            height: 12rem;
            border-radius: 6rem;
            background: no-repeat 50% / cover;
            border: 0.1rem solid #ccc;
            margin-left: 3rem;
          }
          .up {
            margin-top: 20px;
            margin-left: 20px;
            width: 100px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
