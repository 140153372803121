<template>
  <div class="all">
    <div class="about mobile-present">
      <div class="video-show" v-if="videoShow">
        <video :src="postInfo.videoURL" :poster="postInfo.videoimgURL" controls loop></video>
      </div>
      <div class="pic-img" v-else>
        <img class="mobile-img" :src="picImg" />
      </div>
      <div class="post-info">
        <div class="mobile-title">{{ postInfo.title }}</div>
        <div class="mobile-text" v-bind:class="{ active: textMoreShow == true, autohieght: textAutoShow == true }">{{ postInfo.mainText }}</div>
        <div class="mobile-moreTextUp" v-if="textMoreShow == true && textAutoShow == false" @click="onMoreTextUp">收起<span class="el-icon-arrow-up"></span></div>
        <div class="mobile-moreTextDown" v-if="textMoreShow == false && textAutoShow == false" @click="onMoreTextDown">阅读全文<span class="el-icon-arrow-down"></span></div>
      </div>
      <div class="pic-info">
        <div class="item"><span>原片尺寸：</span>{{ picInfo.width }}*{{ picInfo.height }}</div>
        <div class="item"><span>创作日期：</span>{{ postInfo.eventTime }}</div>
        <div class="item"><span>版权使用：</span>联系使用</div>
        <div class="item"><span>作者：</span>{{ postInfo.author }}</div>
      </div>
      <div class="pic-down">
        <el-button type="danger" @click="onDown">下载作品</el-button>
      </div>
      <div class="post-about">
        <div class="about-title">
          <div class="title">相关推荐</div>
        </div>
        <div class="about-post">
          <div class="about-item" v-for="k in aboutPostList" :key="k.id" @click="onLookPost(k.id)">
            <div class="about-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }"></div>
            <div class="about-text">
              <div class="title_s">
                {{ k.title }}
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          class="pager"
          @current-change="onPageChange"
          :current-page="pager.currentPage"
          :page-size="pager.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pager.total"
        ></el-pagination>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'
export default {
  name: 'picDetail',
  components: { Bottom },
  data: function () {
    return {
      postInfo: {}, //图片信息
      picInfo: {},
      aboutPostList: [], // 相关的稿件列表
      picImg: '',
      textMoreShow: false, // 是否展开更多文字。mobile下
      textAutoShow: false,
      videoShow: false,
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('78', this.$route.query)
      if (!this.$route.query.hasOwnProperty('picId')) {
        thiz.videoShow = true
      }
      console.log('ops', thiz.videoShow)

      thiz.picImg = RD.pic().id(this.$route.query.picId).mImage_URL()
      RD.pure()
        .post()
        .id(this.$route.query.postId)
        .one()
        .then((data) => {
          if (data.mainText.length <= 120) {
            thiz.textAutoShow = true
          } else {
            thiz.textAutoShow = false
          }
          data.pics.forEach((k) => {
            if (this.$route.query.picId == k.id) {
              thiz.picInfo = k
            }
          })
          thiz.getAbout(data)
          if (thiz.videoShow) {
            data.videoimgURL = RD.video().id(data.id).poster_URL()
            data.videoURL = RD.video().id(data.id).mvideo_URL()
          }
          this.postInfo = data
        })
    },
    getAbout(info) {
      const thiz = this
      if (info.recentTopics[0]) {
        RD.fixedTopic()
          .id(info.recentTopics[0].id)
          .posts(thiz.pager.currentPage, 8)
          .then((data) => {
            data.list.forEach((k) => {
              if (thiz.videoShow) {
                k.imgURL = RD.video().id(k.id).poster_URL()
              } else {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
            })
            thiz.aboutPostList = data.list
            thiz.pager = data.pager
          })
      }
    },
    // mobile下的展开更多正文
    onMoreTextUp() {
      this.textMoreShow = false
    },
    // mobile下的展开更多正文
    onMoreTextDown() {
      this.textMoreShow = true
    },
    onDown() {
      this.$confirm('请前往美美吴江网页版下载，网址：https://www.meimeiwujiang.com/', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          window.open('https://www.meimeiwujiang.com/', '_blank')
        })
        .catch(() => {})
    },
    onPageChange(newPage) {
      this.pager.currentPage = newPage
      this.reload()
    },
    onLookPost(postId) {
      const thiz = this
      if (thiz.videoShow) {
        this.$router.push({
          path: '/picDetail.html',
          query: { postId: postId },
        })
      } else {
        this.$router.push({
          path: '/post.html',
          query: { id: postId },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.about.mobile-present {
  width: 750px;
  margin: 0 auto;
  background-color: #f5f5f5;
  .pic-img {
    width: 750px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-show {
    height: 500px;
    background-color: #000;
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .post-info {
    background-color: #fff;
    padding-top: 20px;
    .mobile-title {
      font-size: 45px !important;
      font-weight: bold;
      font-family: '黑体';
      margin-left: 10px;
    }
    .mobile-text {
      font-size: 30px !important;
      margin: 35px 35px 0px;
      line-height: 65px;
      white-space: pre-wrap;
      height: 198px;
      overflow: hidden;
      text-indent: 67px;
    }
    .active {
      height: auto !important;
    }
    .autohieght {
      height: auto !important;
    }
    .mobile-moreTextDown {
      height: auto;
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }
    .mobile-moreTextUp {
      height: 50px;
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .pic-info {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
    width: 680px;
    margin: 0 auto;
    height: 120px;
    .item {
      width: 240px;
      flex-shrink: 0;
      font-size: 22px;
      span {
        width: 80px;
        height: 45px;
        line-height: 45px;
        text-align: justify;
        text-align-last: justify;
      }
    }
  }
  .pic-down {
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    .el-button {
      font-size: 20px;
    }
  }

  .post-about {
    width: 730px;
    margin: 0 auto;
    .about-title {
      width: 100%;
      text-align: left;
      .title {
        margin: 0 2rem;
        font-size: 30px;
        font-weight: bold;
      }
    }
    .about-post {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      .about-item {
        width: 340px;
        height: 220px;
        cursor: pointer;
        .about-img {
          width: 100%;
          height: 180px;
          background: no-repeat 50% / cover;
        }
        .about-text {
          text-align: left;
          height: 40px;
          line-height: 40px;
          color: #868686;
          font-size: 24px;
          .title_s {
            height: 100%;
            transition: all 0.5s linear;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 1rem;
            text-align: left;
            left: 0;
            right: 0;
            line-height: 40px;
            cursor: pointer;
          }
          .item {
            height: 2.5rem;
            line-height: 2.5rem;
          }
        }
      }
    }
    .pager {
      text-align: right;
    }
  }
}
</style>
