<template>
  <div class="web-item ft-con" :class="'model_' + content.model">
    <div class="item-title-container">
      <div class="item-title" @click="$parent.onClickTopic(webItem.id)">
        {{ webItem.title }}
      </div>
      <div class="item-icon">{{ content.subTitle }}</div>
      <div class="item-red"></div>
    </div>
    <div class="ft-model-m_swiper" v-if="webItem.model == 'm_swiper'">
      <div class="swiper-left">
        <div class="left" @click="onLeft">
          <div class="left-icon"></div>
        </div>
      </div>
      <div class="swiper-right">
        <div class="right" @click="onRight">
          <div class="right-icon"></div>
        </div>
      </div>
      <div class="swiper-list" @mouseenter="FTstopTimer()" @mouseleave="FTrestartTimer()">
        <template v-for="(pic, contentIndex) in webItem.list">
          <div
            :key="contentIndex + 'FT' + pic.id"
            class="pic"
            v-show="swipeFilter(contentIndex, webItem.list.length, swipeIndex, 4, 1)"
            :class="'pos-' + swipePosition(contentIndex, swipeIndex, webItem.list.length, 6)"
            :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
            @click="$parent.onLookPost(pic.postId)"
          >
            <div class="text">
              <div class="text-con">
                <div class="name">{{ pic.title }}</div>
                <div class="main-text">{{ pic.mainText }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- m_12 -->
    <!-- <div class="ft-model-m_12" v-else-if="webItem.model == 'm_12'">
      <div class="post-con" v-for="(post, index) in webItem.list" :key="'ft' + post.postId" @click="$parent.onLookPost(post.postId)">
        <div class="post-img" :style="{ backgroundImage: `url(` + post.imgURL + `)` }"></div>
        <div class="post-text">{{ post.title }}</div>
      </div>
    </div> -->

    <!-- 固定栏目其它 -->
    <div :class="'ft-model-' + webItem.model" v-else>
      <div class="post-con" v-for="post in webItem.list" :key="'ft' + post.postId" @click="$parent.onLookPost(post.postId)">
        <div class="post-img" :style="{ backgroundImage: `url(` + post.imgURL + `)` }"></div>
        <div class="post-text">{{ post.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexFt',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      swipeIndex: 0,
      swipeMoving: false,
      fttimer: [],
    }
  },
  created() {
    this.reload()
  },
  // destroyed() {
  //   clearInterval(fttimer)
  // },
  methods: {
    reload() {
      const thiz = this
      this.content.list.forEach((i) => {
        if (i.postId) {
          i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
        }
        if (this.content.model == '3s') {
          i.imgURL = RD.pic().id(i.frontPicId).tImage_URL()
        }
      })
      this.webItem = this.content
      this.$nextTick(() => {
        this.swipeMoving = false
      })
      thiz.fttimer = setInterval(() => {
        ;(() => {
          thiz.move()
        })()
      }, 5000)
    },
    move() {
      const thiz = this
      if (thiz.swipeMoving) {
        return
      }
      thiz.swipeMoving = true
      thiz.swipeIndex += 1
      setTimeout(() => {
        thiz.swipeMoving = false
      }, 500)
    },
    FTstopTimer() {
      clearInterval(this.fttimer)
    },
    FTrestartTimer() {
      if (this.fttimer) {
        clearInterval(this.fttimer)
      }
      this.fttimer = setInterval(() => {
        this.move()
      }, 3000)
    },
    // 左移
    onLeft() {
      if (this.swipeMoving) {
        return
      }
      this.swipeMoving = true
      this.swipeIndex -= 1
      setTimeout(() => {
        this.swipeMoving = false
      }, 500)
      this.FTstopTimer()
    },
    // 右移
    onRight() {
      if (this.swipeMoving) {
        return
      }
      this.swipeMoving = true
      this.swipeIndex += 1
      setTimeout(() => {
        this.swipeMoving = false
      }, 500)
      this.FTstopTimer()
    },
    /**
     * swipe filter utils, return if the target index is show or not
     * @Param targetIndex item index to test
     * @Param length array length
     * @Param swipeIndex swipePosition to test
     * @Param forwardGap how many item in the array larger than swipeIndex will be marked as valid
     * @Param backwardGap how many item in the array smaller the swipeIndex will be marked as valid
     */
    swipeFilter(t, l, s, f, b) {
      return !((((t - s) % l) + l) % l > f && (((s - t) % l) + l) % l > b)
    },
    /**
     * return the position index
     * position when swipeIndex == targetIndex return 0
     * forward return  1 2 3
     * backword return total -1 total -2 total -3
     * @Param targetIndex
     * @Param swipeIndex
     * @Param total length of array
     * @Param length of items show
     */ swipePosition(t, s, l, g) {
      return Math.abs((t - (((s % l) + l) % l) + l) % l) <= Math.abs(((((s % l) + l) % l) - t + l) % l)
        ? ((((t - s) % l) + l) % l) % g
        : t > ((s % l) + l) % l
        ? (((-(((s % l) + l) % l) - l + t) % g) + g) % g
        : (((-(((s % l) + l) % l) + t) % g) + g) % g
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .model_m_swiper {
    background-size: cover;
  }
  .ft-con {
    width: 750px;
    margin: 1.7rem auto;
    position: relative;

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
    }

    .post-text {
      position: absolute;
      bottom: 0.66rem;
      left: 50%;
      transform: translate(-50%);
      width: 85%;
      border-radius: 0.42rem;
      font-size: 16px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      transition: all 500ms ease-in-out;
    }

    .ft-model-m_3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-m_111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-m_12 {
      height: 290px;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 486px;
        height: 271px;
        top: 0px;
        left: 15px;
      }

      .post-con:nth-child(2) {
        width: 224px;
        height: 130px;
        top: 0px;
        right: 15px;
      }

      .post-con:nth-child(3) {
        width: 224px;
        height: 130px;
        top: 140px;
        right: 15px;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        height: 217px;
      }

      .post-con:nth-child(1) {
        width: 45rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 45rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 74rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-m_14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-m_1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-m_swiper {
      width: 750px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;

      .swiper-list {
        position: relative;
        width: 83%;
        overflow: hidden;
        height: 270px;
        justify-content: flex-start;
        margin: 0 auto;
        gap: 0px;
        .pic {
          height: 217px;
          width: 156px;
          flex-shrink: 0;
          top: 0px;
          background: no-repeat 50% / cover;
          box-sizing: border-box;
          position: absolute;
          transition: 500ms left, width, top, height ease-in-out;
          cursor: pointer;
          .text {
            width: 100%;
            height: 100%;
            position: relative;
            transition: all 200ms ease-in-out;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(2px);
            border-radius: 4px;
            opacity: 0;
            .text-con {
              position: absolute;
              bottom: 0px;
              height: 16.3rem;
              width: 100%;
              background: rgba(215, 20, 12, 0.75);
              .name {
                width: 19rem;
                margin: 0 auto;
                height: 2.5rem;
                font-size: 18px;
                color: #fff;
                margin-top: 3.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .main-text {
                width: 83%;
                text-align: center;
                margin: 15px auto 0px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.7);
                overflow: hidden;
                font-size: 1.4rem;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
        .pic.pos-0 {
          left: 0rem;
        }
        .pic.pos-1 {
          left: 156px;
        }
        .pic.pos-2 {
          left: 312px;
        }
        .pic.pos-3 {
          left: 468px;
        }
        .pic.pos-4 {
          left: 624px;
        }
        .pic.pos-5 {
          top: -1200rem;
          left: 120rem;
          width: 1px;
          height: 1px;
        }
        .pic.pos-6 {
          top: -1200rem;
          left: -30rem;
          width: 1px;
          height: 1px;
        }
        .pic.pos-7 {
          left: -30rem;
        }
        .pic:hover {
          border: 3px solid rgba(215, 20, 12, 0.75);
          .text {
            opacity: 1;
          }
        }
      }

      .swiper-left {
        position: absolute;
        left: 15px;
        top: 40%;
        cursor: pointer;
        .left {
          width: 30px;
          height: 30px;
          background: rgba(0, 0, 0, 0.25);
          position: relative;
          .left-icon {
            height: 2.5rem;
            width: 2.5rem;
            border-top: 0.3rem solid #dcdcdd;
            border-left: 0.3rem solid #dcdcdd;
            transform: rotate(-45deg);
            position: absolute;
            top: 7px;
            left: 10px;
          }
        }
      }
      .swiper-right {
        position: absolute;
        right: 15px;
        top: 40%;
        cursor: pointer;
        .right {
          width: 30px;
          height: 30px;
          background: rgba(0, 0, 0, 0.25);
          position: relative;
          .right-icon {
            height: 2.5rem;
            width: 2.5rem;
            border-bottom: 0.3rem solid #dcdcdd;
            border-right: 0.3rem solid #dcdcdd;
            transform: rotate(-45deg);
            position: absolute;
            top: 7px;
            right: 10px;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .model_m_swiper {
    background-size: cover;
    height: 72.3rem;
  }
  .ft-con {
    width: 100%;
    margin: 5rem auto 0rem;
    position: relative;
    .post-text {
      cursor: pointer;
    }

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
    }

    // .post-text {
    //   position: absolute;
    //   bottom: 0.66rem;
    //   left: 50%;
    //   transform: translate(-50%);
    //   width: 85%;
    //   border-radius: 0.42rem;
    //   font-size: 1.3rem;
    //   text-align: center;
    //   height: 3.9rem;
    //   line-height: 3.9rem;
    //   background-color: rgba(0, 0, 0, 0.3);
    //   color: #fff;
    //   transition: all 500ms ease-in-out;
    // }

    .ft-model-m_3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-m_111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-m_12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-text {
          width: 0rem;
          height: 0rem;
          transition: all 500ms ease-in-out;
          position: absolute;
          left: 0rem;
          top: 0rem;
          opacity: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-wrap: nowrap;
        }
      }

      .post-con:nth-child(1):hover {
        .post-text {
          width: 100%;
          height: 100%;
          opacity: 1;
          color: #fff;
          line-height: 50rem;
          font-size: 1.6rem;
          vertical-align: middle;
        }
      }

      .post-con:hover {
        .post-text {
          width: 100%;
          height: 100%;
          opacity: 1;
          color: #fff;
          line-height: 25rem;
          font-size: 1.6rem;
          vertical-align: middle;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 45rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 45rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 74rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-m_14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-m_1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-m_swiper {
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      .swiper-list {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 48rem;
        gap: 10px;
        .pic {
          height: 47.2rem;
          width: 28rem;
          flex-shrink: 0;
          top: 0px;
          background: no-repeat 50% / cover;
          box-sizing: border-box;
          position: absolute;
          transition: 500ms left, width, top, height ease-in-out;
          cursor: pointer;
          .text {
            width: 100%;
            height: 100%;
            position: relative;
            transition: all 200ms ease-in-out;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(2px);
            border-radius: 4px;
            opacity: 0;
            .text-con {
              position: absolute;
              bottom: 0px;
              height: 16.3rem;
              width: 100%;
              background: rgba(215, 20, 12, 0.75);
              .name {
                width: 19rem;
                margin: 0 auto;
                height: 2.5rem;
                font-size: 18px;
                color: #fff;
                margin-top: 3.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .main-text {
                width: 83%;
                text-align: center;
                margin: 15px auto 0px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.7);
                overflow: hidden;
                font-size: 1.4rem;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
        .pic.pos-0 {
          left: 0rem;
        }
        .pic.pos-1 {
          left: 30rem;
        }
        .pic.pos-2 {
          left: 60rem;
        }
        .pic.pos-3 {
          left: 90rem;
        }
        .pic.pos-4 {
          left: 120rem;
        }
        .pic.pos-5 {
          top: -1200rem;
          left: 120rem;
          width: 1px;
          height: 1px;
        }
        .pic.pos-6 {
          top: -1200rem;
          left: -30rem;
          width: 1px;
          height: 1px;
        }
        .pic.pos-7 {
          left: -30rem;
        }
        .pic:hover {
          border: 3px solid rgba(215, 20, 12, 0.75);
          .text {
            opacity: 1;
          }
        }
      }

      .swiper-left {
        position: absolute;
        left: -7rem;
        top: 40%;
        cursor: pointer;
        .left {
          width: 6.4rem;
          height: 6.4rem;
          background: rgba(0, 0, 0, 0.25);
          position: relative;
          .left-icon {
            height: 2.5rem;
            width: 2.5rem;
            border-top: 0.3rem solid #dcdcdd;
            border-left: 0.3rem solid #dcdcdd;
            transform: rotate(-45deg);
            position: absolute;
            top: 2rem;
            left: 2.5rem;
          }
        }
      }
      .swiper-right {
        position: absolute;
        right: -7rem;
        top: 40%;
        cursor: pointer;
        .right {
          width: 6.4rem;
          height: 6.4rem;
          background: rgba(0, 0, 0, 0.25);
          position: relative;
          .right-icon {
            height: 2.5rem;
            width: 2.5rem;
            border-bottom: 0.3rem solid #dcdcdd;
            border-right: 0.3rem solid #dcdcdd;
            transform: rotate(-45deg);
            position: absolute;
            top: 2rem;
            right: 2.5rem;
          }
        }
      }
    }
  }
}
</style>

