<template>
  <div>
    <div class="bottom">
      <div class="bottom-con">
        <div class="content">
          <div class="logo-img"></div>
          <div class="con">
            <div class="item">
              <span>关于我们</span>
              <i></i>
              <span>联系我们</span>
              <i></i>
              <span>用户协议</span>
              <i></i>
              <span>隐私政策</span>
              <i></i>
              <span>资源版权</span>
            </div>
            <div class="link">
              <a style="color: #575857" href="https://beian.miit.gov.cn" target="_blank">苏ICP备2024070878号-1</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .bottom {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    .bottom-con {
      position: relative;
      width: 750px;
      margin: 0 auto;
      .logo-img {
        height: 80px;
        width: 220px;
        margin: 0 auto;
        background: no-repeat url(../assets/new_logo.png) center;
        background-size: contain;
      }
      .con {
        height: 80px;
        .item {
          span {
            font-size: 20px;
            margin-right: 10px;
          }
          i {
            font-style: normal;
            display: inline-block;
            width: 2px;
            height: 14px;
            background-color: #000;
            margin-right: 10px;
          }
        }
        .link {
          font-size: 20px;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .bottom {
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    .bottom-con {
      height: 309px;
      padding-top: 10px;
      position: relative;
      width: 1200px;
      margin: 0 auto;
      .logo-img {
        height: 200px;
        width: 200px;
        margin: 0 auto;
        background: no-repeat url(../assets/new_logo.png) center;
        background-size: contain;
      }
    }
  }
}
</style>
