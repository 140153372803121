<template>
  <div class="fts">
    <div class="item-title-container" @click="onOpenCatalog(content.title)">
      <div class="item-red"></div>
      <div class="item-title">
        {{ content.title }}
      </div>
      <div class="item-icon">{{ content.subTitle }}</div>
    </div>
    <div class="fts mobile-present">
      <div class="ft-model-3s" v-if="webItem.model == '3s'" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
        <div
          v-for="(pic, contentIndex) in webItem.list"
          :key="contentIndex + 'FTS' + pic.id"
          class="pic"
          :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
          :class="{ active: picIndex == contentIndex }"
          @click="onLookPost(pic.postId)"
        ></div>
        <div class="text">
          <div
            v-for="(pic, navIndex) in webItem.list"
            :key="'fts_' + pic.id + '_nav' + '_' + navIndex"
            class="nav-pic"
            :class="{ active: picIndex == navIndex }"
            @click="scrollTo(index, navIndex)"
          ></div>
        </div>
      </div>

      <div class="fts-model-m_swiper" v-if="webItem.model == 'm_swiper'">
        <div class="swiper-list" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
          <!-- <template v-for="(pic, contentIndex) in postList">
            <div :key="contentIndex + 'FTS' + pic.id" class="pic" @click="$parent.onLookPost(pic.postId)">
              <div class="img" :style="{ backgroundImage: `url(` + pic.postImgURL + `)` }"></div>
              <div class="text-con">
                <div class="name">{{ pic.title }}</div>
              </div>
            </div>
          </template> -->

          <div
            v-for="(pic, contentIndex) in postList"
            :key="pic + 'pic_' + contentIndex"
            class="front-pic"
            :class="{ active: picIndex == contentIndex }"
            @click="onLookPost(pic.postId)"
          >
            <div class="pic-inner" :style="{ backgroundImage: `url(` + pic.postImgURL + `)` }"></div>
            <div class="text-con">
              <div class="name">{{ pic.title }}</div>
            </div>
          </div>
        </div>
        <div class="left-half">
          <i class="left-button el-icon-caret-left" @click="swipeLeft"></i>
        </div>
        <div class="right-half">
          <i class="right-button el-icon-caret-right" @click="swipeRight"></i>
        </div>
      </div>

      <!-- 固定栏目其它 -->
      <div :class="'fts-model-' + webItem.model" v-else v-touch:swipe.left="swipeHandler" v-touch:swipe.right="swipeHandlerRight">
        <!-- <template v-for="(post, contentIndex) in webItem.list">
          <div
            class="post-con"
            :key="'fts_' + post.id"
            @click="onLookPost(post.posts[0].postId)"
            v-show="swipeFilter(contentIndex, webItem.list.length, swipeIndex, 3, 1)"
            :class="'pos-' + swipePosition(contentIndex, swipeIndex, webItem.list.length, 5)"
          >
            <div class="post-img" :style="{ backgroundImage: `url(` + post.posts[0].postImgURL + `)` }"></div>
            <div class="post-text">
              <div @click="$parent.onClickTopic(post.id)" class="culture-t">
                {{ post.name }}
              </div>
              <div class="title" @click="onLookPost(post.posts[0].postId)">{{ post.posts[0].title }}</div>
            </div>
          </div>
        </template> -->
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexFts',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      swipeIndex: 0, // 轮播的Index
      swipeMoving: false,
      picIndex: 0, //h5滑动index
      postList: [], //首页展示的图
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      // setTimeout(() => {
      var arr = []
      console.log('jk', thiz.content)

      thiz.content.list.forEach((i) => {
        i.imgURL = RD.fixedTopic().id(i.id).headPic_URL()
        i.posts.forEach((k) => {
          k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
          k.videoimgURL = RD.video().id(k.postId).poster_URL()
          k.videoURL = RD.video().id(k.postId).svideo_URL()
          k.posterShow = true
        })
        var post = i.posts.splice(0, 4)

        arr.push(post)
      })
      thiz.postList = arr.flat(Infinity)
      console.log('newArr', thiz.postList)

      this.webItem = this.content
      this.$nextTick(() => {
        this.swipeMoving = false
      })
      // }, 1000)
    },
    onLookPost(postId) {
      var postId = this.postList[this.picIndex].postId
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
    // 分类的标题的点击
    onOpenCatalog(name) {
      this.$router.push({
        path: '/catalog.html',
        query: { title: name },
      })
    },
    // 鼠标move
    swipeHandler() {
      if (this.swipeMoving) {
        return
      }
      this.swipeMoving = true
      this.swipeIndex += 1
      setTimeout(() => {
        this.swipeMoving = false
      }, 500)
    },
    swipeHandlerRight() {
      if (this.swipeMoving) {
        return
      }
      this.swipeMoving = true
      this.swipeIndex -= 1
      setTimeout(() => {
        this.swipeMoving = false
      }, 500)
    },
    // 鼠标move
    swipeLeft() {
      console.log('swipe left')
      const thiz = this
      this.picIndex += 1
      if (thiz.picIndex >= thiz.postList.length) {
        thiz.picIndex = 0
      }
    },
    swipeRight() {
      console.log('swipe right')
      const thiz = this
      this.picIndex -= 1
      var picLength = thiz.postList.length - 1
      if (thiz.picIndex < 0) {
        thiz.picIndex = picLength
      }
    },
    /**
     * swipe filter utils, return if the target index is show or not
     * @Param targetIndex item index to test
     * @Param length array length
     * @Param swipeIndex swipePosition to test
     * @Param forwardGap how many item in the array larger than swipeIndex will be marked as valid
     * @Param backwardGap how many item in the array smaller the swipeIndex will be marked as valid
     */
    swipeFilter(t, l, s, f, b) {
      return !((((t - s) % l) + l) % l > f && (((s - t) % l) + l) % l > b)
    },
    /**
     * return the position index
     * position when swipeIndex == targetIndex return 0
     * forward return  1 2 3
     * backword return total -1 total -2 total -3
     * @Param targetIndex
     * @Param swipeIndex
     * @Param total length of array
     * @Param length of items show
     */ swipePosition(t, s, l, g) {
      return Math.abs((t - (((s % l) + l) % l) + l) % l) <= Math.abs(((((s % l) + l) % l) - t + l) % l)
        ? ((((t - s) % l) + l) % l) % g
        : t > ((s % l) + l) % l
        ? (((-(((s % l) + l) % l) - l + t) % g) + g) % g
        : (((-(((s % l) + l) % l) + t) % g) + g) % g
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .fts {
    background-color: #f7f7f7;
    margin-bottom: 20px;
    .item-title-container {
      position: relative;
      height: 65px;
      width: 750px;
      margin: 1.7rem auto;
      line-height: 10rem;
      text-align: left;
      display: flex;
      .item-red {
        width: 8px;
        height: 8px;
        position: absolute;
        top: 25px;
        left: 10px;
        background-color: #cf0000;
      }
      .item-title {
        font-size: 40px;
        cursor: pointer;
        display: inline-block;
        height: 50px;
        margin-left: 20px;
        padding: 0px 1.5rem;
        font-weight: bold;
      }
      .item-icon {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
  .fts-model-m_22 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    width: 750px;
    margin: 0 auto;
    .post-con {
      width: 370px;
      height: 325px;

      .post-img {
        height: 286px;
        width: 100%;
        background-size: cover;
        cursor: pointer;
      }

      .post-text {
        font-size: 1.5rem;
        display: flex;

        .icon {
          display: inline-block;
          width: 45px;
          height: 35px;
          margin-left: 137px;
          background-size: cover;
        }

        .culture-t {
          cursor: pointer;
        }

        div {
          height: 42px;
          line-height: 42px;
        }
      }
    }
  }

  .mobile-present {
    width: 750px;
    overflow: hidden;
    background-color: #f7f7f7;
    margin: 0 auto;
  }

  .fts-model-m_swiper {
    width: 730px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .left-half {
      position: absolute;
      height: 100px;
      width: 100px;
      left: 6%;
      top: 45%;
      transform: translate(-50%, -50%);
      .left-button {
        position: absolute;
        left: 0rem;
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
        font-size: 8rem;
        line-height: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        // z-index: 1001;
        transition: all 200ms ease-in-out;
        cursor: pointer;
      }
    }
    .left-half:hover {
      .left-button {
        left: 0px;
      }
    }
    .right-half {
      position: absolute;
      height: 100px;
      width: 100px;
      right: -50px;
      top: 45%;
      transform: translate(-50%, -50%);
      .right-button {
        position: absolute;
        right: 0px;
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
        font-size: 8rem;
        line-height: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        // z-index: 1001;
        transition: all 200ms ease-in-out;
        cursor: pointer;
      }
    }
    .right-half:hover {
      .right-button {
        right: 0px;
      }
    }
    .swiper-list {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 450px;

      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;

        .pic-inner {
          width: 100%;
          height: 88%;
          background: no-repeat 50% / cover;
        }
        .text-con {
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          text-align: left;
        }

        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }

        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }

      .front-pic.active {
        opacity: 1;
      }
    }

    .swiper-left {
      position: absolute;
      left: -7rem;
      top: 40%;
      cursor: pointer;
      .left {
        width: 6.4rem;
        height: 6.4rem;
        background: rgba(0, 0, 0, 0.25);
        position: relative;
        .left-icon {
          height: 2.5rem;
          width: 2.5rem;
          border-top: 0.3rem solid #dcdcdd;
          border-left: 0.3rem solid #dcdcdd;
          transform: rotate(-45deg);
          position: absolute;
          top: 2rem;
          left: 2.5rem;
        }
      }
    }
    .swiper-right {
      position: absolute;
      right: -7rem;
      top: 40%;
      cursor: pointer;
      .right {
        width: 6.4rem;
        height: 6.4rem;
        background: rgba(0, 0, 0, 0.25);
        position: relative;
        .right-icon {
          height: 2.5rem;
          width: 2.5rem;
          border-bottom: 0.3rem solid #dcdcdd;
          border-right: 0.3rem solid #dcdcdd;
          transform: rotate(-45deg);
          position: absolute;
          top: 2rem;
          right: 2.5rem;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .fts {
    width: 100%;
    background: #f7f7f7;
  }
  .fts-model-m_22 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    .post-con {
      width: 58.3rem;
      height: 32.25rem;

      .post-img {
        height: 28.6rem;
        width: 100%;
        background-size: cover;
        cursor: pointer;
      }

      .post-text {
        font-size: 1.5rem;
        display: flex;

        .icon {
          display: inline-block;
          width: 4.5rem;
          height: 3.5rem;
          margin-left: 22.4rem;
          background-size: cover;
        }

        .culture-t {
          cursor: pointer;
        }

        div {
          height: 4.2rem;
          line-height: 4.2rem;
        }
      }
    }
  }
  .ft-model-m_21 {
    height: 51rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;

    .post-con {
      position: absolute;
      overflow: hidden;
    }

    .post-con:nth-child(1) {
      width: 34rem;
      height: 25rem;
      top: 0;
      left: 0;
    }

    .post-con:nth-child(2) {
      width: 34rem;
      height: 25rem;
      bottom: 0;
      left: 0;
    }

    .post-con:nth-child(3) {
      width: 85rem;
      height: 51rem;
      top: 0;
      right: 0;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
  .ft-model-m_111 {
    height: 19.5rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    .post-con {
      width: 39.5rem;
      height: 20rem;
      position: relative;
      overflow: hidden;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
  .fts-model-m_142 {
    height: 84rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    .post-con {
      position: absolute;
      overflow: hidden;
      .post-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
      }
      .post-text {
        width: 0rem;
        height: 0rem;
        // width: 100%;
        // height: 100%;
        transition: all 500ms ease-in-out;
        position: absolute;
        left: 0rem;
        top: 0rem;
        opacity: 0;
        cursor: pointer;
        .culture-t {
          position: absolute;
          top: 0px;
          left: 0px;
          color: #fff;
          cursor: pointer;
          width: 100%;
          line-height: 5rem;
          height: 5rem;
          padding-left: 5px;
          text-align: left;
          font-size: 1.8rem;
          // background: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 0.6));
          background: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 0.6));
        }
        .title {
          position: absolute;
          bottom: 0px;
          left: 0px;
          color: #fff;
          height: 5rem;
          line-height: 5rem;
          width: 100%;
          text-align: left;
          font-size: 1.6rem;
          padding-left: 5px;
          // background: linear-gradient(360deg, rgba(0, 0, 0, 0.84) 0%, rgba(202, 202, 202, 0) 100%);
          background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.6));
          cursor: pointer;
        }
      }
    }
    .post-con:hover {
      .post-text {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }

    .post-con:nth-child(1) {
      width: 30.3rem;
      height: 52.8rem;
      top: 0;
      left: 0;
    }

    .post-con:nth-child(2) {
      width: 43.7rem;
      height: 25.4rem;
      top: 0;
      left: 31.5rem;
    }

    .post-con:nth-child(3) {
      width: 43.7rem;
      height: 25.4rem;
      top: 0;
      right: 0;
    }

    .post-con:nth-child(4) {
      width: 43.7rem;
      height: 25.4rem;
      top: 27.4rem;
      left: 31.5rem;
    }

    .post-con:nth-child(5) {
      width: 43.7rem;
      height: 25.4rem;
      top: 27.4rem;
      right: 0;
    }

    .post-con:nth-child(6) {
      width: 59.4rem;
      height: 25.4rem;
      top: 54.5rem;
      left: 0;
    }

    .post-con:nth-child(7) {
      width: 59.4rem;
      height: 25.4rem;
      top: 54.5rem;
      right: 0;
    }

    .post-con:nth-child(1n + 8) {
      display: none;
    }
  }
}
</style>

