<template>
  <div class="local">
    <div class="local-con mobile-present">
      <div class="local-name">
        <div class="name">区镇图库</div>
      </div>
      <div class="local-list">
        <div class="local-post" v-for="k in typeRootList" :key="k.id" @click="onLocalDetail(k.id, k.name)">
          <div class="local-img" :style="{ backgroundImage: `url(` + k.addrImg + `)` }"></div>
          <div class="name">
            <div class="title-icon"></div>
            <div class="title">{{ k.name }}</div>
            <div class="el-icon-right"></div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Bottom },
  data: function () {
    return {
      typeRootList: [], // 地址的root
      typeAnhuiList: [], // 的root
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      totalPage: 1,
      postCount: '',
      query: {},
      localName: '吴江区',
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      RD.addr()
        .id(100)
        .downAddress()
        .then((data) => {
          data.forEach((k) => {
            k.addrImg = RD.addr().id(k.id).bgImage_URL()
          })

          thiz.typeRootList = data
        })
    },
    onLocalDetail(localId, localName) {
      this.$router.push({
        path: '/localShow.html',
        query: { id: localId, name: localName },
      })
    },
    // 加载更多
    onMore() {
      console.log('page', this.page)
      if (this.page == this.totalPage) {
        this.$alert('已经加载全部了', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      } else {
        this.page++
        this.reload()
      }
    },
    onGoReset() {
      this.reload()
    },
    queryPost(id, name) {
      var thiz = this
      var post = {}
      post.addrId = id
      thiz.localName = name
      post.storeIds = [1]
      RD.pure()
        .query()
        .query(post, thiz.page, 10)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
          })
          thiz.postCount = items.pager.total
          thiz.postList = items.list
          thiz.currentPage = items.pager.currentPage
          thiz.downTotal = items.pager.total
        })
    },
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.queryPost(thiz.clickId)
    },
    onLookPost(postId) {
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
  },
}
</script>

<style scoped lang="less">
.local {
  height: auto;
  .local-con {
    width: 750px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    background-color: #f5f5f5;
    .local-name {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;
      height: 220px;
      background: no-repeat url(../assets/fixtopic.png);
      background-size: cover;
      .name {
        width: 730px;
        margin: 0 auto;
        font-size: 38px;
        line-height: 220px;
        font-weight: bold;
      }
    }
    // .active {
    //   background-color: #f48888;
    //   color: #fff;
    // }

    .local-list {
      position: relative;
      width: 730px;
      margin: 10px auto 0;
      .local-post {
        height: 250px;
        position: relative;
        margin-bottom: 20px;
        .local-img {
          height: 100%;
          width: 100%;
          background: no-repeat 50% / cover;
        }
        .name {
          position: absolute;
          bottom: 0px;
          left: 0px;
          height: 60px;
          line-height: 60px;
          width: 730px;
          background-color: rgba(255, 255, 255, 0.3);
          color: #fff;
          font-size: 30px;
          text-align: left;
          display: flex;
          .title-icon {
            height: 40px;
            margin-top: 7px;
            width: 10px;
            background: rgba(215, 20, 12, 0.75);
          }
          .title {
            margin-left: 10px;
          }
          .el-icon-right {
            color: rgba(215, 20, 12, 0.75);
            font-size: 50px;
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }
    }
    .more-post {
      padding-bottom: 20px;
      padding-top: 20px;
      .btn {
        width: 120px;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        border-radius: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
}
.el-tree {
  height: 600px;
  .el-tree-node {
    height: 80px !important;
    display: inline-block;
  }
}
.addr {
  display: inline-block;
  font-size: 1.5rem;
}

// @media only screen and (max-width: 600px) {
//   .whole_box {
//     width: calc(100% - 10px);
//     padding-top: calc((100% - 20px) * 1.35);
//   }
// }
// @media only screen and (min-width: 600px) {
//   .whole_box {
//     width: calc(100% - 10px);
//     padding-top: calc((100% - 20px) * 1.35);
//   }
// }

// @media only screen and (min-width: 960px) {
//   .whole_box {
//     width: calc(30% - 20px);
//     padding-top: calc((25% - 20px) * 1.35);
//   }
// }

@media only screen and (min-width: 1200px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 1600px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
</style>
