<template>
  <div class="myPost">
    <Nav></Nav>
    <div class="myPost-con desktop-present">
      <div class="myPost-title"></div>
      <div class="myPost-detail">
        <div class="detail-tip">
          <div>在“我的图库”里，您将可以看到您上传的全部照片，</div>
          <div>其中全部通过用“ 全部上网 ”表示， 未获通过用“ 全部下网 ”表示，部分通过用“ 部分上网 ”表示，</div>
          <div>正在编辑用“ 正在编辑 ”表示， 尚未编辑用“ 尚未编辑 ”表示。</div>
        </div>
        <div class="detail-page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="pager.total"
            ></el-pagination>
          </div>
        </div>
        <div class="detail-con">
          <el-table :data="myPostList" stripe style="width: 100%" @row-dblclick="onLookPost">
            <el-table-column label="图片标题" width="180">
              <template slot-scope="scope">
                <span class="title">
                  <a target="_blank" :href="'post_' + scope.row.id + '.html'">{{ scope.row.title }}</a></span
                >
                <span v-if="scope.row.canEdit" class="edit" @click="onGoEdit(scope.row.id)">(编辑稿件)</span>
                <span v-if="scope.row.prepareStatus">(预处理上传中，时间：{{ scope.row.prepareStatus.moreTime }},进度：{{ scope.row.prepareStatus.percentString }})</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="postTypeName" label="预处理进度"> 
               <template slot-scope="scope">
            <span v-if="scope.row.prepareStatus">(预处理上传中，时间：{{scope.row.prepareStatus.moreTime}},进度：{{scope.row.prepareStatus.precentString}})</span>
               </template>
            </el-table-column> -->
            <el-table-column prop="postTypeName" label="类型"> </el-table-column>
            <el-table-column prop="firstEditorName" label="初审"> </el-table-column>
            <el-table-column prop="lastEditorName" label="再审"> </el-table-column>
            <el-table-column prop="eventAddrPath" label="发生地点" width="150"> </el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope"> {{ scope.row.countPicOnnet }}/{{ scope.row.countPic }} </template>
            </el-table-column>
            <el-table-column prop="eventTime" label="发生时间" width="150"> </el-table-column>
            <el-table-column prop="ststusName" label="状态"> </el-table-column>
            <el-table-column label="见报数">
              <template slot-scope="scope"> {{ scope.row.published }}张 </template>
            </el-table-column>
            <el-table-column label="下载数">
              <template slot-scope="scope"> {{ scope.row.downloaded }}张 </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pager.currentPage"
            :page-size="pager.pageSize"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="pager.total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="myPost-con mobile-present">
      <div class="myPost-title"></div>
      <div class="myPost-detail">
        <div class="detail-tip">
          <div>在“我的图库”里，您将可以看到您上传的全部照片，</div>
          <div>其中全部通过用“ 全部上网 ”表示， 未获通过用“ 全部下网 ”表示，部分通过用“ 部分上网 ”表示，</div>
          <div>正在编辑用“ 正在编辑 ”表示， 尚未编辑用“ 尚未编辑 ”表示。</div>
        </div>
        <div class="detail-page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="pager.total"
            ></el-pagination>
          </div>
        </div>
        <div class="detail-con">
          <div class="detail-item" v-for="k in myPostList" :key="k.id">
            <div class="post-img" :style="{ backgroundImage: `url(` + k.ImgURL + `)` }"><a target="_blank" :href="'post_' + k.id + '.html'"></a></div>
            <div class="post-content">
              <div class="item">稿件标题:{{ k.title }}</div>
              <div class="item">类型:{{ k.postTypeName }}</div>
              <div class="item">初审:{{ k.firstEditorName }}</div>
              <div class="item">再审:{{ k.lastEditorName }}</div>
              <div class="item">发生地点:{{ k.eventAddrPath }}</div>
              <div class="item">发生时间:{{ k.eventTime }}</div>
              <div class="item">下载数:{{ k.downloaded }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myPostList: [], // 我的稿件列表
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      // var page = 1
      RD.myPost()
        .listPost(thiz.pager.currentPage)
        .then((data) => {
          console.log('data', data)
          var addrs = []
          data.list.forEach((i) => {
            i.postTypeName = i.postType == 'PIC' ? '图片稿' : i.postType == 'VIDEO' ? '视频稿' : ''
            if (i.postType == 'PIC') {
              i.ImgURL = RD.pic().id(i.frontPicId).sImage_URL()
            } else if (i.postType == 'VIDEO') {
              i.ImgURL = RD.video().id(i.id).poster_URL()
            }
            if (i.status == 'UNEDIT') {
              i.ststusName = '未编辑'
            } else if (i.status == 'EDITING') {
              i.ststusName = '编辑中'
            } else if (i.status == 'INNER_EDIT') {
              i.ststusName = '入采编'
            } else if (i.status == 'ONNET') {
              i.ststusName = '上网'
            } else if (i.status == 'DOWNNET') {
              i.ststusName = '下网'
            }
          })
          thiz.myPostList = data.list
          thiz.pager = data.pager
        })
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this
      thiz.pager.currentPage = val
      thiz.reload()
    },
    // 编辑稿件
    onGoEdit(postId) {
      let routerUrl = this.$router.resolve({
        path: '/postEdit.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 浏览稿件
    onLookPost(row) {
      console.log('kl', row)
      var postId = row.id
      let routerUrl = this.$router.resolve({
        path: '/post_' + postId + '.html',
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.myPost {
  background-color: #f1f1f1;
  .myPost-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: 58.5rem;
    .myPost-title {
      height: 5.6rem;
      background-color: #dfdfdf;
      font-size: 1.3rem;
      line-height: 4.5rem;
      text-align: left;
    }
    .myPost-detail {
      height: auto;
      background-color: #dfdfdf;
      width: 100%;
      .detail-tip {
        height: 98px;
        div {
          height: 30px;
          font-size: 1.3rem;
          line-height: 30px;
        }
      }
      .detail-page {
        height: 5.6rem;
      }
      .page-container {
        margin-top: 2rem;
        padding-bottom: 2rem;
        text-align: right;
      }
    }
  }
  .myPost-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 585px;
    .myPost-title {
      height: 56px;
      background-color: #dfdfdf;
      font-size: 1.3rem;
      line-height: 45px;
      text-align: left;
    }
    .myPost-detail {
      height: auto;
      background-color: #dfdfdf;
      width: 100%;
      .detail-tip {
        height: 98px;
        div {
          height: 30px;
          font-size: 1.3rem;
          line-height: 30px;
        }
      }
      .detail-page {
        height: 56px;
      }
      .detail-con {
        .detail-item {
          width: 750px;
          height: 200px;
          display: flex;
          margin-bottom: 20px;
          .post-img {
            width: 400px;
            height: 100%;
            background-size: cover;
            margin-left: 10px;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          .post-content {
            margin-left: 10px;
            .item {
              font-size: 16px;
              text-align: left;
              height: 25px;
              line-height: 25px;
            }
          }
        }
      }
      .page-container {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}
.title {
  cursor: pointer;
  a {
    text-decoration: none;
    color: #606266;
  }
  a:visited {
    color: #606266;
  }
}
.edit {
  color: red;
  cursor: pointer;
}
</style>
